import { Injectable } from "@angular/core";
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "./auth.service";
import { GlobalService } from "./global.service";
import { Storage } from "@ionic/storage-angular";
import { HttpClient } from '@angular/common/http';
import { CrudService } from "./crud.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class AdministrationService extends CrudService{
  private static userData = null;
  private static adminList: any;
  public checkedUserData = 0;
  public checkedAdminData = 0;

  public static set AdminList(value){
    this.adminList = value;
  }

  public static get AdminList(){
    return this.adminList;
  }

  public static set UserData(value){
    this.userData = value;
  }

  public static get UserData(){
    return this.userData;
  }

  constructor(
    public httpClient: HttpClient,
    public storageService: StorageService,
    public navController: NavController,
    public translateService: TranslateService,
    public alertController: AlertController,
    public globalService: GlobalService,
    public authService: AuthService
  ) {
    super(httpClient, navController, translateService, alertController, globalService, storageService, authService);
  }

  public async getFullUserData(){
    if(this.checkedUserData == 0){
      this.checkedUserData = 1;
      let user = await this.read("/user/getaccount");
      AdministrationService.UserData = user;
      this.checkedUserData = 2;
    }
    while(this.checkedUserData == 1) await GlobalService.sleep(100);
    return AdministrationService.UserData;
  }

  public async getOtherUserData(id){
    let user = await this.read("/user/byid/" + id);
    return user;
  }

  public async getUserFromAdministration(userId){
    if(!AdministrationService.adminList){
      let curUser = await this.getFullUserData();
      if(curUser.administration){
        if(this.checkedAdminData == 0){
          this.checkedAdminData = 1;
          await this.getAdministration(curUser.administration.id_administration);
          this.checkedAdminData = 2;
        } else {
          while(this.checkedAdminData == 1) await GlobalService.sleep(100);
        }
      }
      else return curUser;
    }
    
    let finalUser;
    for(let user of AdministrationService.adminList.users){
        if(user.id_user == userId) {
          finalUser = user;
        }
    }
    return finalUser;
  }

  public async getAdministration(administrationId){
    let administration = await this.read("/administration/byid/" + administrationId);
    AdministrationService.AdminList = administration;
    for(let user of AdministrationService.AdminList.users){
      if(user.user_nicename && user.user_nicename.length > 1)
        user.user_nicename = user.user_nicename.charAt(0).toUpperCase() + user.user_nicename.slice(1);
    }
    
    //move current user to the top
    for(let i=0; i<AdministrationService.AdminList.users.length; i++) {
     let item = AdministrationService.AdminList.users[i];
     if(item.id_user == AdministrationService.userData.id_user) {
      AdministrationService.AdminList.users.splice(i, 1);
      AdministrationService.AdminList.users.unshift(item)
     }
    }

    return AdministrationService.AdminList;
  }

  public async createAdministration(administration){
    let res = await this.put("/administration/new", administration);
    console.log(res);
    this.refreshAdministration();
    return res;
  }

  public async SignupForAdministration(administrationId, signupCode){
    let res = await this.patch(`/administration/${administrationId}/signup/${signupCode}`, undefined).catch(async err => {
      this.globalService.returnToast("Incorrect administration information.", undefined, "redToast");
      return;
    });
    console.log("result", res);
    this.refreshAdministration();
    return res;
  }

  public async updateAdministration(administration){
    let res = await this.patch(`/administration/${administration.id_administration}/update`, administration);
    console.log(res);
    this.refreshAdministration();
    return res;
  }

  public async leaveAdministration(){
    let user = await this.getFullUserData();
    let administration = await this.getAdministration(user.administration.id_administration);
    console.log("who are the users", administration.users);
    let finalUsers = [];
    administration.users.forEach(adminUser => {
      if(adminUser.id_user != user.id_user){
        console.log("different id's, " + adminUser.id_user + " vs " + user.id_user);
        finalUsers.push(adminUser);
      }
    });
    administration.users = finalUsers;

    let res = await this.patch(`/administration/${administration.id_administration}/update`, administration);
    console.log(res);
    this.refreshAdministration();
    return res;
  }

  public async deleteAdministration(administrationId){
    let res = await this.delete("/administration/truedelete/" + administrationId, undefined).then(success =>{
    }).catch(result => {
      console.log("result in catch", result); 
    });
    console.log("results of attempted deletion", res);
    this.globalService.returnToast(this.translateService.instant("Administration deleted succesfully"));
    this.refreshAdministration();
    return res;
  }

  public refreshAdministration(){
    this.checkedAdminData = 0;
    this.checkedUserData = 0;
  }
}
