<ion-header>
  <ion-toolbar>
    <app-header [title]="'TERMSANDAGREEMENTSPART1' | translate"></app-header>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div *ngIf="isIos()" class="twentypx-padding"></div>
  <div style="padding-top: 80px;"></div>
  <ion-card>
    <ion-card-content>
      <p class=MsoNormal><span style='font-size:9.0pt;
        line-height:115%;color:#0365A9'>{{ 'TERMSANDAGREEMENTSPART2' | translate }}</span></p>
        
        <h1><a name="_Toc83909785">{{ 'TERMSANDAGREEMENTSPART3' | translate }}</a></h1>
        
        <p class=MsoNoSpacing>{{ 'TERMSANDAGREEMENTSPART4' | translate }}</p>
        
        <p class=MsoNoSpacing>{{ 'TERMSANDAGREEMENTSPART5' | translate }}</p>
        
        <h1><a name="_Toc83909786">{{ 'TERMSANDAGREEMENTSPART6' | translate }}</a></h1>
        
        <p class=MsoNormal>{{ 'TERMSANDAGREEMENTSPART7' | translate }}</p>
        
        <h1><a name="_Toc83909787">{{ 'TERMSANDAGREEMENTSPART8' | translate }}</a></h1>
        
        <p class=MsoNormal>{{ 'TERMSANDAGREEMENTSPART9' | translate }}<span></span></p>
        
        <p class=MsoNormal><span >{{ 'TERMSANDAGREEMENTSPART10' | translate }}</span></p>
        
        <h1><a name="_Toc83909788">{{ 'TERMSANDAGREEMENTSPART11' | translate }}</a> </h1>
        
        <p class=MsoNoSpacing>{{ 'TERMSANDAGREEMENTSPART12' | translate }}</p>
        
        <p class=MsoNoSpacing><span >{{ 'TERMSANDAGREEMENTSPART13' | translate }}</span></p>
        
        <p class=MsoNoSpacing><span >&nbsp;</span></p>
        
        <p class=MsoNoSpacing><span>{{ 'TERMSANDAGREEMENTSPART14' | translate }}</span></p>
        
        <p class=MsoNoSpacing style='margin-left:36.0pt;text-indent:-18.0pt;'><span ><span >-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span >{{ 'TERMSANDAGREEMENTSPART15' | translate }}</span></p>
        
        <p class=MsoNoSpacing style='margin-left:36.0pt;text-indent:-18.0pt;'><span ><span >-<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span></span></span><span >{{ 'TERMSANDAGREEMENTSPART16' | translate }}</span></p>
        
        <p class=MsoNoSpacing><span >&nbsp;</span></p>
        
        <p class=MsoNoSpacing><span >{{ 'TERMSANDAGREEMENTSPART17' | translate }}</span></p>
        
        <h1><a name="_Toc83909789">{{ 'TERMSANDAGREEMENTSPART18' | translate }}</a></h1>
        
        <p class=MsoNormal><span >{{ 'TERMSANDAGREEMENTSPART19' | translate }}</span></p>
        
        <p class=MsoNormal><span >{{ 'TERMSANDAGREEMENTSPART20' | translate }}</span></p>
        
        <p class=MsoNormal><span >{{ 'TERMSANDAGREEMENTSPART21' | translate }}</span></p>
        
        <p class=MsoNormal><span >{{ 'TERMSANDAGREEMENTSPART22' | translate }}</span></p>
        
        <p class=MsoNormal><span >{{ 'TERMSANDAGREEMENTSPART23' | translate }}</span></p>
        
        <h1><a name="_Toc83909790">{{ 'TERMSANDAGREEMENTSPART24' | translate }}</a></h1>
        
        <p class=MsoNormal>{{ 'TERMSANDAGREEMENTSPART25' | translate }}</p>
        
        <p class=MsoNormal><span >&nbsp;</span></p>
        
        <h1><a name="_Toc83909791">{{ 'TERMSANDAGREEMENTSPART26' | translate }}</a></h1>
        
        <p class=MsoNormal>{{ 'TERMSANDAGREEMENTSPART27' | translate }}</p>
        
        <p class=MsoNormal>{{ 'TERMSANDAGREEMENTSPART28' | translate }}</p>
        
        <p class=MsoNormal>&nbsp;</p>
        
        <h1><a name="_Toc83909792">{{ 'TERMSANDAGREEMENTSPART29' | translate }}</a></h1>
        
        <p class=MsoNoSpacing>{{ 'TERMSANDAGREEMENTSPART30' | translate }}</p>
        
        <p class=MsoNoSpacing>{{ 'TERMSANDAGREEMENTSPART31' | translate }}</p>
        
        <p class=MsoNoSpacing>&nbsp;</p>
        
        <p class=MsoNoSpacing>{{ 'TERMSANDAGREEMENTSPART32' | translate }}</p>
        
        <p class=MsoNoSpacing>&nbsp;</p>
        
        <p class=MsoNoSpacing>{{ 'TERMSANDAGREEMENTSPART33' | translate }}</p>
        
        <h1><a name="_Toc83909793">{{ 'TERMSANDAGREEMENTSPART34' | translate }}</a></h1>
        
        <p class=MsoNoSpacing>{{ 'TERMSANDAGREEMENTSPART35' | translate }} </p>
        
        <p class=MsoNoSpacing>{{ 'TERMSANDAGREEMENTSPART36' | translate }}</p>
    </ion-card-content>

    <div class="agree-button" (click)="goBack()">{{ 'TERMSANDAGREEMENTSPART37' | translate }}</div>
  </ion-card>
</ion-content>