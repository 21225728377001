<!--Skeleton Loader-->
<div *ngIf="hideMe && debugMode()">
  Hidden by hideMe
</div>
<div *ngIf="!action && !hideMe && getSkeletonEnabled()" (click)="openActionDetails()">
  <div>
    <ion-card class="action-card">
      <ion-card-content class="ion-no-padding">
      <!--avatars-->
        <div class="title-avatar-wrap">
          <div class="action-ifo-card">
            <ion-avatar class="square-avatar">
              <ngx-skeleton-loader class="note-contact-icon" [theme]="{'height': '64px', 'width': '64px'}">
              </ngx-skeleton-loader>
            </ion-avatar>
          </div>
          <!-- title & contact/action type -->
          <div class="action-ifo-card action-card-text">
            <ion-text class="action-title">
              <ngx-skeleton-loader [theme]="{'width': '30vw'}">
              </ngx-skeleton-loader>
            </ion-text>
            <div class="action-contact">
              <ngx-skeleton-loader [theme]="{'width': '30vw'}">
              </ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </ion-card-content>
    </ion-card>
  </div>
</div>

<!-- Real Data -->
<div *ngIf="action && !hideMe">
  <div *ngIf="!this.detailpage">
    <ion-card class="action-card" [ngClass]="{'failed-goal-action': unfinishedInGoal, 'succesful-goal-action': finishedInGoal}">
      
      <div style="position: absolute; right: 0px;" *ngIf="action.status == 4">
        <ion-icon class="far top-tab-icon new-style-icon fav-star text-color" [src]="'assets/icon/star-touchpoint.svg'"></ion-icon>
      </div>
      <ion-card-content class="ion-no-padding action-card-content">
        <div (click)="openActionDetails()" class="title-avatar-wrap" [ngClass]="{'action-card-content-contact-detail': !isDesktop() && (action.status == 0 || undoEnabled) && select == false && searching != true}">
          <!--avatars-->
          <div class="action-ifo-card" *ngIf="displayAvatar">
            <ion-avatar *ngIf="!action.contacts || action.contacts.length == 0" class="square-avatar">
              <span class="icon-small">
                <ion-icon class="note-contact-icon" [src]="'assets/icon/contacts.svg'"></ion-icon>
              </span>
            </ion-avatar>
    
            <!-- change placeholder with square icon -->
            <ion-avatar class="square-avatar" *ngIf="action.contacts && action.contacts.length > 0 && action.contacts[0]
            && (!avatar || avatar && (!avatar.avatar || avatar.avatar && avatar.avatar.includes('contacts.svg')))">
              <span class="icon-small">
                <ion-icon class="note-contact-icon" [src]="'assets/icon/groups.svg'"></ion-icon>
              </span>
            </ion-avatar>
            <ion-avatar class="square-avatar avatar-avatar" *ngIf="action.contacts && action.contacts.length > 0 && action.contacts[0]
            && (avatar && avatar.avatar && !avatar.avatar.includes('contacts.svg'))">
              <ion-img class="note-contact-icon avatar-avatar" [src]="avatar.avatar"></ion-img>
            </ion-avatar>
          </div>
    
          <!-- title & contact/action type -->
          <div class="action-ifo-card" [ngClass]="{'action-card-text': displayAvatar}">
            <ion-text class="action-title">{{action.title}}</ion-text>
            <div class="action-contact" *ngIf="cDetail || (action.contacts && action.contacts.length > 0)">
              <ion-text color="medium">
                <ion-text *ngIf="!cDetail">{{getDisplayName(action.contacts[0]).trim()}}<br></ion-text>
                <ion-text class="action-user-holder" *ngIf="action.assigned_user && this.selectedUser">{{ selectedUser.user_nicename }}<br></ion-text>
                <ion-text *ngIf="cDetail">{{getLimitedDescription()}}<br></ion-text>
                <ion-text *ngIf="action.due != 'Invalid Date' && action.due != 'error' || debugMode()">{{getDateString()}}</ion-text>
                <ion-text *ngIf="debugMode()"><br>{{action.due}}</ion-text>
              </ion-text>
            </div>
            <div class="action-contact" *ngIf="(!action.contacts || action.contacts.length == 0) && !cDetail">
              <ion-text color="medium">{{'PERSONAL' | translate}}<br>
                <ion-text class="action-user-holder" *ngIf="action.assigned_user && this.selectedUser">{{ selectedUser.user_nicename }}<br></ion-text>
                <ion-text *ngIf="action.due != 'Invalid Date' && action.due != 'error' || debugMode()">{{getDateString()}}
              </ion-text>
                <ion-text *ngIf="debugMode()"><br>{{action.due}}</ion-text>
              </ion-text>
            </div>
          </div>
        </div>
    
    
        <!-- done button -->
        <div class="upper-action-done-button" *ngIf="!searching && ((select == false && !action.status) || (select == false && action.status == 0))">
          <div (click)="completeTask(action, goal, 2)" class="done-button">
            <ion-button class="button-action-card" fill="clear">
              <ion-icon mode="md" name="checkmark-circle"></ion-icon>
    
              <ion-text>{{ "FINISH" | translate}}</ion-text>
            </ion-button>
          </div>
        </div>
        <div class="upper-action-done-button" *ngIf="undoEnabled && !searching && select == false && action.status != 0">
          <div (click)="completeTask(action, goal, 0)" class="done-button">
            <ion-button class="button-action-card" fill="clear">
              <ion-icon mode="md" name="close-circle"></ion-icon>
    
              <ion-text>{{ "UNDOFINISH" | translate}}</ion-text>
            </ion-button>
          </div>
        </div>
      </ion-card-content>
    </ion-card>
  </div>

  <!--template for action-details card-->
  <div *ngIf="this.detailpage" class="action-info">
    <ion-text class="action-ifo-card action-title ion-text-capitalize detail-title selectable-text" [ngClass]="{'desktopDetailIfoCard': isDesktop()}">{{action.title}}</ion-text>
    <div>
      <ion-text class="action-ifo-card selectable-text" [ngClass]="{'desktopDetailIfoCard': isDesktop()}" style="padding-bottom: 10px;"
      >{{getDateString()}}
      <ion-text *ngIf="action && action.status == 0 && isOverdue()" class="reschedule"><br>{{ "ACTIONOVERDUE" | translate }}<u (click)="rescheduleAction()">{{ "RESCHEDULE?" | translate }}</u>
      </ion-text>
        <div *ngIf="debugMode()">
          <br>User: {{ action.id_user }}
        </div>
        <br><div class="action-description selectable-text action-detail-description">{{action.description}}</div>
      </ion-text>
    </div>

    <div *ngIf="action.status == 0">
      <div class="done-button" [ngClass]="{'desktopDetailDone': isDesktop()}" (click)="completeTask(action, goal, 2)">
        <ion-button class="button-action-card" fill="clear">
          <ion-icon mode="md" name="checkmark-circle"></ion-icon>

          <ion-text>{{ "FINISH" | translate}}</ion-text>
        </ion-button>
      </div>
    </div>
  </div>
</div>