<!-- Skeleton View -->
<ion-item *ngIf="!contact && !hideMe && getSkeletonEnabled()" class="item" [lines]="lines ? 'full' : 'none'">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col class="size-2" style="height: 55px; margin-left: -5px;">
        <ion-avatar>
          <ngx-skeleton-loader appearance="circle">
          </ngx-skeleton-loader>
        </ion-avatar>
      </ion-col>
      <ion-col class="contact-text-holder" >
        <ion-row>
          <ngx-skeleton-loader class="upper-skeleton" [theme]="{'width': '30vw'}">
          </ngx-skeleton-loader>
        </ion-row>
        <ion-row>
          <ngx-skeleton-loader class="lower-skeleton" [theme]="{'width': '24vw'}">
          </ngx-skeleton-loader>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>

<!-- Real Data -->
<ion-item *ngIf="contact && !hideMe" class="item" [lines]="lines ? 'full' : 'none'" [button]="this.clickable">
  <ion-grid class="ion-no-padding">
    <ion-row>
      <ion-col class="size-2" (click)="openContactDetails()">
        <ion-avatar *ngIf="!contact.loadingAvatar && (!avatar || !avatar.avatar || avatar.avatar.includes('contacts.svg'))" class="item-avatar no-avatar">
          <ion-icon [src]="'assets/icon/contacts.svg'"></ion-icon>
        </ion-avatar>
        <ion-avatar *ngIf="!contact.loadingAvatar && (avatar && avatar.avatar && !avatar.avatar.includes('contacts.svg'))" class="item-avatar">
          <ion-img [src]="avatar.avatar"></ion-img>
        </ion-avatar>
        <ngx-skeleton-loader *ngIf="contact.loadingAvatar" appearance="circle" class="item-avatar circle-override no-avatar skeleton-circle">
          <ion-icon [src]="'assets/icon/contacts.svg'"></ion-icon>
        </ngx-skeleton-loader>
      </ion-col>
      <ion-col class="contact-text-holder" (click)="openContactDetails()">
        <ion-row>
          <ion-text class="ion-text-capitalize ellipsis slider-ellipsis contact-title">
            {{contactDisplayName}}
          </ion-text>
        </ion-row>
        <ion-row>
          <ion-text color="medium" class="ion-text-capitalize ellipsis slider-ellipsis contact-info-text">
            <ion-text *ngIf="contact.first_name != contact.company">
              {{contact.company}}
            </ion-text>
            <ion-text  *ngIf="contact.first_name != contact.company && contact.company && contact.function">
              -
            </ion-text>
            <ion-text *ngIf="contact.first_name != contact.function">
              {{contact.function}}
            </ion-text>
          </ion-text>
        </ion-row>
      </ion-col>
      <div *ngIf="favouriteButton && contact.id_contact != undefined" class="size-1" (click)="setFavourite()" >
          <ion-icon *ngIf="contact.favourite == 1" class="far top-tab-icon new-style-icon fav-star text-color" [src]="'assets/icon/star-fav.svg'"></ion-icon>
          <ion-icon *ngIf="contact.favourite == 0 || contact.favourite == null" class="far top-tab-icon new-style-icon fav-star text-color" [src]="'assets/icon/star.svg'"></ion-icon>
      </div>
      <ion-col *ngIf="favouriteButton && contact.id_contact == undefined" class="size-1">
        <ion-spinner name="lines" class="loader"></ion-spinner>
      </ion-col>

      <ion-col *ngIf="arrow" class="size-1">
      </ion-col>

      <ion-col *ngIf="arrow" class="size-1" (click)="openContactDetails()">
        <div class="ai-flaticon-next-4 fi row-icon"></div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>