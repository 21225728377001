import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-terms-and-agreements',
  templateUrl: './terms-and-agreements.component.html',
  styleUrls: ['./terms-and-agreements.component.scss'],
})
export class TermsandAgreementsComponent implements OnInit {
  
  constructor(
    private modalController: ModalController,
    public settingsService: SettingsService,
    public globalService: GlobalService
  ) { }

  ngOnInit() {}

  goBack(){
    this.settingsService.SetAgreements("general");
    GlobalService.ForceSignUpPage = true;
    this.modalController.dismiss({});
  }

  isIos(){
    return this.globalService.isIos();
  }
}
