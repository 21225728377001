import { Injectable } from '@angular/core';
import { ContactService } from './contact.service';
import Contact from '../models/contact';
import { ContactAvatarCached } from '../models/contact-avatar-cache';
import { GlobalService } from 'src/app/services/global.service';

@Injectable({
  providedIn: 'root'
})
export class AvatarLoaderService {

  private queue: Contact[] = [];
  public results: ContactAvatarCached[] = [];
  public isRunning: boolean;

  constructor(
    private contactService: ContactService,
    private globalService: GlobalService
  ) { 
    if (!this.isRunning) {
      this.mainLoop();
    }
  }

  public clearAvatars(){
    this.results = [];
  }

  public addToQueue(contact: Contact) {
    // Only add to queue if it's not in there already
    if (this.queue.indexOf(contact) === -1) {
      this.queue.push(contact);
    }
  }

  public async getAvatar (contact: Contact, remember: boolean = false) {
    let loadingCounter = 0;
    while (!this.results[contact.id_contact] && loadingCounter < 3) {
      if(loadingCounter == 1){
        this.getContactAvatar(contact);
      }
      await this.globalService.sleep(1500);
      loadingCounter++;
    }

    let result = this.results[contact.id_contact];
    if(!remember) this.results[contact.id_contact] = undefined;

    return result;
  }

  private async getContactAvatar (contact: Contact) {
    let avatar = await this.contactService.getContactAvatar(contact);

    this.results[contact.id_contact] = avatar;
  }

  private async getAvatarsFromQueue (elements: number) {
    if(GlobalService.DebugMode) console.log(`Getting ${elements} avatars...`);

    this.start();

    let i = 0;
    let promises: Promise<void>[] = [];

    while (this.queue.length && i < elements) {
      promises.push(this.getContactAvatar(this.queue.shift()));
      i++;
    }

    await Promise.all(promises);
  }

  private async mainLoop () {
    console.log("Starting avatar loader...");
    this.isRunning = true;

    while (true) {
      if (this.queue.length) {
        await this.getAvatarsFromQueue(5);
      } else {
        await this.globalService.sleep(1000);
      }
    }
  }

  public start () {
    if (!this.isRunning) {
      this.mainLoop();
    }
  }
}
