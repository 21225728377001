import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'add-contact',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/add-contact/add-contact.module').then( m => m.AddContactPageModule)
  },
  {
    path: 'contact-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/contact-details/contact-details.module').then( m => m.ContactDetailsPageModule)
  },
  {
    path: 'cropper',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/cropper/cropper.module').then( m => m.CropperPageModule)
  },
  {
    path: 'contacts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/contacts/contacts.module').then( m => m.ContactsPageModule)
  },
  {
    path: 'add-action',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/add-action/add-action.module').then( m => m.AddActionPageModule)
  },
  {
    path: 'select-contacts',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/select-contacts/select-contacts.module').then( m => m.SelectContactsPageModule)
  },
  {
    path: 'select-goals',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/select-goals/select-goals.module').then( m => m.SelectGoalsPageModule)
  },
  {
    path: 'select-actions',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/select-actions/select-actions.module').then( m => m.SelectActionsPageModule)
  },
  {
    path: 'select-touchpoints',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/select-touchpoints/select-touchpoints.module').then( m => m.SelectTouchpointsPageModule)
  },
  {
    path: 'action-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/action-details/action-details.module').then( m => m.ActionDetailsPageModule)
  },
  {
    path: 'note-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/note-details/note-details.module').then( m => m.NoteDetailsPageModule)
  },
  {
    path: 'contact-group-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/contact-group-details/contact-group-details.module').then( m => m.ContactGroupDetailsPageModule)
  },
  {
    path: 'about',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'privacy',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'copyright',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/copyright/copyright.module').then( m => m.CopyrightPageModule)
  },
  {
    path: 'help',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'add-contact-group',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/add-contact-group/add-contact-group.module').then( m => m.AddContactGroupPageModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'functionality',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/functionality/functionality.module').then( m => m.FunctionalityPageModule)
  },
  {
    path: 'add-note',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/add-note/add-note.module').then( m => m.AddNotePageModule)
  },
  {
    path: 'search',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/search/search.module').then( m => m.SearchPageModule)
  },
  {
    path: 'actionpath',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/actionpath/actionpath.module').then( m => m.actionpathPageModule)
  },
  {
    path: 'actionpath-details',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/actionpath-details/actionpath-details.module').then( m => m.actionpathDetailsPageModule)
  },
  {
    path: 'add-actionpath',
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/add-actionpath/add-actionpath.module').then( m => m.AddactionpathPageModule)
  },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
