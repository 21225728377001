import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavController, AlertController, ToastController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import * as Sentry from "@sentry/capacitor";

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private static searchData: String = "";
  private goingBack: boolean = false;
  private static forceSignUpPage: boolean = false;
  private static hideMenu: boolean = true;
  private static showCancelSub: boolean = false;
  private static loginPage: boolean = false;
  private static blackLoader: boolean = true;
  private static hasInitialised: boolean = false;
  private static debugMode: boolean = false;
  private static isLoading: boolean = false;
  private static showConfetti: boolean = false;
  private static isAppReady: boolean = false;
  private static showQR: boolean = false;
  private static qrCode: string = "";
  private static debugLog: string = "";
  private static debugCounter: number = 0;
  private static debugLogResetTimer: number = 0;
  private static displayDebugPopup: boolean = false;
  private static displaySkeleton = true;

  public static get ShowConfetti () {
    return this.showConfetti;
  }

  public static set ShowConfetti (result) {
    this.showConfetti = result;
  }

  public static get DebugLogResetTimer () {
    return this.debugLogResetTimer;
  }

  public static set DebugLogResetTimer (result) {
    this.debugLogResetTimer = result;
  }

  public static get DisplaySkeleton () {
    return this.displaySkeleton;
  }

  public static set DisplaySkeleton (result) {
    this.displaySkeleton = result;
  }

  public static get DisplayDebugPopup () {
    return this.displayDebugPopup;
  }

  public static set DisplayDebugPopup (result) {
    this.displayDebugPopup = result;
  }

  public static get DebugCounter () {
    return this.debugCounter;
  }

  public static set DebugCounter (result) {
    this.debugCounter = result;
  }

  public static get DebugLog () {
    return this.debugLog;
  }

  public static set DebugLog (result) {
    this.debugLog = result;
  }

  public static get QrCode () {
    return this.qrCode;
  }

  public static set QrCode (result) {
    this.qrCode = result;
  }

  public static get ShowQR () {
    return this.showQR;
  }

  public static set ShowQR (result) {
    this.showQR = result;
  }

  public static get IsAppReady () {
    return this.isAppReady;
  }

  public static set IsAppReady (result) {
    this.isAppReady = result;
  }

  public static get HasInitialised () {
    return this.hasInitialised;
  }

  public static set HasInitialised (result) {
    this.hasInitialised = result;
  }

  public static get IsLoading () {
    return this.isLoading;
  }

  public static set IsLoading (result) {
    this.isLoading = result;
  }
  public static get BlackLoader () {
    return this.blackLoader;
  }

  public static set BlackLoader (result) {
    this.blackLoader = result;
  }

  public static get LoginPage () {
    return this.loginPage;
  }

  public static set LoginPage (result) {
    this.loginPage = result;
  }
  
  public static get DebugMode () {
    return this.debugMode;
  }

  public static set DebugMode (result) {
    this.debugMode = result;
  }

  public static get ShowCancelSub () {
    return this.forceSignUpPage;
  }

  public static set ShowCancelSub (result) {
    this.showCancelSub = result;
  }

  public static get ForceSignUpPage () {
    return this.forceSignUpPage;
  }

  public static set ForceSignUpPage (result) {
    this.forceSignUpPage = result;
  }

  public static get HideMenu () {
    return this.hideMenu;
  }

  public static set HideMenu (result) {
    this.hideMenu = result;
  }

  public static get SearchData () {
    return this.searchData;
  }

  public static set SearchData (result) {
    this.searchData = result;
  }

  constructor(
    public translateService: TranslateService,
    private navController: NavController,
    private platform: Platform,
    public alertController: AlertController,
    private iab: InAppBrowser,
    private toastController: ToastController
  ) { }

  public alertTimer(alert){
    let count = 4;
    let countdown = setInterval(function () {
      if (count > 0) {
        count--;
      } else {
        clearInterval(countdown);
        if(alert)
          alert.dismiss();
      }
    }, 1000);
  }

  public onEnter(event) {
    if(event.keyCode === 13) {
      event.preventDefault();
      event.target.blur();
    }
    event.target.value = event.target.value.replace(/\r?\n|\r/g, "");
  }

  public getDateString(dateObj){
    try{
      return this.getDateStringFunction(dateObj);
    } catch(error){
      GlobalService.log("Error with", dateObj.toString());
      let newDateObject = dateObj.split("/");
      dateObj = newDateObject[1] + "/" + newDateObject[0] + "/" + newDateObject[2];
      try{
        return this.getDateStringFunction(dateObj);
      } catch(error){
        GlobalService.log("Tried again, error with", dateObj.toString());
      }
    }
  }

  getDateStringFunction(dateObj){
    if(typeof dateObj === "string" && dateObj.includes("T")){
      dateObj = new Date(dateObj).toLocaleString();
    }

    let convertedDate = this.convertDateForMobile(dateObj);

    let prettydate: string;

    if(convertedDate instanceof Date){
      let finalMonth: number = Number(convertedDate.getMonth()) + 1;
      prettydate = 
      (("0" + finalMonth).slice(-2)) + "-" + ("0" + convertedDate.getDate()).slice(-2) + "-" + convertedDate.getFullYear() + ", " +
      ("0" + convertedDate.getHours()).slice(-2) + ":" + ("0" + convertedDate.getMinutes()).slice(-2);
    } else {
      prettydate = ("0" + convertedDate[0]).slice(-2) + "-" + 
      ("0" + (parseInt(convertedDate[1])).toString()).slice(-2) + "-" + 
      (parseInt(convertedDate[2])).toString() +
      ", " + ("0" + convertedDate[3]).slice(-2) + ":" + ("0" + convertedDate[4]).slice(-2);
    }

    return prettydate;
  }

  public convertDateForMobile (dateObj) {
    let pureDate;
    // console.log("convert following date", dateObj, false);
    
    if(typeof dateObj != "string" && typeof dateObj != "number"){
      dateObj = dateObj.toLocaleString();
    } else if(typeof dateObj == "number"){
      dateObj = new Date(dateObj).toLocaleString();
    }

    if(!dateObj.includes("/")){
      dateObj = dateObj.replaceAll("-", "/");
      dateObj = dateObj.replaceAll(" ", ", ");
      dateObj = dateObj.replaceAll(", , ", ", ");
    }
    pureDate = dateObj.split("/");

    if(dateObj.includes("/")){
      pureDate = dateObj.split("/");
    } else {
      dateObj = new Date(dateObj).toLocaleString();
      pureDate = dateObj.split("/");
    }
    
    if(pureDate[2]){
      let convertDate = "";
      convertDate += pureDate[2].split(", ")[0];
      convertDate += "-" + ("0" + pureDate[0]).slice(-2);
      convertDate += "-" + ("0" + pureDate[1]).slice(-2);
  
      let convertHours: number = parseInt(dateObj.split(", ")[1].split(":")[0]);
  
      if(dateObj.split(", ")[1].split(":")[2].split(" ")[1] == "PM"){
        convertHours = convertHours + 12;
        if(convertHours > 23) { convertHours=12; }
      }
  
      let convertTime: string = "";
      convertTime = ("0" + convertHours).slice(-2) + ":";
      convertTime += ("0" + dateObj.split(", ")[1].split(":")[1]).slice(-2);
      
      let returnDate = new Date(convertDate+"T"+convertTime);
  
      if(Number.isNaN(returnDate.getMonth())){
        let arr = dateObj.toLocaleString().split(/[/ - :]/);
        
        if(arr[6] == "PM"){
          arr[3] = parseInt(arr[3]) + 12;
          if(parseInt(arr[3]) > 23) { arr[3]=12; }
        }
  
        arr[2] = arr[2].replace(",", "");
        return arr;
      } else {
        return returnDate;
      }
    } else {
      return undefined;
    }
  }

  public async goBack() {
    if(this.goingBack == false)
    {
      this.goingBack = true;
      await this.navController.back();
      await this.sleep(500);
      this.goingBack = false;
    }
  }

  public sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public static sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public isIos(){
    if(!this.platform.is("ios"))
      return false
    return true;
  }

  public isAndroid(){
    if(!this.platform.is("android"))
      return false
    return true;
  }

  public static sliceToTwo(sliceMe){
    return ("0" + sliceMe).slice(-2);
  }
  
  public isDesktop(){
    if(this.platform.is('desktop') && !this.platform.is('android') && !this.platform.is('iphone')
    || this.platform.is('mobileweb') && !this.platform.is('android') && !this.platform.is('iphone')
    ) {
      return true;
    }
    return false;
  }

  async openURL (page: string) {
    const browser = this.iab.create(page, "_blank","location=no&hideurlbar​=yes");
  }

  public static async startCountingSubs(){
    GlobalService.showCancelSub = false;
    await GlobalService.sleep(7000);
    GlobalService.showCancelSub = true;
  }

  public static showCancelSubFunc(){
    return GlobalService.showCancelSub;
  }

  public async ionicAlert(buttons?: any, alertHeader: string = "", alertMessage: string = "", alertClass: string = "custom-alert", backdropDismiss: boolean = false, timer: boolean = false){
    console.log("ionic alert triggered: " + alertHeader);
    if(!buttons) buttons = [{
      text: this.translateService.instant("OKAY"),
      cssClass: "cancelButton"
    }];

    if(alertMessage != ""){
      alertClass += " custom-alert-top-padding";
      alertMessage = this.translateService.instant(alertMessage);
    }
    
		let alert = await this.alertController.create
		({
      cssClass: alertClass,
			header: this.translateService.instant(alertHeader),
      message: alertMessage,
			buttons: buttons,
      backdropDismiss: backdropDismiss
    });

    if(timer) this.alertTimer(alert);
		alert.present();
  }

  public async genericError(error?, objectError?, errorTitle?, shareButton: boolean = false){
    let buttons = [];

    if(!errorTitle){
      errorTitle = this.translateService.instant("ERROR");
    }

    let finalError = "<br>";

    if(error){
      finalError += error;
    } else if(objectError){
      Object.entries(objectError).forEach(entry => {
        const [key, value] = entry;
        GlobalService.log(key, value);
        finalError += key + ": " + value;
        finalError += ", ";
      })
    } else {
      finalError += "no error detected";
    }

    if(shareButton){
      buttons.push({
        text: this.translateService.instant("SHARE"),
        cssClass: "wideButton",
        handler: () => {
          Sentry.getCurrentScope().addAttachment({
            filename: "debuglog.txt",
            data: error,
          });
          let email = error.split("!divide!")[1];
          Sentry.captureMessage("Debuglog, " + email + ", date: " + new Date())
          this.returnToast(this.translateService.instant("DEBUGLOGSHARED"));
          // this.mailIT(error);
        }
      });
      buttons.push({
        text: this.translateService.instant("COPYTOCLIPBOARD"),
        cssClass: "wideButton",
        handler: () => {
          GlobalService.copyToClipboard(finalError);
          this.returnToast(this.translateService.instant("COPIEDTOCLIPBOARD"));
        }
      });
    }

    buttons.push({
      text: this.translateService.instant("OKAY"),
      cssClass: "wideButton",
      handler: () => {
        alert.dismiss();
      }
    });

    let cssClass = "custom-alert";

    if(GlobalService.debugMode || shareButton) cssClass += " full-screen-alert";
    if(shareButton) cssClass += " z-index-30012";

    const alert = await this.alertController.create({
      cssClass: cssClass,
      header: errorTitle,
      message: finalError,
      buttons: buttons
    });

    this.ionicAlert(
      buttons,
      errorTitle,
      finalError,
      cssClass
    );
  }  
  
  public static doNotAllowSpecial(text) {
    if(text) text = text.toString().replace(/[\`\<\>\\]/g, '');
    
    return text;
  }

  public static isNumber(value) {
    return typeof value === 'number';
  }

  public async returnToast(text, position?, toastClass?, duration = 2000){
    if(!position) {
      if(this.isDesktop()) position = "top";
      else position = "bottom";
    }
    let css = "toastCustomClass";
    if(toastClass) css += " " + toastClass;
    else css += " greenToast"

    const toast = await this.toastController.create({
      message: this.translateService.instant(text),
      duration: duration,
      cssClass: css,
      position: position
    });

    if(position == "top")
      toast.cssClass = toast.cssClass + " toptoast"
    else
    toast.cssClass = toast.cssClass + " bottomtoast"

    toast.present();
  }

  public static checkResetTimer(){
    //Reset timer is 1 day
    if(!GlobalService.DebugLogResetTimer || GlobalService.DebugLogResetTimer > new Date().getTime() + 86400000){
      GlobalService.DebugLog = ""
      GlobalService.DebugLogResetTimer = new Date().getTime();
      GlobalService.log("Debug log reset: " + GlobalService.DebugLogResetTimer);
    }
  }

  public static log(information?, value?, consoleLog: boolean = true){
    GlobalService.checkResetTimer()
    if(consoleLog) console.log(information, value);
    let additionalValue = "";
    if(information) additionalValue += this.convertValueForLog(information);
    if(information && value) additionalValue += ": ";
    if(value) additionalValue += this.convertValueForLog(value);
    if(!this.debugLog.includes(additionalValue)){
      this.debugLog += additionalValue;
      this.debugLog += "\n\n";
    }
  }

  public async displayLog(){
    GlobalService.isLoading = true;
    await this.genericError(GlobalService.DebugLog, undefined, "Debug Log", true);
    GlobalService.isLoading = false;
  }

  public static convertValueForLog(value){
    if(value == null) return null;
    if(Array.isArray(value)){
      let finalValue: string = "";
      value.forEach(element => {
        finalValue += this.convertObjectForLog(element);
      });
      return finalValue;
    } else return this.convertObjectForLog(value);
  }

  public static convertObjectForLog(value){
    if(value == null) return null;
    if(typeof value === 'object'){
      let finalValue: string = "";
      Object.entries(value).forEach(([key, val]) => {
        finalValue += this.convertValueForLog(key) + 
        ": " + this.convertValueForLog(val) + "<br>";
      });
      return finalValue;
    } else {
      if(value.toString().length < 100)
       return value.toString();
      else return "(length exceeded)<br>";
    }
  }

  mailIT(message){
    let emailAdress = "b.moinat@actablue.com";
    message = message.replaceAll("<br>", "\n");
    message = encodeURIComponent(message);
    window.location.href = "mailto:" + emailAdress + "?subject=debuglog: " + new Date().toISOString() + "&body=" + message;
  }

  public static debugTap(){
    console.log("triggered debugTap");
    GlobalService.debugCounter++;
    if(GlobalService.debugCounter >= 5){
      GlobalService.displayDebugPopup = true;
    }
  }

  public static copyToClipboard(value: string){
    navigator.clipboard.writeText(value);
  }

  public static async getFromClipboard(){
    let clipboardText = await navigator.clipboard.readText()
    if(clipboardText) return clipboardText;
    else return null;
  }

  public static async enableSkeletonScreen(){
    console.log("disable skeleton");
    GlobalService.DisplaySkeleton = false;
    await GlobalService.sleep(400); //1200?
    GlobalService.DisplaySkeleton = true;
  }

  public async displayConfetti(){
    GlobalService.ShowConfetti = true;
    await GlobalService.sleep(4000);
    GlobalService.ShowConfetti = false;
  }

  public translate(value){
    return this.translateService.instant(value);
  }
}
