import { Injectable } from '@angular/core';
import { AlertController } from "@ionic/angular";
import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';

@Injectable({
  providedIn: 'root'
})
export class IOSPhotoService {
  private static curphoto = {base64String: "", format: "png", saved: false};
  private height: number;
  private width: number;

  public static get CurPhoto () {
    return this.curphoto;
  }

  public static set CurPhoto (photo) {
    this.curphoto = photo;
  }

  constructor( 
    private alertController: AlertController,
    private camera: Camera,
    private file: File ) {}

  public async takePhoto() {
    // Take a photo
    const options: CameraOptions = {
      quality: 100,
      sourceType: this.camera.PictureSourceType.CAMERA,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE
    }

    await this.camera.getPicture(options).then(async (imageData) => {
      let capturedPhoto = imageData;
      IOSPhotoService.curphoto.base64String = capturedPhoto;
  
      let dimensions = await this.getImageDimensions("data:image/png;base64, " + IOSPhotoService.curphoto.base64String)
      let newHeight = this.height;
      let newWidth = this.width;
      console.log(dimensions);
  
      while(newHeight > 300 || newWidth > 300){
        newHeight = newHeight * 0.8;
        newWidth = newWidth * 0.8;
      }
  
      this.compressImage("data:image/png;base64, " + IOSPhotoService.curphoto.base64String, newWidth, newHeight).then(compressed => {
        console.log(compressed.toString().split("data:image/png;base64,"));
        IOSPhotoService.curphoto.base64String = compressed.toString().split("data:image/png;base64,")[1];
      })   
  
      console.log(capturedPhoto);
      
      return capturedPhoto;
      }, (err) => {
        console.log(err);
    });
    console.log("If you see this, error in photo.service");
    return undefined;
  }

  public async openGallery () {
    // select a photo
    const options: CameraOptions = {
      quality: 90,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE
    }

    await this.camera.getPicture(options).then(async (imageData) => {
      let capturedPhoto = imageData;
      IOSPhotoService.curphoto.base64String = capturedPhoto;
  
      let dimensions = await this.getImageDimensions("data:image/png;base64, " + IOSPhotoService.curphoto.base64String)
      let newHeight = this.height;
      let newWidth = this.width;
      console.log(dimensions);
  
      while(newHeight > 300 || newWidth > 300){
        newHeight = newHeight * 0.8;
        newWidth = newWidth * 0.8;
      }
  
      this.compressImage("data:image/png;base64, " + IOSPhotoService.curphoto.base64String, newWidth, newHeight).then(compressed => {
        console.log(compressed.toString().split("data:image/png;base64,"));
        IOSPhotoService.curphoto.base64String = compressed.toString().split("data:image/png;base64,")[1];
      })    
  
      console.log(capturedPhoto);
      
      return capturedPhoto;
      }, (err) => {
        console.log(err);
    });
    console.log("If you see this, error in photo.service");
    return undefined;
  }

  public compressImage(src, newX, newY){
    return new Promise((res, rej) => {
      let img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }

  public getImageDimensions(file) {
    let context = this;
    return new Promise (function (resolved, rejected) {
      var i = new Image()
      i.onload = function(){
        context.height = i.height;
        context.width = i.width;
        resolved({w: i.width, h: i.height})
      };
      i.src = file
    })
  }
}

