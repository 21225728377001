<ion-header [ngClass]="{'fixDesktopDetailHeader': isDesktop()}">
  <ion-toolbar>
    <ion-buttons mode="md" slot="start">
      <ion-button mode="md" (click)="unsavedData()">
        <div class="ai-flaticon-back-2 fi"></div>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="finish-select-button" mode="md" (click)="submit()" *ngIf="title.length > 0">
        <ion-icon class="new-style-icon v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text class="v-done">{{"DONE" | translate}}</ion-text>
      </ion-button>
      <ion-button class="finish-select-button" mode="md" *ngIf="title.length == 0">
        <ion-icon class="new-style-icon faded v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text class="v-done faded">{{"DONE" | translate}}</ion-text>
      </ion-button>
    </ion-buttons>

    <app-header [title]="(isEditingExisting ? 'EDITACTIONPATH' : 'ADDACTIONPATH') | translate "></app-header>
  </ion-toolbar>
</ion-header>

<div class="disable-background" *ngIf="isSubmitted">
  <div class="pop-up">
    <ion-spinner class="loading-spinner non-standard-loading-spinner" style="color: black;"></ion-spinner>
  </div>
</div>

<ion-content>
  <div class="disable-background" *ngIf="showTimeSelection" (click)="toggleTime()"></div>
  <div class="time-popup desktop-time-selection" *ngIf="showTimeSelection && isDesktop()">
    <div class="time-popup-name">{{ "TIME" | translate }}</div>
    <ion-datetime [(ngModel)]="time" presentation="time" [presentation]="'time'" (presentation)="'time'" required displayFormat="HH:mm" hourCycle="h23" minuteValues="0,15,30,45"></ion-datetime>
    <div (click)="toggleTime()" class="time-done-button">Done</div>
  </div>
  <div class="time-popup" *ngIf="showTimeSelection && !isDesktop()">
    <div class="time-popup-name">{{ "TIME" | translate }}</div>
    <ion-datetime [(ngModel)]="time" presentation="time" [presentation]="'time'" (presentation)="'time'" required displayFormat="HH:mm" hourCycle="h23" minuteValues="0,15,30,45"></ion-datetime>
    <div (click)="toggleTime()" class="time-done-button">Done</div>
  </div>

<ion-content>
  <ion-grid class="ion-no-padding input-grid">
    <div class="bonus-mini-modal-padding"></div>
    <div *ngIf="isIos()" class="twentypx-padding"></div>
    <ion-card>
      <ion-row *ngIf="returnMaximum() == true">
        <ion-col>
          <ion-item text-center class="max-actionpaths-reached">
            <ion-label>{{ "MAXACTIONPATHREACHED" | translate}}</ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label position="floating" color="primary">
              {{ 'TITLE' | translate }}
            </ion-label>

            <ion-input [id]="'titleId'" autocorrect="on" required autocapitalize='true' (keydown)="onEnter($event)"  #titleId [(ngModel)]="title" maxlength="45" [placeholder]="'TITLE' | translate"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label position="floating" color="primary">
              {{"DESCRIPTION" | translate}}
            </ion-label>
            <ion-textarea autocorrect="on" autocomplete="true" spellcheck="true" autocapitalize='true' auto-grow="true" rows="4" [(ngModel)]="description" [placeholder]="'DESCRIPTIONFIELD' | translate">

            </ion-textarea>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-card>

    <ion-card>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label>{{'STATUS' | translate}}</ion-label>
            <ion-select interface="popover" [(ngModel)]="status" [placeholder]="'STATUS' | translate">
              <ion-select-option value="OPEN" *ngIf="this.startingStatus == 'OPEN' || status == 'OPEN' || returnMaximum() == false">
                {{ "OPEN" | translate }}</ion-select-option>
              <ion-select-option value="CLOSED">
                {{ "CLOSED" | translate }}</ion-select-option>
              <!-- <ion-select-option value="INACTIVE">
                {{ "INACTIVE"| translate }}</ion-select-option> -->
            </ion-select>
          </ion-item>
  
        </ion-col>
      </ion-row>
      <ion-row *ngIf="this.status == 'OPEN'">
        <ion-col>
          <ion-item>
            <ion-label>{{'PRIORITY' | translate}}</ion-label>
            <ion-checkbox [(ngModel)]="priority">

            </ion-checkbox>
          </ion-item>
  
        </ion-col>
      </ion-row>
      <ion-row *ngIf="this.status == 'CLOSED'">
        <ion-col>
          <ion-item>
            <ion-label position="floating" color="primary">
              {{"RESULT" | translate}}
            </ion-label>
            <ion-textarea autocorrect="on" autocomplete="true" spellcheck="true" autocapitalize='true' auto-grow="true" rows="4" [(ngModel)]="result" [placeholder]="'RESULT' | translate">

            </ion-textarea>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-card>
    
    <!-- <ion-card>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label style="max-width: 50%;">{{'ENDGOAL' | translate}}</ion-label>
            <ion-select style="max-width: 60%; position: absolute; right: 5px;" disabled interface="popover" [(ngModel)]="subgoalCountType" [placeholder]="'ENDGOAL' | translate">
              <ion-select-option value="NONE">
                {{ "NONE" | translate }}
              </ion-select-option>
              <ion-select-option value="TOUCHPOINTS">
                {{ "TOUCHPOINTS" | translate }}
              </ion-select-option>
              <ion-select-option value="ACTIONSEQUENCES">
                {{ "ACTIONSEQUENCES" | translate }}
              </ion-select-option>
              <ion-select-option value="MANUAL">
                {{ "MANUAL" | translate }}
              </ion-select-option>
              </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="this.subgoalCountType == 'TOUCHPOINTS' || this.subgoalCountType == 'ACTIONSEQUENCES'">
        <ion-col>
          <ion-item>
            <ion-label class="balanced-label" slot="start" color="primary">
              {{ 'ENDGOAL' | translate }} {{ 'NUMBERFULL' | translate }}
            </ion-label>
    
            <ion-input slot="end" type="number" [(ngModel)]="subgoalMax" value="10" [placeholder]="'10'"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-card> -->

    <ion-card *ngIf="this.subgoalCountType == 'MANUAL'">
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label position="floating" color="primary">
              {{"ENDGOALNAME" | translate}}
            </ion-label>
            <ion-input autocorrect="on" required autocapitalize='true' (keydown)="onEnter($event)" [(ngModel)]="subgoalName" maxlength="20" [placeholder]="'ENDGOALNAME' | translate"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="this.subgoalCountType == 'MANUAL'">
        <ion-col>
          <ion-item>
            <ion-label class="balanced-label" slot="start" color="primary">
              {{ 'CURRENT' | translate }} {{ 'NUMBERFULL' | translate }}
            </ion-label>
    
            <ion-input slot="end" type="number" [(ngModel)]="subgoalCount" value="0" [max]="subgoalMax" [placeholder]="'0'"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label class="balanced-label" slot="start" color="primary">
              {{ 'ENDGOAL' | translate }} {{ 'NUMBERFULL' | translate }}
            </ion-label>
    
            <ion-input slot="end" type="number" [(ngModel)]="subgoalMax" value="10" [placeholder]="'10'"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-card>

    <ion-card>
      <ion-row *ngIf="this.status == 'OPEN'">
        <ion-col>
          <ion-item>
            <ion-label class="balanced-label" slot="start" color="primary">
              {{ "TOUCHPOINTS" | translate }} {{ 'ENDGOAL' | translate }}
            </ion-label>
    
            <ion-input slot="end" type="number" [(ngModel)]="subgoalMax" value="10" [placeholder]="'10'"></ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label>{{'FINALDATE' | translate}}</ion-label>
            <ion-checkbox [(ngModel)]="useFinalDate">

            </ion-checkbox>
          </ion-item>
  
        </ion-col>
      </ion-row>
      <ion-row *ngIf="useFinalDate">
        <ion-col>
          <ion-item class="center-text">
            <ion-icon class="add-action-icon" [src]="'assets/icon/calendar.svg'"></ion-icon>
  
            <ion-input type="date" [(ngModel)]="date" required max="2030-01-01" displayFormat="DD - MM - YYYY"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col (click)="toggleTime()">
          <ion-item class="center-text">
            <ion-icon class="add-action-icon" [src]="'assets/icon/clock.svg'"></ion-icon>
            {{ time }}
          </ion-item>
        </ion-col>
      </ion-row>
    </ion-card>
    
    <!-- <ion-card>
      <ion-card-header>
        <ion-card-title class="center-text">
          {{"CONTACTS" | translate}}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div class="center-text">
          <ion-button color="dark" fill="clear">
            <div class="ai-flaticon-plus fi" (click)="selectContacts()"></div>
          </ion-button>
        </div>
        <ion-list>
          <div *ngFor="let contact of this.selectedContacts" >
          <app-contact-item [contact]="contact"></app-contact-item>
        </div>
        </ion-list>
      </ion-card-content>
    </ion-card> -->

    <!-- <ion-card *ngIf="this.subgoalCountType != 'ACTIONSEQUENCES'">
      <ion-card-header>
        <ion-card-title class="center-text">
          {{"ACTIONS" | translate}}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div class="center-text">
          <ion-button color="dark" fill="clear">
            <div class="ai-flaticon-plus fi" (click)="addAction()"></div>
          </ion-button>
        </div>
        <ion-list>
          <div *ngFor="let action of this.selectedActions">
            <app-action-item [select]="true" [clickable]="false" [action]="action"></app-action-item>
          </div>
        </ion-list>
      </ion-card-content>
    </ion-card> -->

    <ion-card *ngIf="this.subgoalCountType != 'ACTIONSEQUENCES'">
      <ion-card-header>
        <ion-card-title class="center-text">
          {{"TOUCHPOINTS" | translate}}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div class="center-text">
          <ion-button color="dark" fill="clear">
            <div class="ai-flaticon-plus fi" (click)="addTouchpoint()"></div>
          </ion-button>
        </div>
        <ion-list>
          <div *ngFor="let touchpoint of this.selectedTouchpoints">
            <app-action-item [select]="true" [clickable]="false" [action]="touchpoint"></app-action-item>
        </div>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <ion-card *ngIf="this.subgoalCountType == 'ACTIONSEQUENCES'">
      <ion-card-header>
        <ion-card-title class="center-text">
          {{"ACTIONSEQUENCES" | translate}}
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <div class="center-text">
          <ion-button color="dark" fill="clear">
            <div class="ai-flaticon-plus fi" (click)="addActionSequences()"></div>
          </ion-button>
        </div>
        <ion-list>
          <div *ngFor="let action of this.selectedActionSequences">
            <app-action-item [select]="true" [clickable]="false" [action]="action"></app-action-item>
          </div>
        </ion-list>
      </ion-card-content>
    </ion-card>
  </ion-grid>
  
  <div class="bottom-button" *ngIf="title.length != 0" (click)="submit()">
    {{ "DONE" | translate }}
  </div>
  <div class="bottom-button faded-bottom-button" *ngIf="title.length == 0" (click)="submit()">
    {{ "DONE" | translate }}
  </div>
</ion-content>
