<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="goBack()">
        <ion-icon class="new-style-icon" [src]="'assets/icon/chevron-left.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="finish-select-button" mode="md" (click)="submit()">
        <ion-icon class="new-style-icon v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text class="v-done">{{"DONE" | translate}}</ion-text>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div [ngClass]="{'ios-cropper-margin': isIos()}"  class="cropper-margin"></div>
  <image-cropper [imageBase64]="'data:image/png;base64, ' + originalImage.base64String"
    [imageChangedEvent]="imageChangedEvent" (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)" (loadImageFailed)="loadImageFailed()"
    [cropperMinHeight]="100"
    [cropperMinWidth]="100"
    [cropperMaxHeight]="1000"
    [cropperMaxWidth]="1000"
    [output]="'base64'"
    ></image-cropper>
</ion-content>