<!-- Skeleton View -->

<div *ngIf="!note && !hideMe" class="ion-no-padding">
  <div class="ion-no-padding">
    <div class="title-avatar-wrap" >
   
    <!--avatars-->
      <div class="action-ifo-card">
        <ngx-skeleton-loader appearance="circle" [theme]="{ width: '60px',
        height: '60px',
        'border-radius': '10px' }">
        </ngx-skeleton-loader>
      </div>
    
    <!-- title & contact/action type -->
      <div class="action-ifo-card action-card-text">
        <ngx-skeleton-loader [theme]="{'width': '24vw'}"></ngx-skeleton-loader>
        <div class="action-contact">
          <ngx-skeleton-loader [theme]="{'width': '20vw', 'height': '15px'}"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="favouriteButton" (click)="setFavourite()" class="fav-star"></div>
</div>

<!-- Real Data -->
<div *ngIf="note && !hideMe" >
  <div (click)="openNoteDetails()" [ngClass]="{'clickeable-card': note.id_contact && displayAvatar}">
    <div class="title-avatar-wrap">
   
    <!--avatars-->
      <div class="action-ifo-card" *ngIf="note.id_contact && displayAvatar" [ngClass]="{'action-ifo-with-avatar': note.id_contact && displayAvatar}">
        <!-- <ion-avatar class="square-avatar" *ngIf="!note.id_contact">
          <span class="icon-small">
            <ion-icon class="note-contact-icon" [src]="'assets/icon/contacts.svg'"></ion-icon>
          </span>
        </ion-avatar> -->
        <ion-avatar class="square-avatar" *ngIf="(!displayAvatar && note.id_contact) || (note.id_contact && (contactDetailId || !avatar || !avatar.avatar || avatar.avatar.includes('contacts.svg')))">
            <span class="icon-small">
              <ion-icon class="note-contact-icon" [src]="'assets/icon/groups.svg'"></ion-icon>
            </span>
          </ion-avatar>
          <ion-avatar class="square-avatar avatar-avatar" *ngIf="displayAvatar && note.id_contact && (!contactDetailId && avatar && avatar.avatar && !avatar.avatar.includes('contacts.svg'))">
            <ion-img class="note-contact-icon avatar-avatar" [src]="avatar.avatar"></ion-img>
          </ion-avatar>
      </div>
    
    <!-- title & contact/action type -->
      <div class="action-ifo-card" [ngClass]="{'action-card-text': note.id_contact && displayAvatar, 'action-ifo-with-avatar': note.id_contact && displayAvatar}">
        <ion-text class="action-title">{{ note.title }}</ion-text>

        <div class="action-contact" *ngIf="note.id_contact">
          <ion-text color="medium">{{getDateString()}}<br>
            <ion-text *ngIf="this.displayAvatar">{{ receivedContactName }}</ion-text>
            <ion-text *ngIf="!this.displayAvatar">{{ getLimitedDescription() }}</ion-text>
          </ion-text>
        </div>
        <div class="action-contact" *ngIf="!note.id_contact">
          <ion-text color="medium">{{getDateString()}}</ion-text><br>
          <ion-text *ngIf="!this.displayAvatar">{{ getLimitedDescription() }}</ion-text>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="favouriteButton" size="1" class="note-favourite" (click)="setFavourite()" >
      <ion-icon *ngIf="note.favourite == 1" class="far top-tab-icon new-style-icon fav-star" [src]="'assets/icon/star-fav.svg'"></ion-icon>
      <ion-icon *ngIf="note.favourite == 0 || note.favourite == null" class="far top-tab-icon new-style-icon fav-star" [src]="'assets/icon/star.svg'"></ion-icon>
  </div>
</div>

