import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalService } from "./global.service";
import { NavController, AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "./auth.service";
import { jwtDecode } from "jwt-decode";
import { lastValueFrom } from 'rxjs';
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class CrudService {
  private static authToken: string;
  private static isShowingBadRequestAlert: boolean = false;
  private static isShowingLoginAlert: boolean = false;
  private tempToken = "";

  public static get AuthToken() {
    return this.authToken;
  }

  public static SetAuthToken(token: string) {
    this.authToken = token;
  }

  constructor(
    public http: HttpClient,
    public navController: NavController,
    public translateService: TranslateService,
    public alertController: AlertController,
    public globalService: GlobalService,
    public storageService: StorageService,
    public authService: AuthService
  ) {}

  public async getToken(): Promise<String> {
    if(GlobalService.DebugMode) console.log("obtaining token");

    let curToken;
    let time;
    let currentTime = parseInt(new Date().getTime().toString().slice(0, -3));

    if(CrudService.authToken){
      curToken = await jwtDecode(CrudService.authToken);
      time = curToken.exp;
      if(GlobalService.DebugMode) console.log("Expiration time:" + Math.round(time / 60) + " > " + Math.round(currentTime / 60));
      if(time > currentTime) return CrudService.authToken;
    } 

    if(AuthService.AccessToken){
      curToken = await jwtDecode(AuthService.AccessToken);
      time = curToken.exp;
      if(GlobalService.DebugMode) console.log("Expiration time:" + Math.round(time / 60) + " > " + Math.round(currentTime / 60));
      if(time > currentTime) return AuthService.AccessToken;
    } 

    if(this.authService.backupToken) {
      curToken = await jwtDecode(this.authService.backupToken);
      time = curToken.exp;
      if(GlobalService.DebugMode) console.log("Expiration time:" + Math.round(time / 60) + " > " + Math.round(currentTime / 60));
      if(time > currentTime) return this.authService.backupToken;
    } 
    
    await this.loginRememberMe();

    if(CrudService.authToken) return CrudService.authToken;

    return "";
  }

  public async read(
    route: string, 
    parameters?: any,
    defaultEnvironment: boolean = true,
    useTextInstead: boolean = false,
    loggedin: boolean = true
    ): Promise<any> {
    let currentEnvironment = "";

    if(defaultEnvironment) currentEnvironment = environment.endpoint;

    let headers;

    if(loggedin) headers = this.getHeaders(loggedin, await this.getToken(), parameters, useTextInstead);
    else headers = this.getHeaders(loggedin, undefined, parameters, useTextInstead);

    if(GlobalService.DebugMode){
      console.log(`Read: ${currentEnvironment}${route}`);
      console.log(`Headers`, headers);
    }

    let result = lastValueFrom(this.http.get(`${currentEnvironment}${route}`, {headers: headers})).catch(error =>{
      return Promise.reject();
    });

    return result;  
  }

  public async delete(
    route: string, 
    parameters?: any, 
    defaultEnvironment: boolean = true,
    loggedin: boolean = true
    ): Promise<any> {
    let currentEnvironment = "";

    if(defaultEnvironment) currentEnvironment = environment.endpoint;

    let headers;

    if(loggedin) headers = this.getHeaders(loggedin, await this.getToken(), parameters);
    else headers = this.getHeaders(loggedin, undefined, parameters);

    if(GlobalService.DebugMode){
      console.log(`Delete: ${currentEnvironment}${route}`);
      console.log(`Headers`, headers);
    }

    let result = lastValueFrom(this.http.delete(`${currentEnvironment}${route}`, {headers: headers})).catch(error =>{
      return Promise.reject();
    });

    console.log("log results", await result);
    return result;
  }

  public async post(
    route: string,
    body: any,
    parameters?: any,
    defaultEnvironment: boolean = true,
    loggedin: boolean = true
  ): Promise<any> {
    if(GlobalService.DebugMode) GlobalService.log("post triggered");
    let currentEnvironment = "";

    if(defaultEnvironment) currentEnvironment = environment.endpoint;

    let headers;

    if(loggedin) headers = this.getHeaders(loggedin, await this.getToken(), parameters);
    else headers = this.getHeaders(loggedin, undefined, parameters);

    if(GlobalService.DebugMode){
      console.log(`Post: ${currentEnvironment}${route}`);
      console.log(`Headers`, headers);
      console.log(`Body`, body);
    }

    let result = lastValueFrom(this.http.post(`${currentEnvironment}${route}`, body, {headers: headers})).catch(error =>{
      return Promise.reject();
    });

    console.log("log results", await result);
    return result;
  }

  public async patch(
    route: string,
    body: any,
    parameters?: any,
    defaultEnvironment: boolean = true,
    loggedin: boolean = true
  ): Promise<any> {
    let currentEnvironment = "";

    if(defaultEnvironment) currentEnvironment = environment.endpoint;

    let headers;

    if(loggedin) headers = this.getHeaders(loggedin, await this.getToken(), parameters);
    else headers = this.getHeaders(loggedin, undefined, parameters);

    if(GlobalService.DebugMode){
      console.log(`Patch: ${currentEnvironment}${route}`);
      console.log(`Headers`, headers);
      console.log(`Body`, body);
    }

    let result = lastValueFrom(this.http.patch(`${currentEnvironment}${route}`, body, {headers: headers})).catch(error =>{
      return Promise.reject();
    });

    console.log("log results", await result);
    return result;
  }

  public async put(
    route: string,
    body: any,
    parameters?: any,
    defaultEnvironment: boolean = true,
    loggedin: boolean = true
  ): Promise<any> {
    let currentEnvironment = "";

    if(defaultEnvironment) currentEnvironment = environment.endpoint;

    let headers;

    if(loggedin) headers = this.getHeaders(loggedin, await this.getToken(), parameters);
    else headers = this.getHeaders(loggedin, undefined, parameters);

    if(GlobalService.DebugMode){
      console.log(`Put: ${currentEnvironment}${route}`);
      console.log(`Headers`, headers);
      console.log(`Body`, body);
    }

    let result = lastValueFrom(this.http.put(`${currentEnvironment}${route}`, body, {headers: headers})).catch(error =>{
      return Promise.reject();
    });

    console.log("log results", await result);
    return result;
  }
  
  public getHeaders(needsAuth: Boolean, usedToken: String, parameters?: any[], useTextInstead = false): HttpHeaders {
    let initialHeader = ""

    if(useTextInstead) initialHeader = "text/plain";
    else initialHeader = "application/json";
    
    let headers = new HttpHeaders().set("Content-Type", initialHeader);
    
    if (needsAuth && usedToken) headers = headers.append("Authorization", `Bearer ${usedToken ?? ""}`);
    
    if(parameters){
      console.log("Parameters:", parameters);
      parameters.forEach(parameter => {
        headers = headers.append(parameter.type, parameter.data);
      });
    }
    
    return headers;
  }

  public async onError(res: Response, ctx: CrudService, defaultEnvironment: boolean = true, displayError: boolean = true, tryRelog: boolean = true): Promise<Response> {
    if(!AuthService.LoggedOut){  
      if(res.status != 500 && res.status != 404 && defaultEnvironment && tryRelog){
        console.log("error result", res);
        let attemptLogin = await this.loginRememberMe();
        console.log("Result of login attempt");
        console.log(attemptLogin);
        if(attemptLogin == false){
          CrudService.SetAuthToken(undefined);
          if (!CrudService.isShowingLoginAlert) {
            if(displayError == true){
              this.displayErrorScreen();
            }
          }
        } else {
          if (!CrudService.isShowingLoginAlert) {
            CrudService.isShowingLoginAlert = true;
            let buttons = [{
              cssClass: "cancelButton",
              text: ctx.translateService.instant("OKAY"),
              handler: () => {
                CrudService.isShowingLoginAlert = false
              }
            }];
            buttons.push();

            if(displayError == true){
              this.globalService.ionicAlert(
                buttons,
                "PLEASETRYAGAIN"
              )
            }
          }
        }
      }
  
      return res;
    }
  }

  public async displayErrorScreen(){
    if(!CrudService.isShowingLoginAlert){
      CrudService.isShowingLoginAlert = true;
      let buttons = [];
      buttons.push({
        cssClass: "cancelButton",
        text: this.translateService.instant("LOGIN"),
        handler: () => {
          CrudService.isShowingLoginAlert = false
          this.navController.navigateRoot('login');
        }
      });
      
      this.globalService.ionicAlert(
        buttons,
        this.translateService.instant("SESSIONEXPIRED")
      )
    }
  }

  public async loginRememberMe(){
    console.log("Trigger loginRememberMe");
    let result: boolean = false;
    let returnNow: boolean = false;

    let remember = await this.storageService.getStorage("remember");

    if(GlobalService.DebugMode) console.log("Remember me status");
    if(GlobalService.DebugMode) console.log(remember);
    if (remember) {
      if(GlobalService.DebugMode) console.log("try obtaining credentials");
      let credentials = await this.storageService.getStorage("credentials")
      let userName = "";
      let password = "";
      let rememberme = false;
      if (credentials) {
        if(GlobalService.DebugMode) console.log("try using obtained credentials");
        userName = credentials.username;
        password = credentials.password;
        rememberme = true;
      } else {
        if(!AuthService.TemporaryRememberMe.username || !AuthService.TemporaryRememberMe.password){
          if(GlobalService.DebugMode) console.log("failed to obtain any credentials");
          result = false;
          returnNow = true;
        } else {
          if(GlobalService.DebugMode) console.log("try using temporary auth information");
          userName = AuthService.TemporaryRememberMe.username;
          password = AuthService.TemporaryRememberMe.password;
        }
      }
      
      if(GlobalService.DebugMode) console.log("login from crudservice 1");
      let attempt = await this.authService.login(userName, password, rememberme, true).catch((returnedCatch) =>{
        return returnedCatch;
      });
  
      if (attempt.success && !result) {
        CrudService.authToken = attempt.accessToken;
        this.tempToken = attempt.accessToken;
        if(GlobalService.DebugMode) console.log("succeeded");
        result = true;
        return true;
      } else {
        if(GlobalService.DebugMode) console.log("failed");
        result = false;
        returnNow = true;
      }

      if(returnNow) return result;
      
      if(!result){
        if(AuthService.TemporaryRememberMe){
          if(GlobalService.DebugMode) console.log("try using temporary auth information because no result found");
          let userName = AuthService.TemporaryRememberMe.username;
          let password = AuthService.TemporaryRememberMe.password;
          
        if(GlobalService.DebugMode) console.log("login from crudservice 2");
          let attempt = await this.authService.login(userName, password, false, true);
      
          if(attempt && attempt.success) {
            CrudService.authToken = attempt.accessToken;
            this.tempToken = attempt.accessToken;
            if(GlobalService.DebugMode) console.log("succeeded");
            result = true;
            return true;
          } else {
            if(GlobalService.DebugMode) console.log("failed");
            result = false;
            return false;
          }
        } else {
          if(GlobalService.DebugMode) console.log("No temporary username detected, trying storage");
          
          let credentials = await this.storageService.getStorage("credentials");
          
          if(credentials){
            if(GlobalService.DebugMode) console.log("trying saved credentials", credentials.username);
            let username = credentials.username;
            let password = credentials.password;

            if(GlobalService.DebugMode) console.log("login from crudservice 3");
            let attempt = await this.authService.login(username, password, false, true);
    
            if(attempt && attempt.success) {
              CrudService.authToken = attempt.accessToken;
              this.tempToken = attempt.accessToken;
              if(GlobalService.DebugMode) console.log("succeeded");
              result = true;
              return true;
            } else {
              if(GlobalService.DebugMode) console.log("failed");
              result = false;
              return false;
            }
          }
        }
      }
    } else if(AuthService.TemporaryRememberMe.username && AuthService.TemporaryRememberMe.password){
      if(GlobalService.DebugMode) console.log("try using temporary auth information because no remember me");
      let userName = AuthService.TemporaryRememberMe.username;
      let password = AuthService.TemporaryRememberMe.password;
        
      if(GlobalService.DebugMode) console.log("login from crudservice 4");
      let attempt = await this.authService.login(userName, password, false, true).catch((returnedCatch) =>{
        return returnedCatch;
      });
  
      if (attempt.success && !result) {
        CrudService.authToken = attempt.accessToken;
        this.tempToken = attempt.accessToken;
        if(GlobalService.DebugMode) console.log("succeeded");
        result = true;
        return true;
      } else {
        if(GlobalService.DebugMode) console.log("failed");
        result = false;
        return false;
      }
    } else {
      if(GlobalService.DebugMode) console.log("failed");
      result = false;
      this.displayErrorScreen();
      return false;
    }
    return result;
  }
}