import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage-angular';
import { MenuController } from '@ionic/angular';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/settings.service';
import { GlobalService } from 'src/app/services/global.service';
import { ContactTransferService } from 'src/app/services/contact-transfer.service';
import { SubscriptionService } from 'src/app/services/subscription.service'
import { ActionService } from './services/action.service';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';
import { StorageService } from "src/app/services/storage.service";
import { register } from 'swiper/element/bundle';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public menuPages: any[];
  public iOS: boolean = true;
  public url: string;
  public loadingLog: boolean = false;
  public supportedLanguages = ["nl", "en", "de"];

  public debugCounter = 0;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private storage: Storage,
    private menuController: MenuController,
    private authService: AuthService,
    private router: Router,
    private navController: NavController,
    private settingsService: SettingsService,
    private globalService: GlobalService,
    private contactTransferService: ContactTransferService,
    private subscriptionService: SubscriptionService,
    private storageService: StorageService,
    private keyboard: Keyboard
  ) {}

  async ngOnInit(){
    register();    
    GlobalService.DebugLogResetTimer = new Date().getTime();
    await this.initializeStorage();

    this.setupLanguage();
    this.setupMenuPages();
    await this.initializeApp();
    if(!SettingsService.Settings){
      this.settingsService.initializeSettings();
    }
  }

  setupLanguage(){
    this.translate.setDefaultLang("en");
    let browserLanguage = this.translate.getBrowserLang()
    if(this.supportedLanguages.includes(browserLanguage)){  
      console.log("Browser language supported: " + this.translate.getBrowserLang());
      this.translate.use(this.translate.getBrowserLang());
    }
    else {
      console.log("Browser language not supported: " + this.translate.getBrowserLang());
      this.translate.use("en");
    }
  }

  async initializeStorage(){
    GlobalService.log("Storage ready!", true);
    this.storageService.setStorage("does storage work", "true");
  }

  async initializeApp() {
    await this.platform.ready();
    this.keyboard.disableScroll(false);
    this.statusBar.overlaysWebView(false);
    this.statusBar.styleLightContent();
    if(!this.platform.is("ios")){
      this.iOS = false;
    } 

    if(await this.authService.getUserObj()){
      GlobalService.HideMenu = false;
    }

    if(GlobalService.DebugMode) GlobalService.log("App started up succesfully", true);
    GlobalService.IsAppReady = true;

    await this.subscriptionService.prepareSubscriptions();
    await this.authService.initializeService();   
  }

  async openPage (page: any) {
    GlobalService.log("navigated to", page.route);
    this.clearForTab();
    if(page.title == "ACTIONS"){
      ActionService.ActionPageTab = 0;
      this.navController.navigateRoot(page.route);
      ActionService.ResetActionPage = true;
    }  else if(page.title == "TOUCHPOINTS"){
      ActionService.ActionPageTab = 2;
      this.navController.navigateRoot(page.route);
      ActionService.ResetActionPage = true;
    }
    else {
      this.navController.navigateRoot(page.route);
    }
  }

  async logout () {
    this.settingsService.removeLocalDataConfirmed();
    GlobalService.log("logout", "triggered");
    await this.authService.logout();
    this.navController.navigateRoot('login');
  }

  closeMenu() {
    this.menuController.close();
  }

  setupMenuPages () {
    this.menuPages = [];

    if(this.globalService.isDesktop()){
      this.menuPages.push({
        icon: "assets/icon/dashboard.svg",
        title: "DASHBOARD",
        route: "tabs/dashboard"
      });
      this.menuPages.push({
        icon: "assets/icon/contacts.svg",
        title: "CONTACTS",
        route: "tabs/contacts"
      });
      this.menuPages.push({
        icon: "assets/icon/actions.svg",
        title: "ACTIONS",
        route: "tabs/actions"
      });
      this.menuPages.push({
        icon: "assets/icon/notes.svg",
        title: "NOTES",
        route: "tabs/notes"
      });
      this.menuPages.push({
        icon: "assets/icon/touchpoint.svg",
        title: "TOUCHPOINTSPAGE",
        route: "tabs/touchpoints"
      });
      this.menuPages.push({
        icon: "assets/icon/goals.svg",
        title: "GOALS",
        route: "tabs/actionpath"
      });
    }

    this.menuPages.push({
      icon: "assets/icon/settings.svg",
      title: "SETTINGS",
      route: "settings"
    });

    this.menuPages.push({
      icon: "assets/icon/bell-04.svg",
      title: "FUNCTIONALITY",
      route: "functionality"
    });


    if(!this.globalService.isDesktop()){
      this.menuPages.push({
        icon: "assets/icon/goals.svg",
        title: "GOALS",
        route: "tabs/actionpath"
      });
    }

    this.menuPages.push({
      icon: "assets/icon/about-us.svg",
      title: "ABOUTUS",
      route: "about"
    });

    this.menuPages.push({
      icon: "assets/icon/privacy.svg",
      title: "Privacy",
      route: "privacy"
    });

    this.menuPages.push({
      icon: "assets/icon/help-circle.svg",
      title: "HELPANDCONTACT",
      route: "help"
    });
  }

  showImportLoading(){
    if(ContactTransferService.IsImporting.boolean){ return true; }
    
    return false;
  }

  displayContactImportLength(){
    if(ContactTransferService.IsImporting.contacts) { return ContactTransferService.IsImporting.contacts };

    return 0;
  }

  displayContactimportProgressLength(){
    if(ContactTransferService.IsImporting.importProgress) { return ContactTransferService.IsImporting.importProgress };

    return 0;
  }

  showForcedLoadingSub(){
    if(SubscriptionService.LoadingSubData){ return true; }
    
    return false;
  }

  isDesktop(){
    return this.globalService.isDesktop();
  }

  public goBack() {
    this.globalService.goBack()
  }

  public hideMenu() {
    return GlobalService.HideMenu;
  }

  public blackLoader(){
    return GlobalService.BlackLoader;
  }

  public showCancelSub(){
    GlobalService.log("Cancel sub results", GlobalService.showCancelSubFunc());
    return GlobalService.showCancelSubFunc();
  }

  public cancelSubLoad(){
    GlobalService.ShowCancelSub = false;
    SubscriptionService.LoadingSubData = false;
  }

  dasboardTap(){
    GlobalService.debugTap();
  }

  debugLeaveMenu(){
    console.log("triggered leave menu");
    GlobalService.DisplayDebugPopup = false;
    GlobalService.DebugCounter = 0;
  }

  debugDashboard(){
    console.log("triggered dashboard");
    GlobalService.DisplayDebugPopup = false;
    GlobalService.DebugCounter = 0;
    GlobalService.IsLoading = false;
    this.openPage({route: "tabs/dashboard"});
  }

  async debugResetData(){
    console.log("triggered reset data");
    GlobalService.DisplayDebugPopup = false;
    GlobalService.DebugCounter = 0;
    GlobalService.IsLoading = false;
    this.settingsService.removeLocalDataConfirmed();
  }

  async debugDisplayLog(){
    console.log("triggered display log");
    this.loadingLog = true;
    GlobalService.DebugCounter = 0;
    await this.globalService.displayLog();
    GlobalService.DisplayDebugPopup = false;
    this.loadingLog = false;
  }

  debugModeToggle(){
    console.log("triggered toggle debugmode");
    GlobalService.DebugCounter = 0;
    GlobalService.IsLoading = false;
    GlobalService.DisplayDebugPopup = false;
    if(GlobalService.DebugMode) GlobalService.DebugMode = false;
    else GlobalService.DebugMode = true;
  }

  debugTap(){
    GlobalService.debugTap();
  }

  debugMode(){
    return GlobalService.DebugMode;
  }

  DisplayDebugPopup(){
    return GlobalService.DisplayDebugPopup;
  }

  isLoading(){
    return GlobalService.IsLoading;
  }

  showConfetti(){
    return GlobalService.ShowConfetti;
  }

  showQR(){
    return GlobalService.ShowQR;
  }

  hideQR(){
    GlobalService.ShowQR = false;
  }

  qrCode(){
    return GlobalService.QrCode;
  }

  isIos(){
		if(this.platform.is("ios") == true) return true;
    return false;
  }

  isAndroid(){
		if(this.platform.is("android") == true) return true;
    return false;
  }

  clearForTab(){
    SettingsService.unsetEverything();
  }
}
