import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import Note from "src/app/models/note";
import { Router } from "@angular/router";
import { NoteService } from "src/app/services/note.service";
import { TranslateService } from "@ngx-translate/core";
import { NavController } from '@ionic/angular';
import { AvatarLoaderService } from 'src/app/services/avatar-loader.service';
import { ContactService } from "src/app/services/contact.service";
import { GlobalService } from "src/app/services/global.service";

@Component({
  selector: "app-note-item",
  templateUrl: "./note-item.component.html",
  styleUrls: ["./note-item.component.scss"],
})
export class NoteItemComponent implements OnInit {
  @Input() public note: Note;
  @Input() public clickable: boolean = true;
  @Input() public lines: boolean = true;
  @Input() public favouriteButton: boolean = false;
  @Input() public contactDetailId: number;
  @Input() public displayAvatar: boolean = true;;
  @Output() onFavourite: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  public avatar = undefined;
  public receivedContactName = undefined;
  public hideMe: boolean = false;

  constructor(
    private router: Router,
    private noteService: NoteService,
    private contactService: ContactService,
    private navController: NavController,
    private translateService: TranslateService,
    private avatarLoader: AvatarLoaderService
  ) {}

  ngOnInit() {
    if(this.note && this.contactDetailId) this.note.id_contact = this.contactDetailId;
    if(this.displayAvatar) this.getContactInformation();
  }

  public openNoteDetails() {
    if (!this.clickable) return;
    
    this.onClick.emit();

    if(this.contactDetailId) this.note.id_contact = undefined;
    NoteService.SetCurrentNote(this.note);
    this.navController.navigateRoot("note-details");
    // NoteService.SetIsEditing(true);
    // this.router.navigateByUrl("add-note");
  }

  public getDateString() {
    if(!this.note.created){
      return "";
    }
    let date: Date = new Date(this.note.created.split(".")[0]);

    return date.toLocaleDateString();
  }

  public setFavourite() {
    let isFavourite = this.note.favourite;
    let favouriteStatus = +!isFavourite; // Negate boolean and convert to number
    let noteType: string;
    let noteTypeId: number;

    if(this.contactDetailId) this.note.id_contact = undefined;

    this.note.favourite = favouriteStatus;

    if (
      this.note.id_contact != 0 &&
      this.note.id_touchpoint == 0 &&
      this.note.id_action == 0
    ) {
      noteType = "contact";
      noteTypeId = this.note.id_contact;
    } else if (
      this.note.id_contact == 0 &&
      this.note.id_touchpoint != 0 &&
      this.note.id_action == 0
    ) {
      noteType = "touchpoint";
      noteTypeId = this.note.id_touchpoint;
    } else if (
      this.note.id_contact == 0 &&
      this.note.id_touchpoint == 0 &&
      this.note.id_action != 0
    ) {
      noteType = "action";
      noteTypeId = this.note.id_action;
    }else {
      noteType = "personal";
      noteTypeId = 0;
    } 

    this.noteService
      .setFavourite(this.note.id_notes, noteType, noteTypeId, favouriteStatus, this.note)
      .then((res) => {
        this.onFavourite.emit();
      });
  }
  
  public async getAvatar () {
    if (!this.note || !this.note.id_contact && !this.contactDetailId) return;

    if(!this.avatar){
      this.avatarLoader.addToQueue({"id_contact": this.note.id_contact});
      this.avatar = await this.avatarLoader.getAvatar({"id_contact": this.note.id_contact}, true);
    }
  }

  public async getContactInformation(){
    if (!this.note || !this.note.id_contact) return;

     if(!this.receivedContactName){
      let obtainedContact = await this.contactService.getMiniContact(this.note.id_contact)
      this.receivedContactName = ContactService.setDisplayName(obtainedContact, 1);
    }

    this.getAvatar();
  }

  public getLimitedDescription(){
    let finalDesc = this.note.note;
    if(this.note.note.includes("\n"))
      finalDesc = finalDesc.split("\n")[0];

    return finalDesc;
  }

  public getSkeletonEnabled(){
    return GlobalService.DisplaySkeleton;
  }
}
