import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import actionpath from 'src/app/models/actionpath';
import { Router } from '@angular/router';
import { ActionpathService } from 'src/app/services/actionpath.service';
import { ContactService } from 'src/app/services/contact.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-actionpath-item',
  templateUrl: './actionpath-item.component.html',
  styleUrls: ['./actionpath-item.component.scss'],
})
export class actionpathItemComponent implements OnInit {
  @Input() public actionpath: actionpath;
  @Input() public clickable: boolean = true;
  @Input() public lines: boolean = true;
  @Input() public elementCenter: boolean = true;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();

  public active: boolean = false;
  public hideMe: boolean = false;
  public dateString = "";
  
  constructor(
    private navController: NavController, 
    private router: Router,
    private actionpathService: ActionpathService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
  }

  public openActionPathDetails() {
    if (!this.clickable) return;
    
    this.onClick.emit();
    ActionpathService.SetCurrentactionpath(this.actionpath);
    this.navController.navigateRoot("actionpath-details");
  }

  public getDisplayName(retrievedContact){
    return ContactService.setDisplayName(retrievedContact);
  }

  public isOverdue(){
    if(this.actionpath.finaldate && new Date(this.actionpath.finaldate) < new Date())
      return true;
    return false;
  }

  public prettyDate(){
    if(this.dateString == "") this.dateString = this.globalService.getDateString(this.actionpath.finaldate)
    else return this.dateString;
  }

  public getSkeletonEnabled(){
    return GlobalService.DisplaySkeleton;
  }
}
