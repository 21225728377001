import { Injectable } from "@angular/core";
import { CrudService } from "./crud.service";
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "./auth.service";
import { GlobalService } from "./global.service";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StorageService } from "./storage.service";
import { environment } from "src/environments/environment";
import { AdministrationService } from "./administration.service";

@Injectable({
  providedIn: "root",
})

export class VoipService extends CrudService {
  constructor(
    public httpClient: HttpClient,
    public storageService: StorageService,
    public navController: NavController,
    public translateService: TranslateService,
    public alertController: AlertController,
    public authService: AuthService,
    public globalService: GlobalService,
    public administrationService: AdministrationService
  ) {
    super(httpClient, navController, translateService, alertController, globalService, storageService, authService);
  }

  public async callThroughVOIP(voip_number: string, dial: string){
    GlobalService.IsLoading = true;
    dial = this.fixDial(dial);
    let body = {
      "internal_number": voip_number,
      "dial": dial
    }

    let voipsuccess = await this.post("/voip/makecall", body);
    console.log("voip call result", voipsuccess);
    // if(this.globalService.isIos()){
    //   window.location.assign('my-app://com.dstny.mobile.ios');
    // }
    GlobalService.IsLoading = false;
    return;
  }

  public async setInternalNumber(personal_number: string){
    if(personal_number == undefined) personal_number = "NULL";
    GlobalService.IsLoading = true;
    let numberSuccess = await this.put("/user/setinternalnumber/" + personal_number, null);
    console.log("set internal number result", numberSuccess);
    GlobalService.IsLoading = false;
  }

  public async hasVoip(){
    if(!AdministrationService.UserData) await this.administrationService.getFullUserData();
    if(AdministrationService.UserData.administration && AdministrationService.UserData.administration.has_voip_customer_key && AdministrationService.UserData.internal_number){
      return true;
     }
     return false;
  }

  public fixDial(dial){
    dial = dial.replaceAll("-", "");
    dial = dial.replaceAll(" ", "");
    dial = dial.replaceAll("(", "");
    dial = dial.replaceAll(")", "");
    return dial;
  }
}