<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-menu-button>
        <ion-icon class="new-style-icon left-menu-icon" [src]="'assets/icon/menu.svg'"></ion-icon>
      </ion-menu-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button (click)="openAddActionPage()">
        <ion-icon class="new-style-icon add-plus" [src]="'assets/icon/plus.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>

    <app-header [title]="'ACTIONS' | translate" *ngIf="currentTab != ActionTabs.Touchpoints"></app-header>
    <app-header [title]="'TOUCHPOINTS' | translate" *ngIf="currentTab == ActionTabs.Touchpoints"></app-header>

  </ion-toolbar>

  <app-searchbar *ngIf="currentTab != ActionTabs.Touchpoints" [Type]="'action'"></app-searchbar>
  <app-searchbar *ngIf="currentTab == ActionTabs.Touchpoints" [Type]="'touchpoint'"></app-searchbar>

</ion-header>

<div *ngIf="returnActionService().RefreshActionPage">
  {{ callRefreshFunction() }}
</div>

<div *ngIf="returnActionService().ResetActionPage">
  {{ ngOnInit() }}
</div>

<div class="subheader" *ngIf="this.currentTab != ActionTabs.Touchpoints">
  <div [ngClass]="{'ios-search-margin-small': isIos()}"  class="search-margin-small"></div>
  <ion-grid class="ion-no-padding">
    <ion-row id="tabs-container">
      <ion-col>
        <ion-item lines="none" [style]="getStyleForTab(ActionTabs.Actions)" class="tab-item ion-text-center" [button]="true"
          (click)="switchTabTo(ActionTabs.Actions)">
          <ion-text class="center-text tab-text">
            <ion-icon class="top-tab-icon" [src]="'assets/icon/contact-actions.svg'"></ion-icon>
            {{ "ACTIONS" | translate}}
          </ion-text>
        </ion-item>
      </ion-col>
      <ion-col>
        <ion-item lines="none" [style]="getStyleForTab(ActionTabs.Archive)" class="tab-item ion-text-center" [button]="true"
          (click)="switchTabTo(ActionTabs.Archive)">
          <ion-text class="center-text tab-text">
            <ion-icon class="top-tab-icon" [src]="'assets/icon/actions.svg'"></ion-icon>
            {{ "CLOSED" | translate}}
          </ion-text>
        </ion-item>
      </ion-col>
      <!-- <ion-col>
        <ion-item lines="none" [style]="getStyleForTab(ActionTabs.Touchpoints)" class="tab-item ion-text-center" [button]="true"
          (click)="switchTabTo(ActionTabs.Touchpoints)">
          <ion-text class="center-text tab-text">
            <i class="fas fa-clipboard-user"></i>
            {{ "TOUCHPOINTSPAGE" | translate}}
          </ion-text>
        </ion-item>
      </ion-col> -->
    </ion-row>
  </ion-grid>
</div>

<ion-content *ngIf="currentTab == ActionTabs.Actions">
  <div *ngIf="this.firstLoad || (!failedToLoad && (isLoadingActions || !returnActionService().Actions))">
    <div *ngFor="let i of [1,2,3,4]" class="page-element-center">
      <div>
        <app-action-item class="action-page-item"  [lines]="false" [clickable]="false"></app-action-item >
      </div>
    </div>
  </div>

  <div *ngIf="!isLoadingActions && returnActionService().Actions != undefined">
    <div *ngFor="let actionGroup of returnActionService().ActionGroups; let i = index" class="page-element-spacing-top action-group-holder">
      <div *ngIf="actionGroup.name != 'TOUCHPOINT' && actionGroup.name != 'ARCHIVE'">
        <ion-text *ngIf="actionGroup.actions.length > 0" class="page-headings">
          {{actionGroup.name | translate}}
        </ion-text>
        <div *ngFor="let action of displayActions[i]" class="action-holder">
          <div *ngIf="action.status == 0 || !action.status" class="page-element-center">
            <app-action-item [lines]="false" [action]="action" class="action-page-item" [actionPage]="true"></app-action-item >
          </div>
        </div>
  
        <div class="gray-border page-element-center" *ngIf="currentSlice[i] < actionGroup.actions.length">
          <ion-item class="show" (click)="getShownItems(true, i)"><div class="center-text full-width show-more-less">{{ "SHOWMORE" | translate }}</div></ion-item>
        </div>
        <div class="gray-border page-element-center" *ngIf="currentSlice[i] > 16">
          <ion-item class="show" (click)="getShownItems(false, i)"><div class="center-text full-width show-more-less">{{ "SHOWLESS" | translate }}</div></ion-item>
        </div>
      </div>
    </div>
    <div class="No-data-found" *ngIf="!this.firstLoad && OpenActionSize() == 0" >
      <ion-text>{{"NO2" | translate}} {{"ACTIONS" | translate}} {{"FOUND" | translate}}</ion-text>
    </div>
  </div>
  <div class="No-data-found" *ngIf="!this.firstLoad && failedToLoad" >
    <ion-text>{{"NO2" | translate}} {{"ACTIONS" | translate}} {{"FOUND" | translate}}</ion-text>
  </div>
</ion-content>


<ion-content *ngIf="currentTab == ActionTabs.Archive">
  <div *ngIf="this.firstLoad || (!failedToLoad && (isLoadingActions || !returnActionService().Actions))">
    <div *ngFor="let i of [1,2,3,4]" class="page-element-center">
      <div>
        <app-action-item class="action-page-item"  [lines]="false" [clickable]="false"></app-action-item >
      </div>
    </div>
  </div>

  <div *ngIf="!isLoadingActions && returnActionService().Actions != undefined">
    <div *ngFor="let actionGroup of returnActionService().ActionGroups; let i = index" class="page-element-spacing-top action-group-holder">
      <div *ngIf="actionGroup.name == 'ARCHIVE'">
        <div *ngFor="let action of displayActions[i]" class="action-holder">
          <div *ngIf="action.status > 0" class="page-element-center">
            <div class="ion-no-padding">
              <app-action-item class="action-page-item" (onFinish)="deleteThis(action)" (onDelete)="deleteThis(action)" [lines]="false" [action]="action"></app-action-item >
            </div>
          </div>
        </div>

        <div class="gray-border page-element-center" *ngIf="currentSlice[i] < actionGroup.actions.length">
          <ion-item class="show" (click)="getShownItems(true, i)"><div class="center-text full-width show-more-less">{{ "SHOWMORE" | translate }}</div></ion-item>
        </div>
        <div class="gray-border page-element-center" *ngIf="currentSlice[i] > 16">
          <ion-item class="show" (click)="getShownItems(false, i)"><div class="center-text full-width show-more-less">{{ "SHOWLESS" | translate }}</div></ion-item>
        </div>
        <div class="No-data-found" *ngIf="!this.firstLoad && actionGroup.actions && actionGroup.actions.length == 0">
          <ion-text>{{"NO2" | translate}} {{"ACTIONS" | translate}} {{"FOUND" | translate}}</ion-text>
        </div>
      </div>
    </div>
  </div>
  <div class="No-data-found" *ngIf="!this.firstLoad && failedToLoad">
    <ion-text>{{"NO2" | translate}} {{"ACTIONS" | translate}} {{"FOUND" | translate}}</ion-text>
  </div>
</ion-content>


<ion-content *ngIf="currentTab == ActionTabs.Touchpoints">
  <div [ngClass]="{'ios-search-margin': isIos()}"  class="search-margin"></div>
  <div *ngIf="this.firstLoad || (!failedToLoad && (isLoadingActions || !returnActionService().Actions))">
    <div *ngFor="let i of [1,2,3,4]" class="page-element-center">
      <div>
        <app-action-item class="action-page-item"  [lines]="false" [clickable]="false"></app-action-item >
      </div>
    </div>
  </div>

  <div *ngIf="!firstLoad && !isLoadingActions && returnActionService().Actions != undefined && returnActionService().ActionGroups != undefined">
    <div *ngFor="let actionGroup of returnActionService().ActionGroups; let i = index" class="page-element-spacing-top action-group-holder">
      <div *ngIf="actionGroup.name == 'TOUCHPOINT'">
        <ion-item *ngIf="actionGroup.actions && actionGroup.actions.length > 0" class="gray-border page-element-center">
          <div (click)="getShownItems(true, i)" class="left-large-button" *ngIf="currentSlice[i] < actionGroup.actions.length">
            <div><div class="center-text full-width show-more-less">{{ "SHOWMORE" | translate }}</div></div>
          </div>
          <div (click)="resetScrollDown()" class="right-small-button">
            <div class="center-text full-width show-more-less">&darr;</div>
          </div>
        </ion-item>
        <!-- <div *ngIf="currentSlice[i] > 16">
          <ion-item class="show" (click)="getShownItems(false, i)"><div class="center-text full-width show-more-less">{{ "SHOWLESS" | translate }}</div></ion-item>
        </div> -->
        <div *ngFor="let action of displayActions[i].slice().reverse()" class="action-holder">
          <div *ngIf="action.status > 1" class="page-element-center">
            <div class="ion-no-padding">
              <app-action-item class="action-page-item" (onFinish)="deleteThis(action)" (onDelete)="deleteThis(action)" [lines]="false" [action]="action"></app-action-item >
            </div>
          </div>
        </div>
        <div class="No-data-found" *ngIf="!this.firstLoad && actionGroup.actions && actionGroup.actions.length == 0">
          <ion-text>{{"NO2" | translate}} {{"TOUCHPOINTS" | translate}} {{"FOUND" | translate}}</ion-text>
        </div>
        <div *ngIf="!this.firstScroll" #snaptobottom id="snaptobottom">
          <div style="color: transparent">{{scrollDown()}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="No-data-found" *ngIf="!this.firstLoad && failedToLoad">
    <ion-text>{{"NO2" | translate}} {{"TOUCHPOINTS" | translate}} {{"FOUND" | translate}}</ion-text>
  </div>
</ion-content>