import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ActionService } from "src/app/services/action.service";
import Action from "src/app/models/action";
import Contact from 'src/app/models/contact';
import { TranslateService } from "@ngx-translate/core";
import { NavController, AlertController } from "@ionic/angular";
import { SubscriptionService} from "src/app/services/subscription.service";
import { GlobalService } from 'src/app/services/global.service';
import { ContactService } from "src/app/services/contact.service";
import { ContactTabs } from "../contact-details/contact-details.page";

@Component({
  selector: "app-actions",
  templateUrl: "actions.page.html",
  styleUrls: ["actions.page.scss"],
})
export class ActionsPage implements OnInit {
  public ActionTabs: typeof ActionTabs = ActionTabs;
  public isLoadingActions: boolean;
  public displayActions = [];
  public curActions = [];
  public currentTab: ActionTabs = ActionTabs.Actions;
  
  public currentSlice = [];
  public startingSlice: number = 16;
  public sliceSize: number = 16;
  public failedToLoad: boolean = false;
  public firstLoad: boolean = true;
  public firstScroll: boolean = false;

  constructor(private router: Router, 
    private navController: NavController, 
    private actionService: ActionService,
    private subscriptionService: SubscriptionService,
    private globalService: GlobalService
    ) {}

    
  async ngOnInit() {
    ActionService.ResetActionPage = false;
    this.firstLoad = true;
    this.switchTabTo(ActionService.ActionPageTab);
    GlobalService.log(window.location.href);
    if(window.location.href.includes("touchpoint")){
      ActionService.ActionPageTab = ActionTabs.Touchpoints;
      this.currentTab = ActionTabs.Touchpoints;
    }
    this.initializePage();
  }

  public async initializePage(){
    this.firstLoad = true;
    await this.refresh();
    if(this.currentTab == ActionTabs.Actions) await this.calculateActionGroupSize();
    this.firstLoad = false;
  }

  public async scrollDown(){
    if(!this.firstScroll){
      await GlobalService.sleep(100)
      let element = document.getElementById("snaptobottom");
      if(element) {
        element.scrollIntoView();
        this.firstScroll = true;
      }
    }
  }

  public async resetScrollDown(){
    this.firstScroll = false;
  }
  
  public switchTabTo(tab: ActionTabs) {
    console.log("go to " + tab + " tab");
    ActionService.ActionPageTab = tab;
    this.currentTab = tab;

    this.refresh();
  }

  public getStyleForTab(tab: ActionTabs) {
    return {
      color:
        this.currentTab == tab ? "var(--ion-color-secondary, rgb(247, 150, 11))" : "black",
    };
  }

  public async refresh() {
    GlobalService.enableSkeletonScreen();
    GlobalService.log("Refreshing...");
    this.isLoadingActions = true;
    let currentStatus: number = this.currentTab;
    console.log("current obtained status", currentStatus);

    if(this.currentTab == ActionTabs.Actions) await this.actionService.getActionsByStatus(0);
    else if(this.currentTab == ActionTabs.Archive) await this.actionService.getActionsByStatus(1);
    else if(this.currentTab == ActionTabs.Touchpoints) await this.actionService.getActionsByStatus(2);

    console.log("found action groups", ActionService.ActionGroups);
    if (ActionService.HasLoadedActions[currentStatus] == false) {
      await this.actionService.getActionsByStatus(currentStatus);
      GlobalService.log(`Loaded ${ActionService.Actions.length} actions`);
      this.isLoadingActions = false;
      ActionService.HasLoadedActions[currentStatus] = true;
      let counter = 0;
      for(let actiongroup of ActionService.ActionGroups){
        this.currentSlice.push(16);
        this.refreshAll(counter, actiongroup);
        counter++;
      };
      console.log("final action groups", ActionService.ActionGroups);
    } else {
      GlobalService.log(`Loaded ${ActionService.Actions.length} actions`);
      let counter = 0;
      for(let actiongroup of ActionService.ActionGroups){
        this.currentSlice.push(16);
        this.refreshAll(counter, actiongroup);
        counter++;
      };
      this.isLoadingActions = false;
    }
  }

  public async openAddActionPage() {
    if(await this.subscriptionService.checkSubscription(ActionService.Actions.length, "ACTIONS")){
      if(this.currentTab == 2) ActionService.ActionStatus = 2;
      else ActionService.ActionStatus = 0;
      ActionService.SelectedGoals = undefined;
      
      this.navController.navigateRoot("add-action");
    }
  }

  public returnActionService(){
    return ActionService;
  }

  public getShownItems (increase: boolean, index: number) {
    if(increase == false){
      this.currentSlice[index] = this.currentSlice[index]-this.sliceSize;
    }
    else{
      this.currentSlice[index] = this.currentSlice[index]+this.sliceSize;
    }
    this.displayActions[index] = this.curActions[index].slice(0, this.currentSlice[index]);
  }

  public refreshAll(index: number, group){
    this.curActions[index] = [];
    this.displayActions[index] = [];
    let currentActions = group.actions;

    for (var i = 0; i < currentActions.length; i += this.sliceSize) {
      let chunk = currentActions.slice(i, Math.min(i + this.sliceSize, currentActions.length));
      chunk.forEach(chunkpiece => {
        this.curActions[index].push(chunkpiece);
      });
    }

    if(this.curActions[index] != undefined){
      if(this.currentSlice[index] > this.curActions[index].length){
        this.currentSlice[index] = 16;
      }
      this.displayActions[index] = this.curActions[index].slice(0,this.currentSlice[index]);
      
    } else {
      this.displayActions[index] = this.curActions[index].slice(0,this.startingSlice);
      this.currentSlice[index] = this.startingSlice;
    }
  }

  calculateActionGroupSize(){
    console.log("action group size", ActionService.ActionGroups);
    let finalSize = 0;
    for(let actiongroup of ActionService.ActionGroups){
      if(actiongroup.name != 'TOUCHPOINT' && actiongroup.name != 'ARCHIVE')
        for(let action of actiongroup.actions){
          finalSize++;
        }
    }; 
    ActionService.OpenActionSize = finalSize;
    console.log("setting group size", finalSize);
    ActionService.ActionsCounted = true;
    return ActionService.OpenActionSize;
  }

  async actionFinishReroute(action){
    console.log("action is finished, sending user forward");
    if(action.contacts && action.contacts.length == 1){
      ContactService.SetCurrentContact(action.contacts[0]);
      ContactService.ContactDetailPageTab = ContactTabs.Touchpoints;
      this.navController.navigateRoot("contact-details");
    }
    else if (action.contacts && action.contacts.length > 1){
      this.switchTabTo(ActionTabs.Archive);
    }
    else {
      this.switchTabTo(ActionTabs.Touchpoints);
    }
  }

  OpenActionSize(){
    return ActionService.OpenActionSize;
  }

  public callRefreshFunction(){
    ActionService.RefreshActionPage = false;
    console.log("refreshed actions by force");
    this.resetScrollDown();
    this.refresh();
  }

  public deleteThis(action){
    this.actionService.removeActionFromList(action);
    this.refresh();
  }
  
  isIos(){
    return this.globalService.isIos();
  }
}

interface ActionGroup {
  name: string;
  actions: Action[];
}

export enum ActionTabs {
  Actions,
  Archive,
  Touchpoints,
}