import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';
import PhotoModel from '../models/photo';


@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  private static curPhoto: Photo;
  private height: number;
  private width: number;

  public static get CurPhoto () {
    return this.curPhoto;
  }

  public static set CurPhoto (photo) {
    this.curPhoto = photo;
  }

  constructor() { }

  public async takePhoto() {
    // Take a photo

    let options = {
      resultType: CameraResultType.Base64, 
      source: CameraSource.Camera, 
      allowEditing: false,
      quality: 100
    };

    const capturedPhoto: Photo = await Camera.getPhoto(options);

    PhotoService.CurPhoto = capturedPhoto;

    let dimensions = await this.getImageDimensions("data:image/png;base64, " + PhotoService.CurPhoto.base64String)
    let newHeight = this.height;
    let newWidth = this.width;

    while(newHeight > 300 || newWidth > 300){
      newHeight = newHeight * 0.8;
      newWidth = newWidth * 0.8;
    }

    this.compressImage("data:image/png;base64, " + PhotoService.CurPhoto.base64String, newWidth, newHeight).then(compressed => {
      PhotoService.CurPhoto.base64String = compressed.toString().split("data:image/png;base64,")[1];
    })    
    
    return capturedPhoto;
  }

  public async openGallery () {
    // Take a photo
    const capturedPhoto: Photo = await Camera.getPhoto({
      resultType: CameraResultType.Base64, 
      source: CameraSource.Photos, 
      allowEditing: false,
      quality: 75
    });

    PhotoService.CurPhoto = capturedPhoto;

    this.compressImage("data:image/png;base64, " + PhotoService.CurPhoto.base64String, 300, 300).then(compressed => {
      PhotoService.CurPhoto.base64String = compressed.toString().split("data:image/png;base64,")[1];
    })    

    return capturedPhoto;
  }

  public compressImage(src, newX, newY){
    return new Promise((res, rej) => {
      let img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement('canvas');
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL();
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }

  public getImageDimensions(file) {
    let context = this;
    return new Promise (function (resolved, rejected) {
      var i = new Image()
      i.onload = function(){
        context.height = i.height;
        context.width = i.width;
        resolved({w: i.width, h: i.height})
      };
      i.src = file
    })
  }
}