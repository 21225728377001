import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TouchpointHistoryItemComponent } from './touchpoint-history-item.component';

@NgModule({
  imports: [ 
    CommonModule, 
    FormsModule, 
    IonicModule, 
    TranslateModule, 
    NgxSkeletonLoaderModule,
  ],
  declarations: [TouchpointHistoryItemComponent],
  exports: [TouchpointHistoryItemComponent, TranslateModule]
})
export class TouchpointHistoryItemComponentModule {}
