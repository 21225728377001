import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() showLogo: boolean = false;
  @Input() title: string;

  public debugCounter = 0;

  constructor(
    public globalService: GlobalService
  ) { }

  ngOnInit() {}

  debugTap(){
    GlobalService.debugTap();
  }

  debugMode(){
    return GlobalService.DebugMode;
  }
}
