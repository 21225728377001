import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { actionpathItemComponent } from './actionpath-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [ 
    CommonModule, 
    FormsModule, 
    IonicModule, 
    TranslateModule, 
    NgxSkeletonLoaderModule,
  ],
  declarations: [actionpathItemComponent],
  exports: [actionpathItemComponent, TranslateModule]
})
export class actionpathItemComponentModule {}
