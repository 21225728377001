import { Component, OnInit } from "@angular/core";
import Contact from "src/app/models/contact";
import { ContactService } from "src/app/services/contact.service";
import { NavController, AlertController } from "@ionic/angular";
import Link from "src/app/models/link";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import Touchpoint from "src/app/models/touchpoint";
import Action from "src/app/models/action";
import Note from "src/app/models/note";
import { Platform } from '@ionic/angular';
import { ContactTransferService } from 'src/app/services/contact-transfer.service';
import actionpath from 'src/app/models/actionpath';
import { ContactAvatarCached } from 'src/app/models/contact-avatar-cache';
import { GlobalService } from 'src/app/services/global.service';
import { ActionService } from "src/app/services/action.service";
import { NoteService } from "src/app/services/note.service";
import { SubscriptionService} from "src/app/services/subscription.service";
import { AvatarLoaderService } from 'src/app/services/avatar-loader.service';
import { VoipService } from 'src/app/services/voip.service';
import { AdministrationService } from "src/app/services/administration.service";

@Component({
  selector: "app-contact-details",
  templateUrl: "./contact-details.page.html",
  styleUrls: ["./contact-details.page.scss"],
})
export class ContactDetailsPage implements OnInit {
  public ContactTabs: typeof ContactTabs = ContactTabs;
  public contact: Contact;
  public phoneExtended: boolean = true;
  public emailExtended: boolean = true;
  public addressesExtended: boolean = true;
  public websitesExtended: boolean = true;
  public actions: Action[];
  public actionpaths: actionpath[];
  public notes: Note[];
  public touchpoints: Action[];
  public touchpointSlice: Action[];
  public history: any[];
  public historyLoader = 0;
  public websiteList: any[];
  public urlList: any[];

  public isLoadingContactDetail: boolean = true;
  public isLoadingActions: boolean;
  public isLoadingActionpaths: boolean;
  public isLoadingNotes: boolean;
  public isLoadingTouchpoints: boolean;
  public isLoadingHistory: boolean;
  public isLoading = false;
  public enteredActionPage = false;
  public firstTPScroll: boolean = false;
  public tpSliceLimit: number = 10;

  public currentTab: ContactTabs = ContactTabs.Information;

  public avatar: ContactAvatarCached;
  public idUser: string = "";
  public openActions = 0;

  constructor(
    private navController: NavController,
    private contactService: ContactService,
    private actionService: ActionService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private router: Router,
    private platform: Platform,
    private contactTransferService: ContactTransferService,
    private subscriptionService: SubscriptionService,
    private avatarLoader: AvatarLoaderService,
    private globalService: GlobalService,
    private voipService: VoipService,
    private administrationService: AdministrationService,
  ) {}

  async ngOnInit() {
    this.contact = ContactService.Contact;
    await this.ionViewDidEnter();
  }

  ionViewDidEnter() {
    this.switchTabTo(ContactService.ContactDetailPageTab);
    this.contact = ContactService.Contact;
    this.idUser = this.contact.id_user;
    this.avatarLoader.clearAvatars();

    this.refresh();

    if(ContactService.JustImportedSingle == true){
      ContactService.JustImportedSingle = false;
      this.actionService.createFollowUpOnContactCreation(this.contact.id_contact);
    }
    this.refreshActions();
  }

  public switchTabTo(tab: ContactTabs) {
    GlobalService.log("switched tab to", tab);
    this.currentTab = tab;
    ContactService.ContactDetailPageTab = tab;
    this.triggerSkeletonScreenSwap;

    switch (this.currentTab) {
      case ContactTabs.Information:
        this.refresh();
        break;
      case ContactTabs.Actions:
        this.refreshActions();
        this.enteredActionPage = true;
        break;
      case ContactTabs.Notes:
        this.refreshNotes();
        break;
      case ContactTabs.Touchpoints:
        this.resetTPScrollDown();
        this.refreshTouchpoints();
        this.enteredActionPage = true;
        break;
      case ContactTabs.Actionpath:
        this.refreshActionPaths();
        break;
      case ContactTabs.History:
        this.refreshHistory();
        break;
    }
  }

  public triggerSkeletonScreenSwap(){
    GlobalService.enableSkeletonScreen();
  }

  public getStyleForTab(tab: ContactTabs) {
    return {
      color:
        this.currentTab == tab ? "var(--ion-color-primary, #3399cc)" : "black",
    };
  }

  public getImageForURL(url: Link): string {
    switch (url.url_type.toLowerCase()) {
      case "facebook":
        return "assets/img/facebook-logo.svg";
      case "instagram":
        return "assets/img/instagram-logo.svg";
      case "linkedin":
        return "assets/img/linkedin-logo.svg";
      case "twitter":
        return "assets/img/twitter-logo.svg";
    }

    return "assets/icon/website.svg";
  }

  public async deleteContact() {
    let buttons = [];
    buttons.push({
      cssClass: 'delete-yes first-yes-no',
      text: this.translateService.instant("YES"),
      handler: () => {
        this.isLoading= true;
        ContactService.HasDeletedContact = true;
        this.contactService.RemoveContactFromList(this.contact);
        this.contactService
          .deleteContact(this.contact.id_contact)
          .then(() => {
            this.goBack();
          });
      },
    });
    buttons.push({
      cssClass: 'delete-no second-yes-no',
      text: this.translateService.instant("CANCEL"),
      handler: () => {},
    });
    
    this.globalService.ionicAlert(
      buttons,
      "DELETECONTACT",
      "AREYOUSURE"
    );
  }

  public sureLeaveWithoutFollowUp(){
    let buttons = [];
    buttons.push({
      cssClass: 'delete-yes first-yes-no',
      text: this.translateService.instant("YES"),
      handler: () => {
        ContactService.ContactDetailPageTab = ContactTabs.Information;
        this.globalService.goBack()
      },
    });
    buttons.push({
      cssClass: 'delete-no second-yes-no',
      text: this.translateService.instant("CANCEL"),
      handler: () => {
        this.addaction();
      },
    });
    
    this.globalService.ionicAlert(
      buttons,
      "AREYOUSURE",
      "CONTACTNOACTIONS"
    );
  }

  public editContact() {
    ContactService.SetIsEditing(true);
    ContactService.SetCurrentContact(this.contact);

    this.navController.navigateRoot("add-contact");
  }

  public async exportContact(){
    this.isLoading = true;
    await this.contactTransferService.exportSingleContact(this.contact);
    this.isLoading = false;
  }

  public goBack() {
    if(this.enteredActionPage && this.hasNoActions()){
      this.sureLeaveWithoutFollowUp();
    } else {
      ContactService.ContactDetailPageTab = ContactTabs.Information;
      this.globalService.goBack()
    }
  }

  public hasNoActions(){
    if(this.actions && this.actions.length > 0 && this.openActions == 0) return true;
    return false;
  }

  public extendPhoneNumbers() {
    this.phoneExtended = !this.phoneExtended;
  }

  public extendEmails() {
    this.emailExtended = !this.emailExtended;
  }

  public extendAddresses() {
    this.addressesExtended = !this.addressesExtended;
  }

  public extendWebsites() {
    this.websitesExtended = !this.websitesExtended;
  }

  public async getAvatar () {
    this.avatar = await this.contactService.getContactAvatar(this.contact);
  }

  public async refresh() {
    GlobalService.enableSkeletonScreen();
    this.contact = await this.contactService.getContact(this.contact.id_contact);

    this.urlList = [];
    this.websiteList = [];
    if(this.contact && this.contact.url_list){
      for(let url of this.contact.url_list){
        if(url.url_type == "website") this.websiteList.push(url);
        else this.urlList.push(url);
      }
    }

    await this.getAvatar();
    this.isLoadingContactDetail = false;
  }

  public async refreshActions() {
    this.isLoadingActions = true;

    this.actions = await this.contactService.getContactActions(this.contact.id_contact);
    this.isLoadingActions = false;
    this.openActions = 0;
    for(let action of this.actions) if(action.status == 0) this.openActions++;
    return this.actions;
  }

  public async refreshTouchpoints() {
    this.actions = [];
    this.touchpoints = [];
    this.touchpointSlice = [];
    await this.refreshActions();
    await this.getTouchpoints();
  }

  public async getTouchpoints() {
    GlobalService.enableSkeletonScreen();
    if(!this.actions) await this.refreshActions();
    console.log("touchpoints", this.actions);
    this.touchpoints = [];
    for(let action of this.actions){
      if(!action.finish_date) action.finish_date = action.due;
      if(action.status != 0) this.touchpoints.push(action);
    }
    console.log("touchpoints", this.touchpoints);
    this.touchpointSlice = this.touchpoints;
    this.touchpointSlice = this.touchpointSlice.sort((b, a) => (new Date(a.finish_date).getTime() > new Date(b.finish_date).getTime()) ? 1 : -1);
    this.touchpointSlice = this.touchpointSlice.slice(0, this.tpSliceLimit);
    this.touchpointSlice = this.touchpointSlice.reverse();
  }

  public async refreshNotes() {
    this.isLoadingNotes = true;

    await this.refreshActions();
    this.historyLoader = 2;

    let res = await this.contactService.getContactNotes(this.contact.id_contact)

    let tempNotes = res;
    this.notes = res;

    this.actions.forEach(action => {
      if(action.notes){
        action.notes.forEach(note => {
          tempNotes.push(note);
        });
      }
    });

    this.notes = tempNotes.sort((b, a) => (new Date(a.created) > new Date(b.created)) ? 1 : -1);

    this.isLoadingNotes = false;
    return this.notes;
  }

  public async refreshActionPaths() {
    GlobalService.enableSkeletonScreen();
    this.isLoadingActionpaths = true;

    let res = await this.contactService.getContactActionpaths(this.contact.id_contact);
    this.actionpaths = res;

    this.isLoadingActionpaths = false;
    return this.actionpaths;
  }

  async refreshHistory(){
    this.isLoadingHistory = true;
    this.historyLoader = 1;
    await this.refreshNotes();
    this.historyLoader = 3;
    await this.refreshActionPaths();

    let historyContainer = [];

    this.actions.forEach(action => {
      // action["historyDate"] = action.creation;
      action["historyDate"] = action.due;
      action["historyType"] = "action";
      // if(action["historyDate"] == undefined) action["historyDate"] = action.due;
      historyContainer.push(action);
    });
    this.notes.forEach(note => {
      note["historyDate"] = note.created;
      note["historyType"] = "note";
      historyContainer.push(note);
    });
    this.actionpaths.forEach(actionpath => {
      actionpath["historyDate"] = actionpath.creation;
      actionpath["historyType"] = "actionpath";
      historyContainer.push(actionpath);
    });

    this.history = historyContainer.sort((b, a) => (new Date(a.historyDate) > new Date(b.historyDate)) ? 1 : -1);
    
    GlobalService.log("full history", this.history);
    this.isLoadingHistory = false;
  }
  
  public addnote(){
    NoteService.CurrentContact = this.contact;
    
    this.navController.navigateRoot("add-note",  {queryParams: {id_contact: this.contact.id_contact, changetype: true, allowTypeChange: false}});
  }

  public async addaction(){
    if(ActionService.Actions){
      if(await this.subscriptionService.checkSubscription(ActionService.Actions.length, "ACTIONS")){
        ActionService.ActionStatus = 0;
        ActionService.SelectedGoals = undefined;
        this.navController.navigateRoot("add-action", {queryParams: {id_contact: this.contact.id_contact}});
      }
    } else {
      ActionService.ActionStatus = 0;
      ActionService.SelectedGoals = undefined;
      this.navController.navigateRoot("add-action", {queryParams: {id_contact: this.contact.id_contact}});
    }
  }

  public async addtouchpoint(){
    if(ActionService.Actions){
      if(await this.subscriptionService.checkSubscription(ActionService.Actions.length, "ACTIONS")){
        ActionService.ActionStatus = 2;
        ActionService.SelectedGoals = undefined;
        this.navController.navigateRoot("add-action", {queryParams: {id_contact: this.contact.id_contact}});
      }
    } else {
      ActionService.ActionStatus = 2;
      ActionService.SelectedGoals = undefined;
      this.navController.navigateRoot("add-action", {queryParams: {id_contact: this.contact.id_contact}});
    }
  }

  public addactionpath(){
    this.navController.navigateRoot("add-actionpath", {queryParams: {id_contact: this.contact.id_contact}});
  }

	openURL(URL: string)
	{
    GlobalService.log(URL);
		if(this.platform.is("ios") == true){
			window.location.assign(URL);
		}
		else{
			window.open(URL, '_system');
		}
  }
  
  smsIT(phoneNumber: string, message: string){
    window.location.assign('sms:' + phoneNumber + "?body=" + message);
	}

	async whatsappIT(phoneNumber: string, message: string){
    GlobalService.log(phoneNumber);
    window.location.assign("http://api.whatsapp.com/send?phone=" + phoneNumber + "&text=" + message);
	}

  async callIT(phoneNumber: string){
    if(!AdministrationService.UserData) await this.administrationService.getFullUserData();
    if(AdministrationService.UserData.administration && AdministrationService.UserData.administration.has_voip_customer_key && AdministrationService.UserData.internal_number){
      this.askVOIPorPhone(phoneNumber);
    } else window.location.assign('tel:' + phoneNumber);
  }

  public async askVOIPorPhone(phoneNumber: string){    
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("DSTNY"),
          handler: async () =>{
            await this.voipService.callThroughVOIP(AdministrationService.UserData.internal_number, phoneNumber);
            this.globalService.returnToast(this.translateService.instant("CALLINGTHROUGHDSTNY"));
            this.afterCall(true);
      }
    });
    buttons.push({
      text: this.translateService.instant("PHONE"),
          handler: () =>{
            window.location.assign('tel:' + phoneNumber)
            this.afterCall();
      }
    });
    buttons.push({ cssClass: "cancelButton", 
      text: this.translateService.instant("CANCEL"),
      role: 'Cancel'
    });
    
    this.globalService.ionicAlert(
      buttons,
      "CALLTHROUGH"
    );
  }

  public async afterCall(VOIP: boolean = false){
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("YES"),
          handler: () =>{     
            ActionService.ActionStatus = 2;
            ActionService.SelectedGoals = undefined;
            this.navController.navigateRoot("add-action", {queryParams: {id_contact: this.contact.id_contact, type: "call"}});
      
      }
    });
    buttons.push({ cssClass: "cancelButton", 
      text: this.translateService.instant("NO"),
      role: 'Cancel'
    });
    
    this.globalService.ionicAlert(
      buttons,
      "QUESTIONCREATETOUCHPOINT"
    );
  }

  mailIT(emailAdress: string){
    window.location.href = "mailto:" + emailAdress;
  }

  openAddress(Address: any){
		if(this.platform.is("ios") == true)
		{
			window.location.assign("maps://?q=" + Address.street + "+" + Address.street_no +",+" + Address.zipcode + "+" + Address.place + "+" + Address.country);
		}
		else
		{
			window.open("http://www.google.com/maps/place/" + Address.street + "+" + Address.street_no +",+" + Address.zipcode + "+" + Address.place + "+" + Address.country, '_system');
		}
  }

  async SelectPhoneNumber(phoneNumber: string, message: string){
    let buttons = [];
    buttons.push({ cssClass: "optionButton",
      text: this.translateService.instant("CALL"),
          handler: () =>{
        this.callIT(phoneNumber);
      }
    });
    buttons.push({
      cssClass: "optionButton",
      text: this.translateService.instant("TEXTING"),
      handler: () =>
      {
        this.smsIT(phoneNumber, message);
      }
    });
    buttons.push({
      cssClass: "optionButton",
      text: this.translateService.instant("WHATSAPP"),
      handler: () =>
      {
        this.whatsappIT(phoneNumber, message);
      }
    });
    buttons.push({
      cssClass: "cancelButton",
      text: this.translateService.instant("CANCEL"),
      role: 'Cancel',
      handler: () =>
      {
        GlobalService.log('Cancelled');
      }
    });
    
    this.globalService.ionicAlert(
      buttons,
      "HOWCONTACTWITHPERSON"
    );
	}

  public returnContactService(){
    return ContactService;
  }

  public exportQR(){
    this.contactService.exportToQR(this.contact);
  }

  public isCordova(){
    if (this.platform.is('cordova')) { return true; }
  }

  public async deleteThisAction(action){
    this.isLoadingActions = true;
    this.actionService.removeActionFromList(action);
    this.actions = await (this.actions.filter(({ id_action }) => id_action !== action.id_action));
    this.isLoadingActions = false;
  }

  public async callRefreshFunction(){
    ContactService.RefreshContactActions = false;
    console.log("refreshed actions by force");
    this.switchTabTo(ContactTabs.Touchpoints);
  }

  public debugMode(){
    return GlobalService.DebugMode;
  }
  
  isDesktop(){
    return this.globalService.isDesktop();
  }
  
  isIos(){
    return this.globalService.isIos();
  }

  copyContactToClipboard(){
    this.copyToClipboard(JSON.stringify(this.contact));
  }

  copyToClipboard(value: string, toast?: string){
    if(toast == undefined) toast = this.translateService.instant("COPIEDTOCLIPBOARD");
    GlobalService.copyToClipboard(value);
    this.globalService.returnToast(toast);
  }

  public async scrollDown(){
    if(!this.firstTPScroll){
      await GlobalService.sleep(100)
      let element = document.getElementById("snaptobottom");
      if(element) {
        element.scrollIntoView();
        this.firstTPScroll = true;
      }
    }
  }

  public increaseTPLimit(){
    this.tpSliceLimit = this.tpSliceLimit + 10;
    this.getTouchpoints();
  }

  public async resetTPScrollDown(){
    this.firstTPScroll = false;
  }
}

export enum ContactTabs {
  Information,
  Actions,
  Notes,
  Touchpoints,
  Actionpath,
  History
}
