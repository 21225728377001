import { Component, OnInit } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { PhotoService } from 'src/app/services/photo.service';
import { GlobalService } from 'src/app/services/global.service';
import { IOSPhotoService } from 'src/app/services/ios-photo.service';
import { Photo } from '@capacitor/camera';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.page.html',
  styleUrls: ['./cropper.page.scss'],
})
export class CropperPage implements OnInit {

  public originalImage: Photo;
  public imageChangedEvent: any = '';
  public croppedImage: string = '';
  public base64image: string = '';
  
  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private globalService: GlobalService
    ) {}

  public dismiss(value: string) {
    let cameraPhoto: Photo = {...this.originalImage};
    cameraPhoto.base64String = value;

    this.modalController.dismiss({
      dismissed: true,
      value: cameraPhoto
    });
  }

  public goBack() {
    this.modalController.dismiss({
      dismissed: true
    });
  }

  public submit() {
    this.dismiss(this.croppedImage);
  }
  
  ngOnInit() {
    if(this.globalService.isIos()){
      this.originalImage = IOSPhotoService.CurPhoto;
    } else {
      this.originalImage = PhotoService.CurPhoto;
    }
  }

  ionViewWillEnter () {
    GlobalService.log(this.originalImage);

    if(this.originalImage != undefined && this.originalImage.base64String != undefined && this.originalImage.base64String == ""){
      this.goBack();
    }
  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  cropperReady(image: any) {
    // cropper ready
  }
  imageLoaded() {
    // show cropper
  }
  loadImageFailed() {
      // show message
  }
  
  isIos(){
    return this.globalService.isIos();
  }
}
