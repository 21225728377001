import { Inject, Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Platform, NavController } from '@ionic/angular';
import { GlobalService } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public firstTime: boolean = true;

  constructor(
    private storage: Storage,
    private navController: NavController,
    private platform: Platform,
  ){}

  private async initStorage() {
    await this.platform.ready() 
    await this.storage.create();
  }
  
  public async setStorage(key: string, value: any, log: boolean = true) {
    if(log) GlobalService.log("setStorage: " + key, value);
    await this.initStorage();
    return this.storage.set(key, value);
  }

  public async getStorage(key: string, log: boolean = true){
    if(log) GlobalService.log("getStorage: " + key);
    await this.initStorage();
    return this.storage.get(key);
  }

  public async clear(){
    GlobalService.log("clearStorage");
    await this.initStorage();
    return this.storage.clear();
  }

  public async remove(value){
    GlobalService.log("removeFromStorage", value);
    await this.initStorage();
    return this.storage.remove(value);
  }

  public async checkData(){
    if(this.firstTime){
      this.firstTime = false;
      
      let clearLocalDataCheck = await this.getStorage("clearLocalDataCheck");
      if(!clearLocalDataCheck || clearLocalDataCheck < 2){
        console.log("saved data needs cleaning, cleaning data...");
        await this.clearStorage();
        await this.setStorage("clearLocalDataCheck", 2);
        console.log("data cleaned");
        this.navController.navigateRoot("settings");
      }
    } else return;
  }

  public async clearStorage(){
    GlobalService.IsLoading = true;
    let raja = await this.getStorage("raja");
    let credentials = await this.getStorage("credentials");
    let remember = await this.getStorage("remember");
    let agreements = await this.getStorage("agreements");
    let settings = await this.getStorage("settings");
    await this.clear();
    await this.setStorage("raja", raja);
    await this.setStorage("credentials", credentials);
    await this.setStorage("remember", remember);
    await this.setStorage("agreements", agreements);
    await this.setStorage("settings", settings);
    GlobalService.IsLoading = false;
  }
}