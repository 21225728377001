import { Injectable } from "@angular/core";
import { Platform, ModalController, AlertController, ToastController } from "@ionic/angular";
import { HunterContact } from "src/app/models/contact";
import Address from "../models/address";
import { ContactService } from "./contact.service";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { Storage } from "@ionic/storage-angular";
import { AuthService } from "./auth.service";;
import { GlobalService } from "./global.service";
import { Contacts } from '@capacitor-community/contacts';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  public loadingImport: boolean = false;

  constructor(
    private storage: Storage,
    private platform: Platform,
    private modalController: ModalController,
    private contactService: ContactService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private sanitizer: DomSanitizer,
    private toastController: ToastController
  ) { }
  
  public async fillArrayWithNativeContacts(contactsArray: Array<HunterContact>) {
    if (!this.platform.is("mobile")) {
      console.log("Platform not supported!");
      console.log(this.platform.platforms());
    }

    GlobalService.log("Getting device contacts");

    let contacts: any[] = (await this.retrieveListOfContacts()).contacts;
    //contacts = contacts.filter((contact) => existingHunterContacts.findIndex(hunterContact) => hunterContact.name.givenName == contact.first_name && hunterContact.name.family == contact.last_name)
    if(contacts && contacts.length > 0) GlobalService.log("Succesfully retrieved contacts");

    console.log("initial contactarray", contacts);
    for(let contact of contacts) {
      let finalContact = await this.prepareContact(contact);
      if(finalContact) contactsArray.push(finalContact);
    }
    if(contacts && contacts.length > 0) GlobalService.log("Succesfully finished preparing contacts");
    console.log("final contactsarray", contactsArray);
    this.loadingImport = false;
    return contactsArray;
  }

  public async prepareContact(contact){
    if (contact.name?.given != undefined ||
      contact.name?.family != undefined ||
      contact.organization.company != undefined ||
      contact.name?.display != undefined ||
      contact.emails?.length > 0
    ) {
      let hunterContact: HunterContact = {};

      if(!contact.name.given) { contact.name.given = ""; }
      if(!contact.name.middle) { contact.name.middle = ""; }
      if(!contact.name.family) { contact.name.family = ""; }

      hunterContact.first_name = contact.name.given;
      hunterContact.last_name = "";

      if(contact.name.middle != ""){
        hunterContact.last_name += contact.name.middle;
      }

      if(contact.name.middle != "" && contact.name.family != ""){
        hunterContact.last_name += " ";
      }

      if(contact.name.family != ""){
        hunterContact.last_name += contact.name.family;
      }

      let globalLogName = "";
      if(hunterContact.first_name != "") globalLogName += hunterContact.first_name[0];
      if(hunterContact.first_name != "" && hunterContact.last_name != "") globalLogName += ". ";
      if(hunterContact.last_name != "") globalLogName += hunterContact.last_name[0];
      if(hunterContact.last_name != "" && hunterContact.last_name.length > 1) globalLogName += hunterContact.last_name[1];

      GlobalService.log("Start contact: ", globalLogName);

      hunterContact.company = "";
      hunterContact.function = "";
      hunterContact.avatar = "";
      hunterContact.favourite = "0";
      hunterContact.relationshipstatus = "MISCELLANEOUS";
      hunterContact.last_interaction = new Date();
      hunterContact.telephone_list = [];
      hunterContact.url_list = [];
      hunterContact.address_list = [];
      hunterContact.email_list = [];
      hunterContact.id_raw = contact.id;
      hunterContact.id_contact = Math.floor(Math.random() * 10000); // Set temporary id for selecting

      if (contact.image && contact.image.base64String) {
        hunterContact.avatar = this.fixURL(contact.image.base64String);
      } else {
        hunterContact.avatar = this.fixURL("assets/icon/contacts.svg");
      }

      if (contact.organization) {
        if(contact.organization.company) hunterContact.company = contact.organization.company;
        if(contact.organization.jobTitle) hunterContact.function = contact.organization.jobTitle;
      }

      if (contact.postalAddresses && contact.postalAddresses.length > 0) {
        for (let address of contact.postalAddresses) {
          hunterContact.address_list.push(this.createHunterAddress(address));
        }
      }

      if (contact.emails && contact.emails.length > 0) {
        for (let email of contact.emails) {
          GlobalService.log("Start converting email");
          let finalLabel = "WORK";
          if(email.label) finalLabel = email.label
          if(email.address != ""){
            hunterContact.email_list.push({
              email: email.address,
              email_type: finalLabel,
            });
          }
          GlobalService.log("Finished converting email");
        }
      }

      if (contact.phones && contact.phones.length > 0) {
        for (let phoneNumber of contact.phones) {
          GlobalService.log("Start converting phone numbers");
          let finalLabel = "GENERAL";
          if(phoneNumber.label) finalLabel = phoneNumber.label
          if(phoneNumber.number != ""){
            hunterContact.telephone_list.push({
              number: phoneNumber.number,
              number_type: finalLabel,
            });
          }
          GlobalService.log("Finished converting phone numbers");
        }
      }

      if (contact.urls && contact.urls.length > 0) {
        for (let url of contact.urls) {
          GlobalService.log("Start converting url's");
          if(url != ""){
            hunterContact.url_list.push({
              url: url,
              url_list: "LINK",
              url_type: this.checkLinkType(url),
            });
          }
          GlobalService.log("Finished converting url's");
        }
      }

      //Convert to Base64
      let base64Img = await this.compressImage(hunterContact.avatar.changingThisBreaksApplicationSecurity, 512, 512);
      hunterContact.avatar = base64Img;
      hunterContact.avatar = hunterContact.avatar.replace("data:image/jpeg;base64", "data:image/png;base64");

      //Remove all duplicates
      if(hunterContact.telephone_list.length > 1)
        hunterContact.telephone_list = [...new Map(hunterContact.telephone_list.map(v => [v.number, v])).values()];
      if(hunterContact.url_list.length > 1) 
        hunterContact.url_list = [...new Map(hunterContact.url_list.map(v => [v.url, v])).values()];
      if(hunterContact.address_list.length > 1) 
        hunterContact.address_list = [...new Map(hunterContact.address_list.map(v => [v.zipcode, v])).values()];
      if(hunterContact.email_list.length > 1) 
        hunterContact.email_list = [...new Map(hunterContact.email_list.map(v => [v.email, v])).values()];
      
      GlobalService.log("Finished contact: ", globalLogName);
      return hunterContact;
    } else return null;
  }

  fixURL(url: string) {
    const win: any = window;
    const fixedURL = win.Ionic.WebView.convertFileSrc(url);
    var returnedURL = this.sanitizer.bypassSecurityTrustResourceUrl(fixedURL);
    return returnedURL;
  }

  private async compressImage(src, newX, newY) {
    if (src.includes('contacts.svg')) {
      return new Promise((resolve, reject) => {
        resolve(src);
      });
    }

    return await new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        const elem = document.createElement("canvas");
        elem.width = newX;
        elem.height = newY;
        const ctx = elem.getContext("2d");
        ctx.drawImage(img, 0, 0, newX, newY);
        const data = ctx.canvas.toDataURL("image/jpeg");
        res(data);
      };
      img.onerror = (error) => rej(error);
    });
  }

  private createHunterAddress(contactAddress) {
    GlobalService.log("Start converting address");
    let address: Address = {};

    if(contactAddress.street != undefined){
      let tempAddressHolder = contactAddress.street.split(" ");

      for (let Parts of tempAddressHolder) {
        if (
          Parts != tempAddressHolder[0] &&
          Parts != tempAddressHolder[tempAddressHolder.length - 1]
        ) {
          tempAddressHolder[0] = tempAddressHolder[0] + " " + Parts;
        }
      }

      if (tempAddressHolder[0] == undefined) {
        tempAddressHolder[0] = "";
      }
  
      if (tempAddressHolder[1] == undefined) {
        tempAddressHolder[1] = "";
      }

      address.street = tempAddressHolder[0];
      address.street_no = tempAddressHolder[tempAddressHolder.length - 1];
    }
    address.zipcode = contactAddress.postcode;
    address.place = contactAddress.region;
    address.country = contactAddress.country;

    GlobalService.log("Finished converting address");
    return address;
  }

  checkLinkType(url: string) {
    if (url.toLowerCase().includes("facebook")) {
      return "Facebook";
    } else if (url.toLowerCase().includes("instagram")) {
      return "Instagram";
    } else if (url.toLowerCase().includes("linkedin")) {
      return "LinkedIn";
    } else if (url.toLowerCase().includes("twitter")) {
      return "Twitter";
    } else return "Website"
  }

  public async retrieveListOfContacts(){
    const projection = {
      name: true,
      organization: true,
      phones: true,
      emails: true,
      postalAddresses: true,
      urls: true,
      image: true
    };
  
    const result = await Contacts.getContacts({
      projection,
    });

    return result;
  };
}
