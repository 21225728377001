<!--Skeleton Loader-->
<div *ngIf="!action && !hideMe" class="title-avatar-wrap" (click)="openActionDetails()">
  <!--avatars-->
  <div class="action-ifo-card">
    <ion-avatar class="square-avatar">
      <ngx-skeleton-loader [theme]="{'height': '64px', 'width': '64px'}">
      </ngx-skeleton-loader>
    </ion-avatar>
  </div>

  <!-- title & contact/action type -->
  <div *ngIf="!hideMe" class="action-ifo-card action-card-text">
    <ion-row>
      <ngx-skeleton-loader [theme]="{'width': '30vw'}">
      </ngx-skeleton-loader>
    </ion-row>
    <ion-row>
      <ngx-skeleton-loader [theme]="{'width': '24vw'}">
      </ngx-skeleton-loader>
    </ion-row>
  </div>
</div>

<!-- Real Data -->
<div *ngIf="action && !hideMe">
  <div *ngIf="!this.detailpage">
    <div class="title-avatar-wrap" (click)="openActionDetails()">
      <!--avatars-->
      <div class="action-ifo-card">
        <!-- <div class="action-red-dot" *ngIf="action.has_notes"></div>
        <div class="action-green-dot" *ngIf="action.status == 2"></div>
        <div class="action-red-dot" *ngIf="action.status == 3"></div> -->
        <ion-avatar *ngIf="!action.contacts || action.contacts.length == 0" class="square-avatar">
          <span class="icon-small">
            <ion-icon class="note-contact-icon" [src]="'assets/icon/contacts.svg'"></ion-icon>
          </span>
        </ion-avatar>

        <!-- change placeholder with square icon -->
        <ion-avatar class="square-avatar" *ngIf="action.contacts && action.contacts.length > 0 && action.contacts[0]
        && (!avatar || avatar && (!avatar.avatar || avatar.avatar.includes('contacts.svg')))">
          <span class="icon-small">
            <ion-icon class="note-contact-icon" [src]="'assets/icon/groups.svg'"></ion-icon>
          </span>
        </ion-avatar>
        <ion-avatar class="square-avatar avatar-avatar" *ngIf="action.contacts && action.contacts.length > 0 && action.contacts[0]
        && (avatar && avatar.avatar && !avatar.avatar.includes('contacts.svg'))">
          <ion-img class="note-contact-icon avatar-avatar" [src]="avatar.avatar"></ion-img>
        </ion-avatar>
      </div>

      <!-- title & contact/action type -->
      <div class="action-ifo-card action-card-text">
        <ion-text class="action-title">{{action.title}}</ion-text>
        <div class="action-contact" *ngIf="action.contacts && action.contacts.length > 0">
          <ion-text color="medium">{{getDisplayName(action.contacts[0]).trim()}}
            <br><ion-text *ngIf="this.action.due != 'Invalid Date' && this.action.due != 'error' || debugMode()">{{getDateString()}}
            </ion-text>
            <ion-text *ngIf="debugMode()"><br>{{this.action.due}}</ion-text>
          </ion-text>
        </div>
        <div class="action-contact" *ngIf="!action.contacts || action.contacts.length == 0">
          <ion-text color="medium">{{'PERSONAL' | translate}}
            <br><ion-text *ngIf="this.action.due != 'Invalid Date' && this.action.due != 'error' || debugMode()">{{getDateString()}}
            </ion-text>
            <ion-text *ngIf="debugMode()"><br>{{this.action.due}}</ion-text>
          </ion-text>
        </div>
      </div>
    </div>
  </div>
</div>