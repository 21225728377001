import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";
import { NavController, AlertController } from "@ionic/angular";
import { CrudService } from "../services/crud.service";
import { TranslateService } from "@ngx-translate/core";
import { SettingsService } from 'src/app/services/settings.service';
import { GlobalService } from 'src/app/services/global.service';
import { SubscriptionService } from "src/app/services/subscription.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(
    public authService: AuthService,
    private translateService: TranslateService,
    private alertController: AlertController,
    private crudService: CrudService,
    private settingsService: SettingsService,
    private globalService: GlobalService,
    private navController: NavController
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      GlobalService.DebugCounter = 0;
      if(GlobalService.DebugMode) console.log("Authguard Started");
      if(CrudService.AuthToken){
        if(GlobalService.DebugMode) console.log("auth guard passed");
        this.CheckSubscriptionAPI();
        GlobalService.HideMenu = false;
        
        if(GlobalService.DebugMode) console.log("Authguard Ended");
        return true;
      }
      else {
        if(!GlobalService.LoginPage){
          console.log("no authtoken found");
          let shouldNeverShow = false;
          this.crudService.loginRememberMe().then(async (isAuthenticated) => {
            shouldNeverShow = true;
            console.log(`Token valid: ${isAuthenticated}`);
            await this.CheckSubscriptionAPI();
  
            if(GlobalService.DebugMode) console.log("Authguard Ended");
            return true;
          }).catch(async err => {
            shouldNeverShow = true;
            console.log("Authgurad catch triggered");
            await this.CheckSubscriptionAPI();
            if(GlobalService.DebugMode) console.log("Authguard Ended");
            return true;
          });
        }
      }
      if(GlobalService.DebugMode) console.log("Authguard Ended");
      return true;
    }

  public async CheckSubscriptionAPI(){
    return true;
    // if(!this.globalService.isDesktop()){
    //   this.settingsService.setSubscription(SubscriptionService.Subscribed);
    // }
  }
}
