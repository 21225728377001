import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SearchPageRoutingModule } from './search-routing.module';

import { SearchPage } from './search.page';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponentModule } from 'src/app/components/header/header.module';
import { ContactItemComponentModule } from 'src/app/components/contact-item/contact-item.module';
import { ContactGroupItemComponentModule } from 'src/app/components/contact-group-item/contact-group-item.module';
import { ActionItemComponentModule } from 'src/app/components/action-item/action-item.module';
import { actionpathItemComponentModule } from 'src/app/components/actionpath-item/actionpath-item.module';
import { NoteItemComponentModule } from 'src/app/components/note-item/note-item.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SearchPageRoutingModule,
    TranslateModule,
    ContactItemComponentModule,
    ContactGroupItemComponentModule,
    ActionItemComponentModule,
    actionpathItemComponentModule,
    NoteItemComponentModule,
    HeaderComponentModule,
  ],
  declarations: [SearchPage]
})
export class SearchPageModule {}
