import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { Platform } from '@ionic/angular';
import { Settings } from "../models/settings";
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionService } from "./subscription.service";
import { ContactTransferService } from "./contact-transfer.service";
import { TermsandAgreementsComponent } from "../components/terms-and-agreements/terms-and-agreements.component";
import { ModalController } from '@ionic/angular';
import { AuthService } from './auth.service';
import { GlobalService } from "./global.service";
import { NavController, AlertController } from '@ionic/angular';
import { CrudService } from './crud.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { NoteService } from "./note.service";
import { ContactService } from "./contact.service";
import { ActionService } from "./action.service";
import { AdministrationService } from "./administration.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class SettingsService extends CrudService {
  private static settings: Settings;
  private static isDirty: boolean; // true if there are any unsaved settings
  private static isSubmitted: boolean = false;
  // private static devMode: boolean = false;
  private static agreements: {general: boolean, cookies: boolean} = {general: false, cookies: false};

  public static get Settings() {
    return SettingsService.settings;
  }

  // public static get devmode() {
  //   return SettingsService.devMode;
  // }

  public static get Agreements() {
    return SettingsService.agreements;
  }

  public async SetAgreements(type) {
    if(type == "general"){
      SettingsService.agreements.general = true;
    }

    if(type == "cookies"){
      SettingsService.agreements.cookies = true;
    }

    this.storageService.setStorage("agreements", SettingsService.agreements).then((x) => {});
  }

  public static getSubmitted() {
    return SettingsService.isSubmitted;
  }

  public static setSubmitted(status){
    SettingsService.isSubmitted = status;
  }

  constructor(
    public httpClient: HttpClient,
    private platform: Platform,
    private modalController: ModalController,
    private subscriptionService: SubscriptionService,
    public navController: NavController,
    public translateService: TranslateService,
    public alertController: AlertController,
    public globalService: GlobalService,
    public authService: AuthService,
    public contactTransferService: ContactTransferService,
    public storageService: StorageService
  ) {
    super(httpClient, navController, translateService, alertController, globalService, storageService, authService);
  }

  public async initializeSettings() {
    let settings = await this.storageService.getStorage("settings");
    console.log("Settings: ", settings);

    if (settings == null || settings == undefined) {
      SettingsService.settings = this.getDefaultSettings();

      this.saveSettings();
    } else {
      SettingsService.settings = settings;
    }

    console.log("Use language: ", SettingsService.settings.language);
    this.translateService.use(SettingsService.settings.language);

    console.log("Settings initialized!");

    if(settings){
      if(settings.subscribed){
        this.subscriptionService.prepareSubscriptions();
      }
    }
    
    let agreements = await this.storageService.getStorage("agreements");
    if(agreements != null){
      SettingsService.agreements = agreements;

      if(!SettingsService.agreements.general){
        this.checkAgreements();
      }
    } else {
      this.checkAgreements();
    }
  }

  public flagDirty() {
    SettingsService.isDirty = true;
  }

  public async saveSettings() {
    this.storageService.setStorage("settings", SettingsService.settings).then((x) => {
      SettingsService.isDirty = false;
    });
  }

  public getDefaultSettings(): Settings {
    return {
      language: this.translateService.currentLang,
      rememberPassword: false,
      subscribed: false
    };
  }

  public setLanguage(lang: string) {
    SettingsService.Settings.language = lang;

    this.flagDirty();

    this.translateService.use(lang);

    this.saveSettings();
  }

  public setRememberPassword(remember: boolean) {
    SettingsService.Settings.rememberPassword = remember;

    this.flagDirty();

    this.saveSettings();
  }

  public async checkAgreements(){
    const modal = await this.modalController.create({
      component: TermsandAgreementsComponent
    });

    return await modal.present();
  }

  public async setSubscription(subscribed: boolean){
    console.log("setting subscription");
    await this.put("/user/setSubscription", subscribed)
  }

  public static unsetEverything(){
    NoteService.unsetNote();
    ContactService.Contact = undefined;
    ActionService.Action = undefined;
    ActionService.ActionPageTab = undefined;
  }

  public async removeLocalData(){
    let buttons = [];
    buttons.push({
      text: this.translateService.instant("CONTINUE"),
      handler: async () => { 
        this.removeLocalDataConfirmed(true);
      },
    });
    buttons.push({
      cssClass: "deleteButton",
      text: this.translateService.instant("CANCEL"),
    });

    this.globalService.ionicAlert(
      buttons,
      "REMOVELOCALDATA",
      "LOCALDATAREMOVE"
    )
    return;
  }

  async removeLocalDataConfirmed(clearLocalStorage: boolean = false){
    GlobalService.IsLoading = true;
    ContactService.stopSync();
    ContactService.resetLocalLists();
    ActionService.resetLocalLists();
    NoteService.resetLocalLists();
    this.contactTransferService.stopQueue();
    SettingsService.unsetEverything();
    SubscriptionService.SubscriptionData = undefined;
    await this.authService.emptyUserContacts();
    let raja = await this.storageService.getStorage("raja");
    let credentials = await this.storageService.getStorage("credentials");
    let remember = await this.storageService.getStorage("remember");
    let agreements = await this.storageService.getStorage("agreements");
    let settings = await this.storageService.getStorage("settings");
    if(clearLocalStorage) await this.storageService.clear();
    else {
      this.storageService.remove("user_obj");
      this.storageService.remove("localContacts_" + (await this.authService.getUserObj()).id_user);
    }
    await this.storageService.setStorage("raja", raja);
    await this.storageService.setStorage("credentials", credentials);
    await this.storageService.setStorage("remember", remember);
    await this.storageService.setStorage("agreements", agreements);
    await this.storageService.setStorage("settings", settings);
    this.globalService.returnToast("REMOVEDLOCALDATA", undefined, "greenToast");
    
    GlobalService.IsLoading = false;

    // this.authService.logout().then(() => {
    //   this.navController.navigateRoot('login');
    // });
    this.navController.navigateRoot('tabs/dashboard');
  }
}
