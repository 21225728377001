import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SelectContactsPageRoutingModule } from './select-contacts-routing.module';

import { SelectContactsPage } from './select-contacts.page';
import { TranslateModule } from '@ngx-translate/core';
import { SearchbarComponentModule } from 'src/app/components/searchbar/searchbar.module';
import { HeaderComponentModule } from 'src/app/components/header/header.module';
import { ContactItemComponentModule } from 'src/app/components/contact-item/contact-item.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    IonicModule,
    SearchbarComponentModule,
    HeaderComponentModule,
    ContactItemComponentModule,
    SelectContactsPageRoutingModule
  ],
  declarations: [SelectContactsPage]
})
export class SelectContactsPageModule {}
