<ion-header [ngClass]="{'fixDesktopDetailHeader': isDesktop()}">
  <ion-toolbar>
    <ion-buttons mode="md" slot="start">
      <ion-button mode="md" (click)="goBack()">
        <ion-icon class="new-style-icon" [src]="'assets/icon/chevron-left.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>

    <app-header [showLogo]="true"></app-header>
  </ion-toolbar>

  <div class="searchbar-holder" [ngClass]="{'hiddenContent': isLoading == true}">
    <div class="searchbar">
      <ion-searchbar [id]="'searchInput'" #searchInput search-icon="assets/icon/search.svg" mode="md" clear-icon="undefined" class="real-searchbar"
        [placeholder]="'SEARCH' | translate" (ionChange)="onSearch($event)" [debounce]="1000" [value]="getGlobalSearch()">
      </ion-searchbar>
    </div>
  </div>
</ion-header>

<ion-content>
  <div [ngClass]="{'ios-searchpage-top-margin': isIos()}"  class="searchpage-top-margin"></div>

  <div *ngIf="!isLoading && (!searchString || searchString && searchString.length === 0)" style="text-align: center;">
    {{ "TYPESEARCHINBAR" | translate }}
  </div>
  <div *ngIf="this.isLoading" style="text-align: center;">
    <ng-container  *ngIf="this.type == 'dashboard'">{{ "DATARETRIEVAL" | translate }}: {{ dataProgress }}%</ng-container >
    <ng-container  *ngIf="this.type != 'dashboard'">{{ "DATARETRIEVAL" | translate }}...</ng-container >
  </div>
  <div *ngIf="searchString && searchString.length > 0">  
    <div *ngIf=" this.type == 'contact' || this.type == 'dashboard'">
    <!-- Contacts Skeleton-->
    <div *ngIf="!filteredContacts || this.isLoading == true">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"CONTACTS" | translate}}</h1>
        </ion-text>
      </div>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
    </div>

    <!-- Contacts -->
    <div *ngIf="filteredContacts && filteredContacts.length > 0 && this.isLoading == false">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"CONTACTS" | translate}}</h1>
        </ion-text>
      </div>

      <ion-card class="item-container">
        <app-contact-item *ngFor="let contact of showContacts" [contact]="contact"></app-contact-item>
        <div *ngIf="this.currentSlice[0] < filteredContacts.length">
          <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Contacts, true)">
            <div class="center-text full-width show-more-less">{{ "SHOWMORE" | translate }}</div>
          </ion-item>
        </div>
        <div *ngIf="this.currentSlice[0] > 11">
          <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Contacts, false)">
            <div class="center-text full-width show-more-less">{{ "SHOWLESS" | translate }}</div>
          </ion-item>
        </div>
      </ion-card>
    </div>
  </div>

  <div *ngIf=" this.type == 'group' || this.type == 'dashboard'">
    <!-- Contacts Skeleton-->
    <div *ngIf="!filteredGroups || this.isLoading == true">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"GROUPS" | translate}}</h1>
        </ion-text>
      </div>
      <app-contact-group-item></app-contact-group-item>
      <app-contact-group-item></app-contact-group-item>
      <app-contact-group-item></app-contact-group-item>
    </div>
    <!-- Contactgroups -->
    <div *ngIf="filteredGroups && filteredGroups.length > 0 && this.isLoading == false">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"GROUPS" | translate}}</h1>
        </ion-text>
      </div>

      <ion-card class="item-container">
        <app-contact-group-item *ngFor="let group of showGroups" [group]="group"></app-contact-group-item>
        <div *ngIf="this.currentSlice[5] < filteredGroups.length">
          <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Groups, true)">
            <div class="center-text full-width show-more-less">{{ "SHOWMORE" | translate }}</div>
          </ion-item>
        </div>
        <div *ngIf="this.currentSlice[5] > 11">
          <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Groups, false)">
            <div class="center-text full-width show-more-less">{{ "SHOWLESS" | translate }}</div>
          </ion-item>
        </div>
      </ion-card>
    </div>
  </div>
  
  <div *ngIf=" this.type == 'action' || this.type == 'dashboard'">
    <!-- Actions Skeleton-->
    <div *ngIf="!filteredActions || this.isLoading == true">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"ACTIONS" | translate}}</h1>
        </ion-text>
      </div>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
    </div>

    <!-- Actions -->
    <div *ngIf="filteredActions && filteredActions.length > 0 && this.isLoading == false">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"ACTIONS" | translate}}</h1>
        </ion-text>
      </div>

        <div *ngFor="let action of showActions">
          <app-action-item [searching]="true" [action]="action"></app-action-item>
        </div>
        
        <ion-card class="item-container">
          <div *ngIf="this.currentSlice[1] < filteredActions.length">
            <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Actions, true)">
              <div class="center-text full-width show-more-less">{{ "SHOWMORE" | translate }}</div>
            </ion-item>
          </div>
          <div *ngIf="this.currentSlice[1] > 11">
            <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Actions, false)">
              <div class="center-text full-width show-more-less">{{ "SHOWLESS" | translate }}</div>
            </ion-item>
          </div>
        </ion-card>
    </div>
  </div>

  <div div *ngIf=" this.type == 'note' || this.type == 'dashboard'">
    <!-- Notes Skeleton-->
    <div *ngIf="!filteredNotes || this.isLoading == true">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"NOTES" | translate}}</h1>
        </ion-text>
      </div>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
      <app-contact-item></app-contact-item>
    </div>


    <!-- Notes -->
    <div *ngIf="filteredNotes && filteredNotes.length > 0 && this.isLoading == false">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"NOTES" | translate}}</h1>
        </ion-text>
      </div>

      <ion-card class="item-container" *ngFor="let note of showNotes">
        <app-note-item  [note]="note"></app-note-item>
      </ion-card>
      
      <ion-card class="item-container">
        <div *ngIf="this.currentSlice[4] < filteredNotes.length">
          <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Notes, true)">
            <div class="center-text full-width show-more-less">{{ "SHOWMORE" | translate }}</div>
          </ion-item>
        </div>
        <div *ngIf="this.currentSlice[4] > 11">
          <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Notes, false)">
            <div class="center-text full-width show-more-less">{{ "SHOWLESS" | translate }}</div>
          </ion-item>
        </div>
      </ion-card>
    </div>
  </div>

  <div *ngIf=" this.type == 'touchpoint' || this.type == 'dashboard'">
    <!-- Touchpoints -->
    <div *ngIf="filteredTouchpoints && filteredTouchpoints.length > 0 && this.isLoading == false">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"TOUCHPOINTS" | translate}}</h1>
        </ion-text>
      </div>

      <div *ngFor="let touchpoint of showTouchpoints">
        <app-action-item [searching]="true" [action]="touchpoint"></app-action-item>
      </div>
      
      <ion-card class="item-container">
        <div *ngIf="this.currentSlice[3] < filteredTouchpoints.length">
          <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Touchpoints, true)">
            <div class="center-text full-width show-more-less">{{ "SHOWMORE" | translate }}</div>
          </ion-item>
        </div>
        <div *ngIf="this.currentSlice[3] > 11">
          <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Touchpoints, false)">
            <div class="center-text full-width show-more-less">{{ "SHOWLESS" | translate }}</div>
          </ion-item>
        </div>
      </ion-card>
    </div>
  </div>

  <div *ngIf="this.type == 'actionpath' || this.type == 'dashboard'">
    <!-- Actionpaths Skeleton -->
    <div *ngIf="!filteredActionpaths || this.isLoading == true">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"GOALS" | translate}}</h1>
        </ion-text>
      </div>
      <app-actionpath-item></app-actionpath-item>
      <app-actionpath-item></app-actionpath-item>
      <app-actionpath-item></app-actionpath-item>
    </div>

    <!-- Actionpaths -->
    <div *ngIf="filteredActionpaths && filteredActionpaths.length > 0">
      <div class="search-category">
        <ion-text class="search-category-text">
          <h1>{{"GOALS" | translate}}</h1>
        </ion-text>
      </div>
        <app-actionpath-item class="search-AP" *ngFor="let actionpath of showActionpaths" [actionpath]="actionpath"></app-actionpath-item>
        
        <ion-card class="item-container">
          <div *ngIf="this.currentSlice[0] < filteredActionpaths.length">
            <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Actionpaths, true)">
              <div class="center-text full-width show-more-less">{{ "SHOWMORE" | translate }}</div>
            </ion-item>
          </div>
          <div *ngIf="this.currentSlice[0] > 11">
            <ion-item class="show" (click)="getShownItems(ShowMoreDataType.Actionpaths, false)">
              <div class="center-text full-width show-more-less">{{ "SHOWLESS" | translate }}</div>
            </ion-item>
          </div>
        </ion-card>
    </div>
  </div>
</div>
</ion-content>