<!-- Skeleton View -->
<ion-item *ngIf="!group && getSkeletonEnabled()" class="item" [lines]="lines ? 'full' : 'none'">
  <ion-grid class="ion-no-padding conctact-group-grid" style="padding-top: 7px; padding-bottom: 7px;">
    <ion-row style="margin-top: 0px; margin-left: 5px;">
      <ion-col class="contact-group-avatar" size="2">
        <ion-avatar style="height: 50px;">
          <ngx-skeleton-loader class="actionpath-circle-override" appearance="circle">
          </ngx-skeleton-loader>
        </ion-avatar>
      </ion-col>
      <ion-col style="position: absolute; padding-left: 90px;">
        <ion-row style="height: 15px; margin-top: 4px; margin-bottom: 8px;">
          <ion-text>
            <ngx-skeleton-loader [theme]="{'width': '40vw'}">
            </ngx-skeleton-loader>
          </ion-text>
        </ion-row>
        <ion-row style="height: 15px;">
          <ion-text>
            <ngx-skeleton-loader [theme]="{'width': '60vw'}">
            </ngx-skeleton-loader>
          </ion-text>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>

<!-- Real Data -->
<ion-item *ngIf="group" class="item" [lines]="lines ? 'full' : 'none'" [button]="this.clickable"
  (click)="openGroupDetails()">
  <ion-grid class="ion-no-padding" style="padding-top: 7px; padding-bottom: 7px;">
    <ion-row>
      <ion-col size="2">
        <ion-avatar class="item-avatar">
          <ion-img src="assets/img/defaultGroupAvatar.png"></ion-img>
        </ion-avatar>
      </ion-col>
      <ion-col>
        <ion-text *ngIf="group" class="ion-text-capitalize vertical-center">
          {{group.name}}
        </ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-item>