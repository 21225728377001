import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Platform, NavController } from '@ionic/angular';
// import { InAppPurchase2, IAPProduct } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';
import { GlobalService } from 'src/app/services/global.service';
import { SettingsService } from './settings.service';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { Storage } from "@ionic/storage-angular";
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService extends CrudService{
  private static maxValue: number = 50;
  private static productId: string = "70429fb712334806b1fb235d5741a82d";
  private static subscriptionData;
  private static loadingSubData = false;
  private static prepared = false;
  private isPreparing: boolean = false;

  public static fakeSub = {	
    "id":"70429fb712334806b1fb235d5741a82d",
    "alias":"70429fb712334806b1fb235d5741a82d",
    "type":"Subscription",
    "group":"20869882",
    "state":"pending",
    "title":"Subscription to HunterCRM",
    "description":"Subscription of HunterCRM, removing all restrictions from the application.",
    "priceMicros":4990000,
    "price":"€4,99",
    "currency":"EUR",
    "countryCode":"NL",
    "loaded":true,
    "canPurchase":false,
    "owned":false,
    "introPrice":null,
    "introPriceMicros":null,
    "introPricePeriod":null,
    "introPriceNumberOfPeriods":null,
    "introPricePeriodUnit":null,
    "introPriceSubscriptionPeriod":null,
    "introPricePaymentMode":null,
    "ineligibleForIntroPrice":null,
    "discounts":[],
    "downloading":false,
    "downloaded":false,
    "additionalData":null,
    "transaction":null,
    "valid":true
    };

  constructor(
    public httpClient: HttpClient,
    public translateService: TranslateService,
    public alertController: AlertController,
    public navController: NavController,
    private platform: Platform, 
    // private store: InAppPurchase2,
    public globalService: GlobalService,
    public settingsService: SettingsService,
    public storageService: StorageService,
    public authService: AuthService
    ) {
      super(httpClient, navController, translateService, alertController, globalService, storageService, authService);
    }

  public static get ProductId () {
    return this.productId;
  }

  public static get Subscribed () {
    return true;
    // if(SubscriptionService.SubscriptionData != undefined){
    //   return SubscriptionService.SubscriptionData.owned;
    // } else {
    //   return false;
    // }
  }

  public static get SubscriptionData () {
    return this.subscriptionData;
  }

  public static set SubscriptionData (subscriptionData) {
    this.subscriptionData = subscriptionData;
  }
  
  public static get Prepared () {
    return this.prepared;
  }
  
  public static get MaxValue () {
    return this.maxValue;
  }

  public static set MaxValue (maximum) {
    this.maxValue = maximum;
  }
  
  public static get LoadingSubData () {
    return this.loadingSubData;
  }

  public static set LoadingSubData (value) {
    this.loadingSubData = value;
  }

  public async prepareSubscriptions(){
    SubscriptionService.prepared = true;
    this.isPreparing = false;
    return;
    // if (this.platform.is('cordova')) {
    //   if(this.isPreparing == false){
    //     this.isPreparing = true;
    //     if(this.platform.is("ios")){
    //       SubscriptionService.productId = "c532b967b17749f78d27c1c0c4958b0a";
    //     } else {
    //       SubscriptionService.productId = "0ff6be3e5aa24e38a8de14a3dae10cc6";
    //     }
  
    //     this.store.verbosity = this.store.DEBUG;
    //     this.store.register({
    //       id: SubscriptionService.productId,
    //       type: this.store.PAID_SUBSCRIPTION,
    //     });
  
    //     // Register event handlers for the specific product
    //     this.store.when(SubscriptionService.productId).registered( (product: IAPProduct) => {
    //       if(GlobalService.DebugMode) console.log('Registered: ' + JSON.stringify(product));
    //     });
  
    //     // User closed the native purchase dialog
    //     this.store.when(SubscriptionService.productId).cancelled( (product) => {
    //         console.error('Purchase was Cancelled');
    //     });
  
    //     this.store.when(SubscriptionService.productId).updated((product: IAPProduct) => {
    //       if(GlobalService.DebugMode) console.log('Updated' + JSON.stringify(product));
    //     });
  
  
    //     this.store.when(SubscriptionService.productId).approved((product: IAPProduct) => {
    //       product.verify();
    //     });
  
    //     this.store.when(SubscriptionService.productId).verified((product: IAPProduct) => {
    //       product.finish();
    //     });
  
    //     this.store.when(SubscriptionService.productId).finished((product: IAPProduct) => {
    //       console.log("finished, setting product to", product);
    //       SubscriptionService.subscriptionData = product;
    //       if(GlobalService.DebugMode) {
    //         this.globalService.genericError(undefined, product);
    //         this.globalService.genericError("Obtained subscription data");
    //       }
    //     });
    //     // Track all store errors
    //     this.store.error( (err) => {
    //       console.error('Store Error ' + JSON.stringify(err));
    //     });
  
    //     // Run some code only when the store is ready to be used
    //     this.store.ready(() =>  {
    //       console.log('Store is ready');
    //       console.log('Products: ' + JSON.stringify(this.store.products));
    //       let products: any[];
    //       products = this.store.products;
    //       products.forEach(product => {
    //         if(product.price != null){
    //           console.log(product);
    //           SubscriptionService.subscriptionData = product;
    //         }
    //       });
    //       if(GlobalService.DebugMode) {
    //         this.globalService.genericError("Store ready for usage");
    //       }
    //     });
  
    //     // Refresh the status of in-app products
    //     SubscriptionService.prepared = true;
    //     this.store.refresh();
    //     this.isPreparing = false;
    //     return;
    //   } else {
    //     return;
    //   }
    // } else {
    //   if(GlobalService.DebugMode) console.log("using a computer, can't connect to app/playstore");
    //   if(SettingsService.devmode) SubscriptionService.SubscriptionData = SubscriptionService.fakeSub;
    //   SubscriptionService.prepared = true;
    //   this.isPreparing = false;
    //   return;
    // }
  }

  public async orderProduct(){
    SubscriptionService.loadingSubData = true;
    GlobalService.startCountingSubs();
    if(!SubscriptionService.prepared){
      await this.prepareSubscriptions();
    }

    // if (this.platform.is('cordova')) {
    //   await this.store.order(SubscriptionService.productId);
    //   SubscriptionService.loadingSubData = false;
    // } else {
    //   if(GlobalService.DebugMode) console.log("using a computer, can't connect to app/playstore");
    //   SubscriptionService.setupFakeSub();
    //   SubscriptionService.loadingSubData = false;
    //   console.log(SubscriptionService.SubscriptionData);
    // }
  }

  public static setupFakeSub(){
    // if(SettingsService.devmode) SubscriptionService.fakeSub.owned = true;
    // if(SettingsService.devmode) SubscriptionService.SubscriptionData = SubscriptionService.fakeSub;
    // if(SettingsService.devmode) console.log("Setting fakesub", SubscriptionService.fakeSub);
  }

  public async checkSubDesktop(){
    let userInfo = await this.obtainUser();
    
    if(GlobalService.DebugMode) console.log("user info", userInfo);
    if(userInfo.subscribed){
      //Set subscribed
      SubscriptionService.fakeSub.owned = true;
      SubscriptionService.SubscriptionData = SubscriptionService.fakeSub;
      if(GlobalService.DebugMode) console.log("HAS SUB");
      return true;
    } else {
      //Set unsubscribed
      SubscriptionService.fakeSub.owned = false;
      SubscriptionService.SubscriptionData = SubscriptionService.fakeSub;
      if(GlobalService.DebugMode) console.log("No SUB");
      return false;
    }
  }

  public async checkSubscription(number, type, warning: boolean = true){
    return true
    // try{
    //   let Maximum = SubscriptionService.maxValue;
    //   if(type == "CONTACTS"){
    //     Maximum = 15;
    //   }
  
    //   console.log(number + " out of " + Maximum);
  
    //   console.log("is subbed?", SubscriptionService.Subscribed);
      
    //   if(this.globalService.isDesktop()){
    //     let desktopSub = await this.checkSubDesktop();
    //     console.log("DESKTOPSUB", desktopSub);
    //     if(desktopSub || SettingsService.devmode) return true;
    //   }
  
    //   if(SubscriptionService.Subscribed == true){
    //     return true;
    //   }
  
    //   if(GlobalService.DebugMode){
    //     this.globalService.genericError(undefined, SubscriptionService.SubscriptionData);
    //     this.globalService.genericError("Account owned variable is false");
    //   }
      
    //   if(number < Maximum){
    //     return true;
    //   }
  
    //   if(warning){
    //     this.SubscribeDialogue(type, Maximum);
    //   }
      
    //   return false;
    // } catch(error){
    //   return true;
    // }
  }
  
  public async SubscribeDialogue (type, maximum?) {
    console.log("is subbed?", SubscriptionService.SubscriptionData);

    if(!maximum){ maximum = SubscriptionService.maxValue }
    let buttons = [];
    if(this.globalService.isDesktop()){
      buttons.push({
        text: this.translateService.instant("OKAY"),
        cssClass: "cancelButton"
      });
    } else {
      buttons.push({
        text: this.translateService.instant("Subscribe"),
        cssClass: "optionButton",
        handler: () => {
          this.orderProduct();
        }
      });
      buttons.push({
        text: this.translateService.instant("CANCEL"),
        cssClass: "cancelButton"
      });
    }

    let message = this.translateService.instant("YOUMAYNOTCREATEMORETHAN") + " " + maximum + " " +
      this.translateService.instant(type) + this.translateService.instant(" in the free version of this app");

    this.globalService.ionicAlert(
      buttons,
      message
    );
  }

  public testSubLoad(){
    SubscriptionService.loadingSubData = true;
    GlobalService.startCountingSubs();
  }

  public async obtainUser(){
    if(AuthService.User.id_user){
      let userInfo = await this.read("/user/byid/" + AuthService.User.id_user);
      return userInfo;
    } else {
      if(GlobalService.DebugMode) {
        this.globalService.genericError("No user detected");
      }
      return false;
    }
  }
}
