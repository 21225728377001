import { Component, OnInit } from '@angular/core';
import Action from 'src/app/models/action';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { ActionpathService } from 'src/app/services/actionpath.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service'
import Actionpath from "src/app/models/actionpath";

@Component({
  selector: 'app-select-goals',
  templateUrl: './select-goals.page.html',
  styleUrls: ['./select-goals.page.scss'],
})
export class SelectGoalsPage implements OnInit  {
  public goals: Actionpath[] = [];
  public selectedgoals: Actionpath[];
  public isLoadinggoals: Boolean;
  public fetchNewgoals: boolean = true;
  public visiblegoals;
  public maximum: number = undefined;

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private actionpathService: ActionpathService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private globalService: GlobalService
  ) {}

  public async ngOnInit () {
    GlobalService.log("Selected goals:");
    GlobalService.log(this.selectedgoals);
    if(this.selectedgoals == undefined){
      this.selectedgoals = [];
    }
    await this.ionViewDidEnterManual();
  }

  public ionViewDidEnterManual () {
    if (this.fetchNewgoals) {
      this.getgoals();
    } else { 
      this.fetchNewgoals = true; // Ensure fetchNewContacts is on for future contact selects
    }
  }

  public searchgoals(eventData){
    if(eventData.detail.value){
      let searchValue = eventData.detail.value
      GlobalService.log(searchValue);
      GlobalService.log(ActionpathService.Searchedgoals);
      ActionpathService.Searchedgoals = ActionpathService.Searchedgoals;
  
      let newgoals = [];
      ActionpathService.Searchedgoals.forEach(action => {
        if(action.title && action.title.toLowerCase().includes(searchValue.toLowerCase())){
          newgoals.push(action);
        }
      });
      this.goals = [];
      newgoals.forEach(action => {
        if(!action.status || action.status == 0 || action.status == 1){
          this.goals.push(action);
        }
      });
      GlobalService.log(this.goals);
    } else {
      ActionpathService.Searchedgoals.forEach(goal => {
        this.goals.push(goal);
      });
    }
  }

  public searchedgoals(){
    return ActionpathService.Searchedgoals;
  }

  public async getgoals () {
    this.isLoadinggoals = true;
    this.goals = [];
    
    let goals = await this.actionpathService.getAllactionpaths();
    goals.forEach(goal => {
      this.goals.push(goal);
    });

    this.isLoadinggoals = false;
  }

  public dismiss(dismissed) {
    GlobalService.log(this.selectedgoals);
    GlobalService.log(dismissed);
    
    this.modalController.dismiss({
      dismissed: dismissed,
      goals: this.selectedgoals
    });
  }

  public goBack() {
    this.dismiss(false);
  }

  public submit() {
    this.dismiss(true);
  }

  private comparegoals (goal: Actionpath) {
    return x => (goal.id_action_path != undefined && x.id_action_path == goal.id_action_path);
  }

  public actionIsSelected (action: Actionpath): boolean {
    let isSelected = this.selectedgoals.find(this.comparegoals(action)) != undefined;
    return isSelected;
  }

  public selectGoal (goal: Actionpath) {
    if(this.selectedgoals.find(this.comparegoals(goal))) {
      this.selectedgoals.splice(this.selectedgoals.findIndex(this.comparegoals(goal)),1);
    } else {
      if(this.maximum == 1){
        this.selectedgoals = [];
      }
      this.selectedgoals.push(goal);
    }
    GlobalService.log(this.selectedgoals);
  }

  public selectAll () {
    if (this.selectedgoals.length == this.goals.length) {
      this.selectedgoals = [];
    } else {
      this.selectedgoals = [...this.goals]
    }
  }
  
  isIos(){
    return this.globalService.isIos();
  }
}
