import { Component, OnInit } from '@angular/core';
import Action from 'src/app/models/action';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { ActionService } from 'src/app/services/action.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service'
import Contact from 'src/app/models/contact';
import { SelectContactsPage } from '../select-contacts/select-contacts.page';

@Component({
  selector: 'app-select-touchpoints',
  templateUrl: './select-touchpoints.page.html',
  styleUrls: ['./select-touchpoints.page.scss'],
})
export class SelectTouchpointsPage implements OnInit  {
  public actions: Action[] = [];
  public selectedContacts: Contact[];
  public selectedActions: Action[];
  public isLoadingActions: Boolean;
  public fetchNewActions: boolean = true;
  public visibleActions;

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private actionService: ActionService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private globalService: GlobalService
  ) {}

  public async ngOnInit () {
    GlobalService.log("Selected Actions:");
    GlobalService.log(this.selectedActions);
    if(this.selectedActions == undefined){
      this.selectedActions = [];
    }
    await this.ionViewDidEnterManual();
  }

  public ionViewDidEnterManual () {
    if (this.fetchNewActions) {
      this.getActions();
    } else { 
      this.fetchNewActions = true; // Ensure fetchNewContacts is on for future contact selects
    }
  }

  public searchActions(eventData){
    if(eventData.detail.value){
      let searchValue = eventData.detail.value
      GlobalService.log(searchValue);
      GlobalService.log(ActionService.SearchedActions);
      ActionService.SetSearchedActions(ActionService.SearchedActions);
  
      let newActions = [];
      ActionService.SearchedActions.forEach(action => {
        if(action.title && action.title.toLowerCase().includes(searchValue.toLowerCase())){
          newActions.push(action);
        }
      });
      this.actions = [];
      newActions.forEach(action => {
        if(action.status && action.status > 1){
          this.actions.push(action);
        }
      });
      GlobalService.log(this.actions);
    } else {
      ActionService.SearchedActions.forEach(action => {
        if(action.status && action.status > 1){
          this.actions.push(action);
        }
      });
    }
  }

  public searchedActions(){
    return ActionService.SearchedActions;
  }

  public getActions () {
    this.isLoadingActions = true;

    this.actionService.getActionsByStatus(2).then(actions => {
      actions.forEach(action => {
        if(action.status && action.status > 1){
          this.actions.push(action);
        }
      });

      this.isLoadingActions = false;
    }).catch(async err => {
      this.isLoadingActions = false;      
      let buttons = [];
      buttons.push({
        cssClass: "cancelButton",
        text: 'OK',
        handler: () => {
          this.dismiss(false);
        }
      });

      this.globalService.ionicAlert(
        buttons,
        "ERROR",
        "LOADINGACTIONSFAILED",
        "custom-alert",
        true,
        true
      );
    });
  }

  public dismiss(dismissed) {
    GlobalService.log(this.selectedActions);
    GlobalService.log(dismissed);
    
    this.modalController.dismiss({
      dismissed: dismissed,
      touchpoints: this.selectedActions
    });
  }

  public goBack() {
    this.dismiss(false);
  }

  public submit() {
    this.dismiss(true);
  }

  private compareActions (action: Action) {
    return x => (action.id_action != undefined && x.id_action == action.id_action);
  }

  public actionIsSelected (action: Action): boolean {
    return this.selectedActions.find(this.compareActions(action)) != undefined;
  }

  public selectAction (action: Action) {
    if (this.selectedActions.find(this.compareActions(action))) {
      this.selectedActions.splice(this.selectedActions.findIndex(this.compareActions(action)),1);
    } else {
      this.selectedActions.push(action);
    }
  }

  public async selectActions() {
    const modal = await this.modalController.create({
      component: SelectContactsPage,
      cssClass: "fullscreen-modal",
      componentProps: {
        selectedContacts: this.selectedContacts,
      },
    });

    modal.onDidDismiss().then((data) => {
      this.selectedContacts = data.data.actions;
    });

    return await modal.present();
  }
  
  public async selectTouchpoints() {
    const modal = await this.modalController.create({
      component: SelectContactsPage,
      cssClass: "fullscreen-modal",
      componentProps: {
        selectedContacts: this.selectedContacts,
      },
    });

    modal.onDidDismiss().then((data) => {
      this.selectedContacts = data.data.touchpoints;
    });

    return await modal.present();
  }

  public selectAll () {
    if (this.selectedActions.length == this.actions.length) {
      this.selectedActions = [];
    } else {
      this.selectedActions = [...this.actions]
    }
  }
  
  isIos(){
    return this.globalService.isIos();
  }
}