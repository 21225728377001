import { Component, OnInit } from '@angular/core';
import Action from 'src/app/models/action';
import { ModalController, NavController, AlertController } from '@ionic/angular';
import { ActionService } from 'src/app/services/action.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service'

@Component({
  selector: 'app-select-actions',
  templateUrl: './select-actions.page.html',
  styleUrls: ['./select-actions.page.scss'],
})
export class SelectActionsPage implements OnInit  {
  public actions: Action[] = [];
  public selectedActions: Action[];
  public isLoadingActions: Boolean;
  public fetchNewActions: boolean = true;
  public visibleActions;
  public maximum: number = undefined;
  public sequencesOnly: boolean = false;

  constructor(
    private modalController: ModalController,
    private navController: NavController,
    private actionService: ActionService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private globalService: GlobalService
  ) {}

  public async ngOnInit () {
    GlobalService.log("Selected Actions:");
    GlobalService.log(this.selectedActions);
    if(this.selectedActions == undefined){
      this.selectedActions = [];
    }
    await this.ionViewDidEnterManual();
  }

  public ionViewDidEnterManual () {
    if (this.fetchNewActions) {
      this.getActions();
    } else { 
      this.fetchNewActions = true; // Ensure fetchNewContacts is on for future contact selects
    }
  }

  public searchActions(eventData){
    if(eventData.detail.value){
      let searchValue = eventData.detail.value
      GlobalService.log(searchValue);
      GlobalService.log(ActionService.SearchedActions);
      ActionService.SetSearchedActions(ActionService.SearchedActions);
  
      let newActions = [];
      ActionService.SearchedActions.forEach(action => {
        if(action.title && action.title.toLowerCase().includes(searchValue.toLowerCase())){
          newActions.push(action);
        }
      });
      this.actions = [];
      newActions.forEach(action => {
        if(!action.status || action.status == 0 || action.status == 1){
          this.actions.push(action);
        }
      });
      GlobalService.log(this.actions);
    } else {
      ActionService.SearchedActions.forEach(action => {
        if(!action.status || action.status == 0 || action.status == 1){
          this.actions.push(action);
        }
      });
    }
  }

  public searchedActions(){
    return ActionService.SearchedActions;
  }

  public async getActions () {
    this.isLoadingActions = true;

    let actions = await this.actionService.getActionsByStatus(-1)
    GlobalService.log(actions);
    for(let action of actions){
      if(this.sequencesOnly) {
        GlobalService.log("sequencesOnly");
        if(!action.id_previous_action && action.type != "Personal"){ this.actions.push(action); }
      }
      else if(!action.status || action.status == 0 || action.status == 1) { this.actions.push(action); }
    };

    GlobalService.log("Current Actions", this.actions);
    this.isLoadingActions = false;
  }

  public dismiss(dismissed) {
    GlobalService.log(this.selectedActions);
    GlobalService.log(dismissed);
    
    this.modalController.dismiss({
      dismissed: dismissed,
      actions: this.selectedActions
    });
  }

  public goBack() {
    this.dismiss(false);
  }

  public submit() {
    this.dismiss(true);
  }

  private compareActions (action: Action) {
    return x => (action.id_action != undefined && x.id_action == action.id_action);
  }

  public actionIsSelected (action: Action): boolean {
    return this.selectedActions.find(this.compareActions(action)) != undefined;
  }

  public selectAction (action: Action) {
    if (this.selectedActions.find(this.compareActions(action))) {
      this.selectedActions.splice(this.selectedActions.findIndex(this.compareActions(action)),1);
    } else {
      if(this.maximum == 1){
        this.selectedActions = [];
      }
      this.selectedActions.push(action);
    }
  }

  public selectAll () {
    let activeActions = [];
    this.actions.forEach(action => {
      if(action.status == 0 || this.sequencesOnly){
        activeActions.push(action);
      }
    });
    if (this.selectedActions.length == activeActions.length) {
      this.selectedActions = [];
    } else {
      this.selectedActions = [];
      activeActions.forEach(action => {
        this.selectAction(action);
      });
    }
  }
  
  isIos(){
    return this.globalService.isIos();
  }
}
