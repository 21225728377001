<ion-header>
  <ion-toolbar>
    <ion-buttons mode="md" slot="start">
      <ion-button mode="md" (click)="goBack()">
        <ion-icon class="new-style-icon" [src]="'assets/icon/chevron-left.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="finish-select-button" (click)="submit()" *ngIf="selectedActions && selectedActions.length > 0">
        <ion-icon class="new-style-icon v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text class="v-done">{{"DONE" | translate}}</ion-text>
      </ion-button>
      <ion-button class="finish-select-button" *ngIf="!selectedActions || selectedActions.length == 0">
        <ion-icon class="new-style-icon faded v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text class="v-done faded">{{"DONE" | translate}}</ion-text>
      </ion-button>
    </ion-buttons>

    <app-header [title]="'TOUCHPOINTS' | translate"></app-header>
  </ion-toolbar>

  <ion-searchbar search-icon="search-outline" mode="md" clear-icon="undefined" [placeholder]="'SEARCH' | translate" 
  (ionChange)="searchActions($event)"></ion-searchbar></ion-header>

<ion-content>
  <div [ngClass]="{'ios-search-margin': isIos()}"  class="search-margin"></div>

  <div *ngIf="this.isLoadingActions && !visibleActions">
    <ion-list>
      <app-action-mini-item></app-action-mini-item>
      <app-action-mini-item></app-action-mini-item>
      <app-action-mini-item></app-action-mini-item>
      <app-action-mini-item></app-action-mini-item>
      <app-action-mini-item></app-action-mini-item>
    </ion-list>
  </div>

  <div *ngIf="!this.isLoadingActions && actions">
    <div class="center-text select-page-buttonholder">
      <ion-button color="medium" (click)="selectAll()">
        {{ "SELECTALL" | translate }}
      </ion-button>
    </div>

    <ion-list>
      <ion-item [ngClass]="{'selected-item': actionIsSelected(action)}" *ngFor="let action of actions" ion-button (click)="selectAction(action)" [button]="true">
        <ion-grid class="ion-no-padding">
          <ion-row>
            <ion-col>
              <app-action-mini-item [select]="true" [action]="action" [clickable]="false" [lines]="false"></app-action-mini-item>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-list>
  </div>
</ion-content>