import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Action from 'src/app/models/action';
import { Router } from '@angular/router';
import { ActionService } from 'src/app/services/action.service';
import { NoteService } from 'src/app/services/note.service';
import { NavController, AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ContactService } from "src/app/services/contact.service";
import { GlobalService } from 'src/app/services/global.service';
import { AvatarLoaderService } from 'src/app/services/avatar-loader.service';
import actionpath from 'src/app/models/actionpath';
import { AuthService } from 'src/app/services/auth.service';
import { AdministrationService } from 'src/app/services/administration.service';

@Component({
  selector: 'app-action-mini-item',
  templateUrl: './action-mini-item.component.html',
  styleUrls: ['./action-mini-item.component.scss'],
})
export class ActionMiniItemComponent implements OnInit {
  @Input() public action: Action;
  @Input() public select: boolean = false;
  @Input() public detailpage: boolean = false;
  @Input() public originatedFromDetailPage: boolean = false;
  @Input() public clickable: boolean = true;
  @Input() public lines: boolean = true;
  @Input() public searching: boolean = false;
  @Input() public goal: actionpath;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onFinish: EventEmitter<any> = new EventEmitter();

  public detailedContacts = [];
  public active: boolean = false;
  public hideMe: boolean = false;
  public avatar = undefined;
  public dateString = "";
  public actionUserId = "";
  
  constructor(
    public actionService: ActionService,
    private navController: NavController,
    private globalService: GlobalService,
    private avatarLoader: AvatarLoaderService,
    private administrationService: AdministrationService
  ) { }

  async ngOnInit() {
    if(this.clickable){
      if(this.action){
        this.active = this.action && (this.action.duedate > new Date().getTime());
    
        if(this.select != false){ this.select = true; }
    
        if(this.action.contacts && this.action.contacts.length > 0){
          this.getAvatar();
        }
        
        this.initializeHideMe();

        if(this.action.due == 'Invalid Date')
          this.action = await this.actionService.getAction(this.action.id_action);
      }
    }
  }

  public async initializeHideMe(){
    if(!AdministrationService.UserData)
      await this.administrationService.getFullUserData();

    if(!this.debugMode() && AuthService.User && this.action
    && AdministrationService.UserData.id_user != this.action.id_user
    && !AdministrationService.UserData.administration){
      this.hideMe = true;
    }
  }

  public getDisplayName(retrievedContact){
    return ContactService.setDisplayName(retrievedContact);
  }

  public openActionDetails() {
    if (!this.clickable) return;

    if(this.originatedFromDetailPage){
      ActionService.SetCurrentAction(this.action);
      this.onClick.emit(this.action);
    } else {
      ActionService.SetCurrentAction(this.action);
      this.navController.navigateRoot("action-details");
    }
  }

  public async completeTask (action: Action, goal, status) {
    if(action){
      let originalStatus = this.action.status;
      await this.actionService.serviceCompleteTask(action, goal, status, originalStatus);
      this.hideMe = true;
      this.onFinish.emit();
  
      if(this.detailpage){
        this.goBack();
      }
    }
  }

  public goBack() {
    this.globalService.goBack()
  }

  public getDateString () {
    if(this.dateString == "") this.dateString = this.globalService.getDateString(this.action.due)
    else return this.dateString;
  }
  
  public async getAvatar () {
    if (!this.action || !this.action.contacts || !this.action.contacts[0]) return;

    if(!this.action.contacts[0].avatar || !this.action.contacts[0].avatar.includes('contacts.svg')){
      if(this.action.contacts[0].id_contact != undefined){
        this.avatarLoader.addToQueue(this.action.contacts[0]);
        this.avatar = await this.avatarLoader.getAvatar(this.action.contacts[0], true);
      }
    } else {
      this.avatar = this.action.contacts[0].avatar;
    }
  }

  public debugMode(){
    return GlobalService.DebugMode;
  }
}