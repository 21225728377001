import { Component, OnInit, Input } from '@angular/core';
import { ContactGroup } from 'src/app/models/contact-group';
import { Router } from '@angular/router';
import { ContactService } from 'src/app/services/contact.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-contact-group-item',
  templateUrl: './contact-group-item.component.html',
  styleUrls: ['./contact-group-item.component.scss'],
})
export class ContactGroupItemComponent implements OnInit {

  @Input() public group: ContactGroup;
  @Input() public clickable: boolean = true;
  @Input() public lines: boolean = true;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {}

  public openGroupDetails() {
    if (!this.clickable) return;

    ContactService.ContactGroup = this.group;
    this.router.navigateByUrl("contact-group-details");
  }

  public getSkeletonEnabled(){
    return GlobalService.DisplaySkeleton;
  }
}
