<ion-header [ngClass]="{'fixDesktopDetailHeader': isDesktop()}">
  <ion-toolbar>
    <ion-buttons mode="md" slot="start">
      <ion-button mode="md" (click)="unsavedData()">
        <ion-icon class="new-style-icon" [src]="'assets/icon/chevron-left.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="finish-select-button" mode="md" (click)="submit()" *ngIf="title.length > 0">
        <ion-icon class="new-style-icon v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text class="v-done">{{"DONE" | translate}}</ion-text>
      </ion-button>
      <ion-button class="finish-select-button" mode="md" *ngIf="title.length == 0">
        <ion-icon class="new-style-icon faded v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text class="v-done faded">{{"DONE" | translate}}</ion-text>
      </ion-button>
    </ion-buttons>

    <app-header *ngIf="status > 1" [title]="'TOUCHPOINT' | translate "></app-header>
    <app-header *ngIf="status < 2" [title]="(isEditingExisting ? 'EDITACTION' : 'ADDACTION') | translate "></app-header>
  </ion-toolbar>
</ion-header>

<div class="disable-background" *ngIf="isSubmitted">
  <div class="pop-up" (click)="debugTap()">
    <ion-spinner class="loading-spinner non-standard-loading-spinner" style="color: black;"></ion-spinner>
  </div>
</div>

<ion-content>
  <div class="disable-background" *ngIf="showTimeSelection" (click)="toggleTime()"></div>
  <div class="time-popup desktop-time-selection" *ngIf="showTimeSelection && isDesktop()">
    <div class="time-popup-name">{{ "TIME" | translate }}</div>
    <ion-datetime [(ngModel)]="time" presentation="time" [presentation]="'time'" (presentation)="'time'" required displayFormat="HH:mm" hourCycle="h23" minuteValues="0,15,30,45"></ion-datetime>
    <div (click)="toggleTime()" class="time-done-button">Done</div>
  </div>
  <div class="time-popup" *ngIf="showTimeSelection && !isDesktop()">
    <div class="time-popup-name">{{ "TIME" | translate }}</div>
    <ion-datetime [(ngModel)]="time" presentation="time" [presentation]="'time'" (presentation)="'time'" required displayFormat="HH:mm" hourCycle="h23" minuteValues="0,15,30,45"></ion-datetime>
    <div (click)="toggleTime()" class="time-done-button">Done</div>
  </div>


  <div *ngIf="isIos()" class="twentypx-padding"></div>
  <div class="bonus-modal-padding"></div>
  <ion-grid class="shadow ion-no-padding input-grid">
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label position="floating" color="primary">
            {{ 'DESCRIPTION' | translate }}
          </ion-label>

          <ion-input [id]="'titleId'" autocorrect="on" mode="md" required autocapitalize='true' (keydown)="onEnter($event)" #titleId [(ngModel)]="title" maxlength="45" [placeholder]="'DESCRIPTION' | translate"></ion-input>
        </ion-item>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="status != 2 && !previousAction" [ngClass]="{'hideDiv': disableTypeSelection}">
      <ion-col>
        <ion-item>
          <ion-label color="primary">
            {{ 'TYPE' | translate }}
          </ion-label>
          <ion-select interface="popover" [(ngModel)]="type">
            <ion-select-option (ionSelect)="disableContactSelection()" value="Personal" selected="true">
              {{ 'PERSONAL' | translate }}</ion-select-option>
            <ion-select-option (ionSelect)="enableContactSelection()" value="Action"> {{ "CONTACT" | translate}}
            </ion-select-option>
          </ion-select>

          <ion-select *ngIf="status > 1" interface="popover" [(ngModel)]="type">
            <ion-select-option selected="true" (ionSelect)="enableContactSelection()" value="Action"> {{ "CONTACT" | translate}}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
    <div *ngIf="type == 'Action'">
      <ion-row>
        <ion-col>
          <ion-item>
            <ion-label color="primary">Type {{ 'INTERACTION' | translate }}</ion-label>
            <ion-select interface="popover" [(ngModel)]="interactionType" class="action-select">
              <ion-select-option value="call">{{ "CALL" | translate }}</ion-select-option>
              <ion-select-option value="email">{{ "MAIL" | translate }}</ion-select-option>
              <ion-select-option value="text">{{ "TEXTING" | translate }}</ion-select-option>
              <ion-select-option value="meeting">{{ "MEETING" | translate }}</ion-select-option>
              <!--<ion-select-option value="videoconferencing">{{ "VIDEOCONFERENCING" | translate }}</ion-select-option>-->
            </ion-select>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <ion-item lines="none">
            <ion-label color="primary" *ngIf="selectedContacts">{{ "ADDEDCONTACTS" | translate }}: {{selectedContacts.length}}</ion-label>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="center-text" *ngIf="selectedContacts && selectedContacts.length == 0">
            <ion-text color="medium">{{"PLEASEADDCONTACTS" | translate }}</ion-text>
          </div>
          <ion-list>
            <ion-item *ngFor="let contact of selectedContacts">
              <ion-grid class="ion-no-padding">
                <ion-row>
                  <ion-col>
                    <app-contact-item [contact]="contact" [clickable]="false" [lines]="false"></app-contact-item>
                  </ion-col>
                </ion-row>
              </ion-grid>
              <ion-icon *ngIf="!previousAction" slot="end" class="delete-notification remove-contact-from-action" [src]="'assets/icon/delete.svg'"
               (click)="removeContact(contact)"></ion-icon>
            </ion-item>
          </ion-list>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col>
          <div class="center-text">
            <ion-button color="dark" fill="clear">
              <div (click)="selectContacts()">
                <ion-icon class="add-item text-color-dark" [src]="'assets/icon/plus-circle.svg'"></ion-icon>
              </div>
            </ion-button>
          </div>
        </ion-col>
      </ion-row>
    </div>
  </ion-grid>

  <ion-grid class="shadow ion-no-padding input-grid" *ngIf="this.checkedUserData && inAdministration() && userAdmin && loggedinUser">
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label color="primary">
            {{ 'USER' | translate }}
          </ion-label>
          <ion-select interface="popover" [(ngModel)]="assignedUser">
            <div *ngFor="let user of userAdmin.users">
              <ion-select-option value="{{user.id_user}}">
                {{ user.user_nicename }}
              </ion-select-option>
            </div>
          </ion-select>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>

  <ion-grid class="shadow ion-no-padding input-grid">
    <div *ngIf="!isDesktop() || debugMode()">
      <ion-row>
        <ion-col>
          <ion-item class="center-text">
            <ion-icon class="add-action-icon" [src]="'assets/icon/calendar.svg'"></ion-icon>
  
            <ion-input type="date" (change)="loggit()" [(ngModel)]="date" required max="2030-01-01" displayFormat="DD - MM - YYYY"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col (click)="toggleTime()">
          <ion-item class="center-text">
            <ion-icon class="add-action-icon" [src]="'assets/icon/clock.svg'"></ion-icon>
            {{ time }}
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="status != 2">
        <ion-col>
          <ion-item>
            <ion-label>
              {{"ADDTOCALENDER" | translate}}
            </ion-label>
            <ion-toggle [(ngModel)]="addToCalendar" (ionChange)="checkCalendarPermission()">
  
            </ion-toggle>
          </ion-item>
        </ion-col>
      </ion-row>
      <div *ngIf="addToCalendar" class="add-to-calendar-div">
        <ion-row>
          <ion-col>
            <ion-list>
              <ion-item>
                <ion-text class="action-notification-duration-text">
                {{ "DURATION" | translate }}: 
                </ion-text>
                <div class="action-notification-duration-div">
                  <ion-select slot="start" class="action-notification-duration-select" interface="popover" [(ngModel)]="calendarTime">
                    <ion-select-option value="15" selected="true">
                      <ion-label>15 min</ion-label>
                    </ion-select-option>
                    <ion-select-option value="30">
                      <ion-label>30 min</ion-label>
                    </ion-select-option>
                    <ion-select-option value="60">
                      <ion-label>1 {{ "HOUR" | translate }}</ion-label>
                    </ion-select-option>
                    <ion-select-option value="120">
                      <ion-label>2 {{ "HOURS" | translate }}</ion-label>
                    </ion-select-option>
                    <ion-select-option value="240">
                      <ion-label>4 {{ "HOURS" | translate }}</ion-label>
                    </ion-select-option>
                    <ion-select-option value="480">
                      <ion-label>8 {{ "HOURS" | translate }}</ion-label>
                    </ion-select-option>
                  </ion-select>
                </div>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </div>
      
      <ion-row *ngIf="status != 2">
        <ion-col>
          <ion-item>
            <ion-label>
              {{"ADDNOTIFICATIONS" | translate}}
            </ion-label>
            <ion-toggle [(ngModel)]="hasNotifications">
  
            </ion-toggle>
          </ion-item>
        </ion-col>
      </ion-row>
      <div *ngIf="hasNotifications">
        <ion-row>
          <ion-col>
            <ion-list>
              <ion-item *ngFor="let notification of notifications">
                <ion-text class="action-notification-duration-text">
                  Timer: 
                </ion-text>
                <div class="action-notification-duration-div">
                  <ion-select *ngIf="notification" slot="start" interface="popover" class="action-notification-duration-select" [(ngModel)]="notification.time">
                    <ion-select-option value="0.25">
                      <ion-label>15 min</ion-label>
                    </ion-select-option>
                    <ion-select-option value="0.5" selected="true">
                      <ion-label>30 min</ion-label>
                    </ion-select-option>
                    <ion-select-option value="1">
                      <ion-label>1 {{ "HOUR" | translate }}</ion-label>
                    </ion-select-option>
                    <ion-select-option value="2">
                      <ion-label>2 {{ "HOURS" | translate }}</ion-label>
                    </ion-select-option>
                    <ion-select-option value="12">
                      <ion-label>12 {{ "HOURS" | translate }}</ion-label>
                    </ion-select-option>
                    <ion-select-option value="24">
                      <ion-label>1 {{ "DAY" | translate }}</ion-label>
                    </ion-select-option>
                    <ion-select-option value="48">
                      <ion-label>2 {{ "DAYS" | translate }}</ion-label>
                    </ion-select-option>
                  </ion-select>
                  <ion-icon slot="end" class="delete-notification" [src]="'assets/icon/delete.svg'"
                   (click)="removeNotification(notification)"></ion-icon>
                </div>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="notifications.length < 2">
          <ion-col>
            <div class="center-text">
              <ion-button color="dark" fill="clear" (click)="addNotification()">
                <ion-icon class="add-item text-color-dark" [src]="'assets/icon/plus-circle.svg'" *ngIf="notifications"></ion-icon>
              </ion-button>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </div>
    <div *ngIf="isDesktop() && !debugMode()">
      <ion-row>
        <ion-col>
          <ion-item class="center-text">
            <div class="fi ai-flaticon-calendar"></div>
  
            <ion-input type="date" (change)="loggit()" [(ngModel)]="date" required max="2030-01-01" displayFormat="DD - MM - YYYY"></ion-input>
          </ion-item>
        </ion-col>
        <ion-col (click)="toggleTime()">
          <ion-item class="center-text">
            <div class="fi ai-flaticon-clock" style="margin-left: 10px;"></div>
            {{ time }}
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="isIos()">
        <ion-col>
          <ion-item>
            <ion-label>
              {{"ADDTOCALENDER" | translate}}
            </ion-label>
            <ion-toggle [(ngModel)]="addToCalendar" (ionChange)="checkCalendarPermission()">
  
            </ion-toggle>
          </ion-item>
        </ion-col>
      </ion-row>
      <div class="add-to-calendar-div" *ngIf="addToCalendar">
        <ion-row>
          <ion-col>
            <ion-list>
              <ion-item>
                <ion-text class="action-notification-duration-text">
                {{ "DURATION" | translate }}: 
                </ion-text>
                <div class="action-notification-duration-div">
                  <ion-select slot="start" class="action-notification-duration-select" interface="popover" [(ngModel)]="calendarTime">
                    <ion-select-option value="15" selected="true">
                      <ion-label>15 min</ion-label>
                    </ion-select-option>
                    <ion-select-option value="30">
                      <ion-label>30 min</ion-label>
                    </ion-select-option>
                    <ion-select-option value="60">
                      <ion-label>1 {{ "HOUR" | translate }}</ion-label>
                    </ion-select-option>
                    <ion-select-option value="120">
                      <ion-label>2 {{ "HOURS" | translate }}</ion-label>
                    </ion-select-option>
                  </ion-select>
                </div>
              </ion-item>
            </ion-list>
          </ion-col>
        </ion-row>
      </div>
    </div>
  </ion-grid>

  <ion-grid class="shadow ion-no-padding input-grid">
    <ion-row>
      <ion-col>
        <ion-item>
          <ion-label position="floating" color="primary">
            {{"NOTE" | translate}}
          </ion-label>
          <ion-textarea class="action-description" autocorrect="on" autocomplete="true" spellcheck="true" autocapitalize='true' auto-grow="true" rows="6" [(ngModel)]="description" [placeholder]="'DESCRIPTIONFIELD' | translate">

          </ion-textarea>
        </ion-item>
      </ion-col>
    </ion-row>
  </ion-grid>

  
  <div class="bottom-button" *ngIf="title.length != 0" (click)="submit()">
    {{ "DONE" | translate }}
  </div>
  <div class="bottom-button faded-bottom-button" *ngIf="title.length == 0" (click)="submit()">
    {{ "DONE" | translate }}
  </div>

</ion-content>
