import { Injectable, NgZone } from "@angular/core";
import { CrudService } from "./crud.service";
import Action from "../models/action";
import { AlertController, NavController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/services/global.service';
import { NoteService } from "./note.service";
import { NotificationService } from "./notification.service";
import { Storage } from "@ionic/storage-angular";
import { AuthService } from "./auth.service";
import { LocalNotificationPendingList, LocalNotificationRequest, LocalNotifications} from '@capacitor/local-notifications'
import Contact from "../models/contact";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SubscriptionService} from "src/app/services/subscription.service";
import { ActionpathService} from "src/app/services/actionpath.service";
import actionpath from "../models/actionpath";
import { ContactService } from "./contact.service";
import { SelectGoalsPage } from "../pages/select-goals/select-goals.page";
import { AddactionpathPage } from "../pages/add-actionpath/add-actionpath.page";
import { AddActionPage } from "../pages/add-action/add-action.page";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class ActionService extends CrudService {
  private static action: Action;
  private static actions: Action[] = [];
  private static searchedActions: Action[];
  private static isEditing: boolean;
  private static hasLoaded: {open: boolean, archive: boolean, touchpoints: boolean} = {open: false, archive: false, touchpoints: false};
  private static actionGroups: ActionGroup[];
  private static actionDetails: boolean = false;
  private static actionStatus: number = 0;
  private static actionPageTab: number = 0;
  private static fromNote: boolean = false;
  private static dashboardActions: Action[];
  private static previousAction: any = "";
  private static tempAPList: any[];
  private static selectedGoals: any[];
  private static actionsCounted = false;
  private static openActionSize = 0;
  private static refreshActionPage: boolean = false;
  private static refreshDashboardPage: boolean = false;
  private static resetActionPage: boolean = false;

  constructor(
    public httpClient: HttpClient,
    public navController: NavController,
    public translateService: TranslateService,
    public alertController: AlertController,
    public globalService: GlobalService,
    public noteService: NoteService,
    public notificationService: NotificationService,
    public zone: NgZone,
    public subscriptionService: SubscriptionService,
    public authService: AuthService,
    public actionpathService: ActionpathService,
    public storageService: StorageService,
    private modalController: ModalController,
    public contactService: ContactService
  ) {
    super(httpClient, navController, translateService, alertController, globalService, storageService, authService);
  } 
  
  public static get ResetActionPage () {
    return this.resetActionPage;
  }

  public static set ResetActionPage (value) {
    this.resetActionPage = value;
  }

  public static get RefreshDashboardPage () {
    return this.refreshDashboardPage;
  }

  public static set RefreshDashboardPage (value) {
    this.refreshDashboardPage = value;
  }

  public static get RefreshActionPage () {
    return this.refreshActionPage;
  }

  public static set RefreshActionPage (value) {
    this.refreshActionPage = value;
  }

  public static get OpenActionSize () {
    return this.openActionSize;
  }

  public static set OpenActionSize (value) {
    this.openActionSize = value;
  }

  public static get ActionsCounted () {
    return this.actionsCounted;
  }

  public static set ActionsCounted (value) {
    this.actionsCounted = value;
  }

  public static get SelectedGoals () {
    return this.selectedGoals;
  }

  public static set SelectedGoals (value) {
    this.selectedGoals = value;
  }

  public static get PreviousAction () {
    return this.previousAction;
  }

  public static set PreviousAction (value) {
    this.previousAction = value;
  }

  public static get TempAPList () {
    return this.tempAPList;
  }

  public static set TempAPList (value) {
    this.tempAPList = value;
  }

  public static get FromNote () {
    return this.fromNote;
  }

  public static set FromNote (value) {
    this.fromNote = value;
  }

  public static get DashboardActions () {
    return this.dashboardActions;
  }

  public static set DashboardActions (value) {
    this.dashboardActions = value;
  }

  public static get ActionGroups () {
    return this.actionGroups;
  }

  public static set ActionGroups (value) {
    this.actionGroups = value;
  }

  public static set ActionStatus (value) {
    this.actionStatus = value;
  }

  public static set ActionPageTab (value) {
    this.actionPageTab = value;
  }

  public static get ActionPageTab () {
    return this.actionPageTab;
  }

  public static get ActionStatus () {
    return this.actionStatus;
  }

  public static get Action () {
    return this.action;
  }

  public static set Action (value) {
    this.action = value;
  }
  
  public static get Actions () {
    return this.actions;
  }

  public static PushAction (action: Action) {
    console.log("push action into action group", action);
    if(this.actions != undefined){
      if(action.user) action.id_user = action.user.id_user;
      action.duedate = new Date(action.due).getTime();
      action.due = new Date(action.due).toLocaleString();
      if(!action.contacts){ action.contacts = []; }
      this.actions.push(action);
      this.getActionGroups(this.actions);
      ActionService.ActionsCounted = false;
    }
  }

  public removeActionFromList(action): void {
    if(ActionService.actions && ActionService.actionGroups){
      ActionService.SetCurrentActions(ActionService.actions.filter(({ id_action }) => id_action !== action.id_action));
      
      for(let actiongroup of ActionService.actionGroups){
        actiongroup.actions = actiongroup.actions.filter(({ id_action }) => id_action !== action.id_action);
      }; 
    }

    if(ActionService.DashboardActions){
      ActionService.DashboardActions = ActionService.DashboardActions.filter(({ id_action }) => id_action !== action.id_action);
    }
    ActionService.ActionsCounted = false;
  }

  public static get SearchedActions () {
    return this.searchedActions;
  }

  public static set HasLoadedActions (hasLoaded: {open: boolean, archive: boolean, touchpoints: boolean}) {
    this.hasLoaded = hasLoaded;
  }

  public static get HasLoadedActions () {
    return this.hasLoaded;
  }

  public static SetCurrentAction (action: Action) {
    this.action = action;
  }

  public static SetCurrentActions (actions: Action[]) {
    this.actions = actions;
  }

  public static SetIsEditing (isEditing: boolean) {
    this.isEditing = isEditing;
  }

  public static SetSearchedActions (actions: Action[]) {
    this.searchedActions = actions;
  }

  public static get IsEditing () {
    return this.isEditing;
  }

  public async getAllActions(): Promise<Array<Action>> {
    console.log("Getting actions...");

    let res = await this.read("/action/list");

    let Actionlist = res as Action[];

    Actionlist.forEach(action => {
      action.duedate = new Date(action.due).getTime();
      action.due = new Date(action.due).toLocaleString();
      if(!action.contacts){ action.contacts = []; }
    });

    ActionService.SetCurrentActions(Actionlist);
    ActionService.SetSearchedActions(Actionlist);
    ActionService.getActionGroups(Actionlist);
    return Actionlist;
  }

  public async getActionsByStatus(status: number): Promise<Array<Action>> {
    //-1 is all actions, 0 = open actions, 1 = archive, 2 = touchpoints.
    let Actionlist: Action[] = [];
    let res;
    if(status == 2){
      console.log("Getting actions by status 2, 3 and 4");
      res = await this.read("/action/list/status/" + 2);
      for(let action of res) Actionlist.push(action);
      res = await this.read("/action/list/status/" + 3);
      for(let action of res) Actionlist.push(action);
      res = await this.read("/action/list/status/" + 4);
      for(let action of res) Actionlist.push(action);
    } else if(status == -1) {
      console.log("Getting actions by status 0 and 1");
      res = await this.read("/action/list/status/" + 0);
      for(let action of res) Actionlist.push(action);
      res = await this.read("/action/list/status/" + 1);
      for(let action of res) Actionlist.push(action);
    } else {
      console.log("Getting actions by status " + status);
      res = await this.read("/action/list/status/" + status);
      Actionlist = res as Action[];
    }

    Actionlist.forEach(action => {
      action.duedate = new Date(action.due).getTime();
      action.due = new Date(action.due).toLocaleString();
      if(!action.contacts){ action.contacts = []; }
    });
    console.log("retrieved actions", Actionlist);

    ActionService.SetCurrentActions(Actionlist);
    ActionService.SetSearchedActions(Actionlist);
    ActionService.getActionGroups(Actionlist);
    return Actionlist;
  }

  public async getActionpathActions(idActionPath: number){
    console.log("Getting actions...");

    let res = await this.read("/action/list/actionpath/" + idActionPath);

    let Actionlist = res as Action[];

    Actionlist.forEach(action => {
      action.duedate = new Date(action.due).getTime();
      action.due = new Date(action.due).toLocaleString();
      if(!action.contacts){ action.contacts = []; }
    });

    return Actionlist
  }

  public async createAction (action: Action) : Promise<Response> {
    console.log(action);
    return await this.put("/action/create?addtogoal=true", action);
  }

  public async updateAction (action: Action) : Promise<Response> {
    let fixedAction = action;

    if(!fixedAction.action_notifications){
      fixedAction.action_notifications = [];
    }
    if(!fixedAction.contacts){
      fixedAction.contacts = [];
    }

    let finalGoalList = [];
    if(fixedAction.actionpaths){
      for(let goal of fixedAction.actionpaths){
        let tempGoal = await this.actionpathService.getactionpath(goal.id_action_path);
        tempGoal.user = AuthService.User;
        finalGoalList.push(tempGoal);
        if(!tempGoal.actions) tempGoal.actions = [];
        for(var i=0; i < tempGoal.actions.length; i++){
          tempGoal.actions[i] = {"id_action": tempGoal.actions[i].id_action };
        }
        console.log("AP", tempGoal);
      };
    }

    ActionService.TempAPList = finalGoalList;
    fixedAction.actionpaths = [];

    GlobalService.log("update action", action);
    return await this.put("/action/create?addtogoal=true", fixedAction);
  }

  public async deleteAction (action: any, convert: boolean = false) {
    if(convert){
      return this.delete("/action/convertdelete/"+action.id_action);
    } else {
      if(action.notes){
        await action.notes.forEach(async note => {
          await this.noteService.deleteNote(note);
        })
      }
      
      return this.delete("/action/truedelete/"+action.id_action);
    }
  }

  public async getAction (actionId): Promise<Action> {
    if(typeof actionId != 'number' ){
      actionId = actionId.id_action;
    }

    let res = await this.read("/action/byid/" + actionId);
    // GlobalService.log("obtained single Action", res);
    let action = res as Action;
    if(!action.status){ action.status = 0; }
    action.duedate = new Date(action.due).getTime();
    action.due = new Date(action.due).toLocaleString();
    // GlobalService.log("fixed single Action", res);
    return action;
  }

  public async getActionPure (id: number): Promise<Action> {
    let res = await this.read("/action/byid/" + id)
    let action = res as Action;
    if(!action.status){ action.status = 0; }
    return action;
  }

  public async createNotification (notification, action: Action) {
    console.log(notification);
    notification.id_notification = undefined;
    GlobalService.log("send following notif to API", notification);
    let res = await this.put("/action/newnotification/" + action.id_action, notification);
    return res;
  }

  public async deleteNotification (notification) {
    await this.notificationService.deleteNotification(notification);
    return this.delete("/action/deletenotification/"+ notification.id_notification);
  }

  public static getActionGroups(actions: Array<Action>) {
    console.log("Creating groups", actions);

    let groups: ActionGroup[] = [];
    let todayGroup: ActionGroup = { name: "TODAY", actions: [] };
    let tomorrowGroup: ActionGroup = { name: "TOMORROW", actions: [] };
    let thisweekGroup: ActionGroup = { name: "THISWEEK", actions: [] };
    let futureGroup: ActionGroup = { name: "FUTURE", actions: [] };
    let historyGroup: ActionGroup = { name: "OVERDUE", actions: [] };
    let archiveGroup: ActionGroup = { name: "ARCHIVE", actions: [] };
    let touchpointGroup: ActionGroup = { name: "TOUCHPOINT", actions: [] };
    let todayDate: Date = new Date();
    let totalDays = Math.floor(todayDate.getTime() / 86400000);

    for (let i = 0; i < actions.length; i++) {
      let action = actions[i];
      if(action.due == undefined) { action.due = new Date().toISOString(); }
      
      let actionDays = Math.floor(action.duedate / 86400000);

      if (actionDays == totalDays && (!action.status || action.status == 0)) {
        todayGroup.actions.push(action);
        // if (action.duedate > todayDate.getTime()) {
        //   todayGroup.actions.push(action);
        // } else {
        //   historyGroup.actions.push(action);
        // }
      } else if (actionDays == totalDays + 1 && (!action.status || action.status == 0)) {
        tomorrowGroup.actions.push(action);
      } else if (actionDays > totalDays + 1 && actionDays < totalDays + 7 && (!action.status || action.status == 0)) {
        thisweekGroup.actions.push(action);
      } else if (actionDays > totalDays + 1 && (!action.status || action.status == 0)) {
        futureGroup.actions.push(action);
      } else if (actionDays < totalDays && (!action.status || action.status == 0)) {
        historyGroup.actions.push(action);
      }

      if(action.status == 1){
        archiveGroup.actions.push(action);
      }
      if(action.status > 1){
        touchpointGroup.actions.push(action);
      }
    }

    todayGroup.actions.sort((a, b) => (new Date(a.duedate) > new Date(b.duedate)) ? 1 : -1)
    tomorrowGroup.actions.sort((a, b) => (new Date(a.duedate) > new Date(b.duedate)) ? 1 : -1)
    thisweekGroup.actions.sort((a, b) => (new Date(a.duedate) > new Date(b.duedate)) ? 1 : -1)
    futureGroup.actions.sort((a, b) => (new Date(a.duedate) > new Date(b.duedate)) ? 1 : -1)
    historyGroup.actions.sort((a, b) => (new Date(b.duedate) > new Date(a.duedate)) ? 1 : -1)
    archiveGroup.actions.sort((a, b) => (new Date(b.duedate) > new Date(a.duedate)) ? 1 : -1)
    touchpointGroup.actions.sort((a, b) => (new Date(b.duedate) > new Date(a.duedate)) ? 1 : -1)

    //Push in the correct order
    groups.push(historyGroup);
    groups.push(todayGroup);
    groups.push(tomorrowGroup);
    groups.push(thisweekGroup);
    groups.push(futureGroup);
    groups.push(touchpointGroup);
    groups.push(archiveGroup);

    ActionService.ActionGroups = groups;
  }

  public async deleteAllActions(){
    let allActions = await this.getAllActions().catch(err =>{
      return null;
    });
    let IDActions = [];

    if(allActions){
      allActions.forEach(action => {
        IDActions.push({"id_action": action.id_action});
      });
  
      if(allActions && allActions.length > 0){
        let deleteAll = this.deleteActionsCollection(IDActions);
  
        return deleteAll;
      }
    } else return;
  }
  
  public async deleteActionsCollection(actionList: Action[]){
    console.log("delete actions");
    console.log(actionList);
    let sleepTimer = 0;
    let deleteList = [];

    while(actionList.length > 0){
      if(actionList.length < 10 && actionList.length != 0){
        actionList.forEach(contact => {
          this.removeActionFromList(contact);
        });
        await this.post(`/action/delete/collection`,actionList);
        actionList = [];
      }

      deleteList.push(actionList[0]);
      this.removeActionFromList(actionList[0]);
      actionList.shift(); 

      if(sleepTimer == 10){
        await this.post(`/action/delete/collection`,deleteList);
        deleteList = [];
        sleepTimer = 0;
      }

      sleepTimer++;
    }
    
    return;
  }

  public async listenToNotifications(){
    LocalNotifications.addListener('localNotificationActionPerformed', (notification) => {
      console.log('Notification action received', notification.notification);
      this.zone.run(() => this.openActionDetailsFromNotification(notification.notification));
    });
  }

  public async openActionDetailsFromNotification(notification) {
    console.log("go to the following.extra.action");
    console.log(notification);
    ActionService.SetCurrentAction(notification.extra.action);
    this.navController.navigateRoot("action-details");
  }

  public getContact (id) : Promise<Contact> {
    return this.read(`/contact/byid/${id}`).then(res => {
      return res as Contact;
    });
  }

  public static resetLocalLists (){
    ActionService.actions = [];
    ActionService.actionGroups = [];
    ActionService.searchedActions = [];
    ActionService.HasLoadedActions = {open: false, archive: false, touchpoints: false};
  }
  
  async prepareSequence(startingAction){
    console.log("preparing sequence");

    let sequenceList = [];
    sequenceList.push(startingAction);

    try{
      await this.checkForward(sequenceList, startingAction);
    } catch(error) { console.log("could not acquire"); }
    
    try{
      await this.checkBackward(sequenceList, startingAction);
    } catch(error) { console.log("could not acquire"); }

    return sequenceList.sort((b, a) => (a.id_action > b.id_action) ? 1 : -1);
  }

  async checkForward(sequenceList, startingAction){
    let checkForward = startingAction.id_follow_up_action;
    
    while(checkForward){
      console.log("next", checkForward);
      let forwardAction = await this.getAction(parseInt(checkForward));
      sequenceList.push(forwardAction);
      checkForward = forwardAction.id_follow_up_action;

      if(!checkForward){
        return;
      }
    }

    if(!checkForward){
      return;
    }
  }

  async checkBackward(sequenceList, startingAction){
    let checkBackward = startingAction.id_previous_action;

    while(checkBackward){
      console.log("previous", checkBackward);
      let previousAction = await this.getAction(parseInt(checkBackward));
      sequenceList.push(previousAction);
      checkBackward = previousAction.id_previous_action;

      if(!checkBackward){
        return;
      }
    }

    if(!checkBackward){
      return;
    }
  }

  async finishActionUpdate(res, action, initialNotifications){
    GlobalService.log("finish action update", res);

    this.removeActionFromList(action);
    let fullAction = res as unknown as Action;

    fullAction.contacts = action.contacts;
    if(fullAction.contacts && fullAction.contacts.length > 0)
      fullAction.contacts[0] = await this.getContact(fullAction.contacts[0].id_contact);
    
    ActionService.PushAction(fullAction);

    GlobalService.log("notification data", initialNotifications);

    //Setup the notifications for the updated action
    let notiffAction = fullAction;
    if(notiffAction.action_notifications && notiffAction.action_notifications.length > 0){
      for (let i = 0; i < notiffAction.action_notifications.length; i++) {
        initialNotifications[i].id_notification = fullAction.action_notifications[i].id_notification;
        this.notificationService.scheduleNotification(notiffAction.title, initialNotifications[i], notiffAction);
      }
    }

    if(ActionService.TempAPList){
      let tempAPList = ActionService.TempAPList;
      ActionService.TempAPList = undefined;
      for(let AP of tempAPList){
        for(let A of AP.actions){
          A = {"id_action": A.id_action };
        }
        await this.actionpathService.updateactionpath(AP);
      }
    }

    ActionService.ActionsCounted = false;
    GlobalService.IsLoading = false;
    GlobalService.log("action update finished", res);
    return fullAction;
  }

  public async convertActionNotifications(action, contacts, initialNotifications){
    let tempContactList = [];
    if(contacts && contacts.length > 0){
      for(let c = 0; c < contacts.length; c++){
        let tempContact = await this.contactService.getContact(contacts[c].id_contact);
        tempContactList.push(tempContact);
        console.log(tempContact);
      }
    }

    for(let i = 0; i < initialNotifications.length; i++) {
      let logInfo = initialNotifications[i];
      GlobalService.log("notification before transformation", logInfo);
      let reductionTime = Number.parseFloat(initialNotifications[i].time) * (60 * 60 * 1000);
      let notifTime = parseInt(action.due) - reductionTime;
      if(reductionTime > 0){
        initialNotifications[i].time = notifTime;
        initialNotifications[i].msg = "";
        if(tempContactList && tempContactList.length > 0){
          //Put in type, uppercase first letter.
          initialNotifications[i].msg += action.type + ": ";
          initialNotifications[i].msg = initialNotifications[i].msg.charAt(0).toUpperCase() + initialNotifications[i].msg.slice(1);

          for(let c = 0; c < tempContactList.length; c++){
            initialNotifications[i].msg += ContactService.setDisplayName(tempContactList[c]);
            if(action.type == "email" && tempContactList[c].email_list && tempContactList[c].email_list[0])
            initialNotifications[i].msg += ": " + tempContactList[c].email_list[0].email + ". " ;

            if((action.type == "call" || action.type == "text") && tempContactList[c].telephone_list && tempContactList[c].telephone_list[0])
            initialNotifications[i].msg += ": " + tempContactList[c].telephone_list[0].number + ".";

            if(initialNotifications[i].msg.charAt(initialNotifications[i].msg.length - 1) != ".")
              initialNotifications[i].msg += ",";
            initialNotifications[i].msg += " ";
          }
        }
        if( initialNotifications[i].msg != ""){ initialNotifications[i].msg += "BIGMESSAGE"; }
        initialNotifications[i].msg += `${this.globalService.getDateString(new Date(notifTime).toUTCString())}`;

        GlobalService.log("notification after transformation", initialNotifications[i]);
      }
    }
    action.action_notifications = initialNotifications;
    return action;
  }

  public setDisplayName(contact){
    if(!contact) return "?";
    
    if(contact.first_name != undefined && contact.first_name != "" && contact.first_name != "?"){
      if(contact.last_name != undefined && contact.last_name != "" && contact.last_name != "?") {
        return contact.first_name + " " + contact.last_name;
      } else {
        return contact.first_name;
      }
    } else if(contact.last_name != undefined && contact.last_name != "" && contact.last_name != "?"){
      return contact.last_name;
    } else {
      if(contact.company) return contact.company;
      else if(contact.function) return contact.function;
      else return "?";
    }
  }

  ///////////////////////////////////////////////
  //Everything below here should be transferred to a seperate action-completion-service

  public async createFollowUp(idAction, goal?, time?){
    console.log("was idAction set up?", idAction);
    let buttons = [];
    buttons.push({
      cssClass: 'delete-yes first-yes-no',
      text: this.translateService.instant("YES"), handler: async () =>
      {
        if(ActionService.Actions){
          if(await this.subscriptionService.checkSubscription(ActionService.Actions.length, "ACTIONS")){
            ActionService.ActionStatus = 0;
            ActionService.PreviousAction = idAction;
            this.quickFollowTime(goal, time);
          }
        } else {
          ActionService.ActionStatus = 0;
          ActionService.PreviousAction = idAction;
          this.quickFollowTime(goal, time);
        }
      }
    });

    buttons.push({
      cssClass: 'delete-no second-yes-no',
      text: this.translateService.instant("NO"),
      handler: () => {
        this.didActionSucceed(idAction);
      }
    });
    
    this.globalService.ionicAlert(
      buttons,
      "WOULDYOULIKETOCREATEFOLLOWUP"
    );
  }

  public async createFollowUpOnContactCreation(idContact){
    let buttons = [];
    buttons.push({
      cssClass: 'delete-yes first-yes-no',
      text: this.translateService.instant("YES"), handler: async () =>
      {
        if(ActionService.Actions){
          if(await this.subscriptionService.checkSubscription(ActionService.Actions.length, "ACTIONS")){
            ActionService.ActionStatus = 0;
            this.navController.navigateRoot("add-action", {queryParams: {id_contact: idContact}});
          }
        } else {
          ActionService.ActionStatus = 0;
          this.navController.navigateRoot("add-action", {queryParams: {id_contact: idContact}});
        }
      }
    });
    buttons.push({
      cssClass: 'delete-no second-yes-no',
      text: this.translateService.instant("NO"),
      handler: () => {}
    });
    
    this.globalService.ionicAlert(
      buttons,
      "WOULDYOULIKETOCREATEFOLLOWUP"
    );
  }

  //Action can be either a full action, or an action id.
  public async serviceCompleteTask(action, goal, finalStatus, originalStatus){
    if(action){
      GlobalService.IsLoading = true;
      let actionId;
      if(typeof action == "number") actionId = action;
      else actionId = action.id_action;
      
      action = await this.getActionPure(actionId);
      console.log("Complete task: ", action);
      if(finalStatus == 2 && (!action.contacts || action.contacts.length == 0)) finalStatus = 1;
      await this.patch("/action/" + actionId + "/setstatus/" + finalStatus, undefined);

      //To-Do: delete all notifications of action using actionId

      let fullAction = await this.refreshSingleAction(actionId);
      if(finalStatus == 2 && originalStatus == 0){
        if(goal) await this.createFollowUp(fullAction.id_action, goal);
        else await this.pickGoalType(fullAction.id_action);
      }
      GlobalService.IsLoading = false;
      if(finalStatus == 1 || finalStatus == 2) {
        if(finalStatus == 1)this.globalService.returnToast(this.translateService.instant("CONGRATULATIONSACTION"));
        if(finalStatus == 2)this.globalService.returnToast(this.translateService.instant("CONGRATULATIONSTOUCHPOINT"));
        this.globalService.displayConfetti();
      }
    }
    return;
  }

  async refreshSingleAction(actionId){
    let fullAction = await this.getAction(actionId);
    this.removeActionFromList(fullAction);
    if(fullAction.action_notifications){
      for(let notification of fullAction.action_notifications){
        console.log("This notification is deleted", notification);
        await this.deleteNotification(notification)
      }
      fullAction.action_notifications = undefined;
    }
    ActionService.PushAction(fullAction);
    return fullAction;
  }

  public async quickFollowTime(goal?, time?){
    let buttons = []

    if(GlobalService.DebugMode){
      buttons.push({
        text: this.translateService.instant("TODAY"), handler: async () =>{
          this.followUpModal(goal, time, 0);
        }
      });
    }

    buttons.push({
      text: this.translateService.instant("TOMORROW"), handler: async () =>{
        this.followUpModal(goal, time, 1);
      }
    });
    buttons.push({
      text: this.translateService.instant("NEXTWEEK"), handler: async () =>{
        this.followUpModal(goal, time, 7);
      }
    });
    buttons.push({
      text: this.translateService.instant("NEXTYEAR"), handler: async () =>{
        this.followUpModal(goal, time, 365);
      }
    });
    buttons.push({
      text: this.translateService.instant("MANUALSELECT"), handler: () =>{
        this.navController.navigateRoot("add-action", {queryParams: {goal: goal, immediateAccept: false, prevActionTime: time}});
      }
    });

    this.globalService.ionicAlert(
      buttons,
      "WHENFOLLOWUP"
    );
  }

  public async followUpModal(goal, time, followupDate){
    const modal = await this.modalController.create({
      component: AddActionPage,
      cssClass: "hiddenContent",
      componentProps: {
        followUpData: {
          goal: goal,
          immediateAccept: true,
          prevActionTime: time,
          followUpDate: followupDate
        }
      },
    });
    
    modal.onDidDismiss().then((data) => {
      if(data.data.dismissed == true){
        ContactService.RefreshContactActions = true;
        ActionService.RefreshActionPage = true;
        ActionService.RefreshDashboardPage = true;
        ActionpathService.RefreshActionPathActions = true;
      }
    });

    await modal.present();
  }

  public async pickGoalType(actionId, prevDueDate?){
    GlobalService.IsLoading = true;
    let allGoals = await this.actionpathService.getAllactionpaths();
    let goalHolder = [];
    for(let goal of allGoals) if(goal.status != "CLOSED" && goal.status != "INACTIVE") goalHolder.push(goal);
    console.log("goal holder content", goalHolder);
    GlobalService.IsLoading = false;
    if(goalHolder.length > 0){
      let buttons = [];
      buttons.push({
        text: this.translateService.instant("YES"),
        cssClass: 'delete-yes first-yes-no',
        handler: async () => {
          if(goalHolder && goalHolder.length == 1){
            ActionService.SelectedGoals = [{"id_action_path": goalHolder[0].id_action_path}];
            await this.alertUpdateAction(actionId);
            await this.createFollowUp(actionId, undefined, prevDueDate);
            return;
          } else{
            this.selectGoalType(actionId, prevDueDate);
            return;
          }
        }
      });
      buttons.push({
        text: this.translateService.instant("NO"),
        cssClass: 'delete-no second-yes-no',
        handler: async () => {
          let action = await this.getActionPure(actionId);
          if(action.actionpaths && action.actionpaths.length > 0){
            await this.createFollowUp(actionId, action.actionpaths[0].id_action_path, prevDueDate);
          } else {
            await this.createFollowUp(actionId, undefined, prevDueDate);
          }
          return;
        }
      });

      this.globalService.ionicAlert(
        buttons,
        "ADDTOUCHPOINTTOGOAL"
      );
    } else {
      await this.alertUpdateAction(actionId);
      await this.createFollowUp(actionId, undefined, prevDueDate);
      return;
    }
  }

  private async selectGoalType(actionId, prevDueDate?){
    GlobalService.IsLoading = true;
    console.log("action should be an action id", actionId);

    let buttons = [];
    buttons.push({
      text: this.translateService.instant("ADDACTIONPATH"),
      cssClass: "optionButton",
      handler: async () => {
        ActionpathService.FromAction = true;
        let selected = await this.openNewGoalsPage();
        console.log(selected);
        if(selected.dismissed){     
          await this.alertUpdateAction(actionId);
          await this.createFollowUp(actionId, undefined, prevDueDate);
          GlobalService.IsLoading = false;
        }
      }
    });
    buttons.push({
      text: this.translateService.instant("SELECTGOALS"),
      cssClass: "optionButton",
      handler: async () => {
        let selected = await this.selectGoals();
        console.log(selected);
        if(selected.dismissed){    
          await this.alertUpdateAction(actionId);
          await this.createFollowUp(actionId, undefined, prevDueDate);
          GlobalService.IsLoading = false;
        } else {
          GlobalService.IsLoading = false;
        }
      }
    });
    buttons.push({
      text: this.translateService.instant("CANCEL"),
      cssClass: "cancelButton",
      handler: async () => {
        console.log("action should be here", actionId);
        await this.createFollowUp(actionId, undefined, prevDueDate);
      }
    });

    this.globalService.ionicAlert(
      buttons,
      this.translateService.instant("ACTION") + " " + this.translateService.instant("GOAL").toLowerCase()
    );
  }

  public async selectGoals(){
    ActionService.SelectedGoals = [];
    let safelist = [];

    if(ActionService.SelectedGoals && ActionService.SelectedGoals.length > 0){
      ActionService.SelectedGoals.forEach(element => {
        safelist.push(element);
      });
    }
    
    const modal = await this.modalController.create({
      component: SelectGoalsPage,
      componentProps: {
        selectedGoals: ActionService.SelectedGoals,
      },
    });

    modal.present();

    return modal.onDidDismiss().then((data) => {
      if(data.data.dismissed == true){
        ActionService.SelectedGoals = data.data.goals;
      } else {
        ActionService.SelectedGoals = safelist;
      }

      return data.data
    });
  }

  private async openNewGoalsPage(): Promise<any> {
    ActionpathService.FromAction = true;
    const modal = await this.modalController.create({
      component: AddactionpathPage,
    });

    modal.cssClass = "fullscreen-modal";
    

    await modal.present();

    return modal.onDidDismiss().then((data) => {
      if(data.data.dismissed == true){
        ActionService.SelectedGoals = [data.data.goal && data.data.goal];
      } else {
        ActionService.SelectedGoals = [];
      }

      return data.data
    });
  }

  public async alertUpdateAction(actionId) {
    console.log("triggers alertUpdateAction", actionId);
    if(ActionService.SelectedGoals && ActionService.SelectedGoals.length > 0){
      GlobalService.IsLoading = true;
      let fullAction = await this.getActionPure(actionId);
      fullAction["actionpaths"] = [];
      for(let goal of ActionService.SelectedGoals){
        fullAction.actionpaths.push({"id_action_path": goal.id_action_path});
        fullAction.actionpaths = [...new Set(fullAction.actionpaths)];
      }
      let res = await this.updateAction(fullAction);
      if(ActionService.SelectedGoals && ActionService.SelectedGoals.length > 0){
        await this.fixActionActionPaths(fullAction);
      }
      ActionpathService.RefreshDashboardGoals = true;
      GlobalService.IsLoading = false;
      return res;
    }
  }

  async fixActionActionPaths(fullAction){
    for(let goal of ActionService.SelectedGoals){
      let tempPath: actionpath = await this.actionpathService.getactionpath(goal.id_action_path);
      let tempActions = [];
      if(!tempPath.actions) tempPath.actions = [];
      if(tempPath.actions){
        tempPath.actions.forEach(tempAction => {
          tempActions.push(tempAction.id_action);
        });
      }
      tempActions.push(fullAction.id_action);
      if(fullAction.id_previous_action || fullAction.id_follow_up_action){
        let sequenceList = await this.prepareSequence(fullAction.id_action);
        sequenceList.forEach(actionInSequence => {
          tempActions.push(actionInSequence);
        });
      }

      tempPath.actions = [...new Set(tempActions)];
      let finalPath = await this.actionpathService.updateactionpath(tempPath);
      await this.actionpathService.reloadPage();
    }
  }

  public async didActionSucceed(actionId){
    GlobalService.IsLoading = true;

    let buttons = [];
    buttons.push({
      text: this.translateService.instant("YES"),
      cssClass: 'delete-yes first-yes-no',
      handler: async () => {
        // this.startNewActionSequence();
        await this.serviceCompleteTask({"id_action": actionId}, undefined, 4, -1);
        GlobalService.IsLoading = false;
        return;
      }
    });
    buttons.push({
      text: this.translateService.instant("NO"),
      cssClass: 'delete-no second-yes-no',
      handler: async () => {
        await this.serviceCompleteTask({"id_action": actionId}, undefined, 3, -1);
        GlobalService.IsLoading = false;
        return;
      }
    });
    
    this.globalService.ionicAlert(
      buttons,
      "DIDACTIONSUCCEED"
    );
  }

  
  public async rescheduleTask(action, givenTime){
    GlobalService.log("reschedule task: ", action);
    GlobalService.log("given time: ", givenTime.toString());
    if(action){
      if(typeof action == "number") action = await this.getAction(action);
      let useAction = await this.getActionPure(action.id_action);
      GlobalService.log("pure action: ", action);
      GlobalService.IsLoading = true;
      
      if(useAction.action_notifications){
        for(let notification of useAction.action_notifications){
          console.log("This notification is deleted", notification);
          await this.deleteNotification(notification)
        }
        useAction.action_notifications = undefined;
      }

      //Set duedate.
      let baseDate = new Date(givenTime);
      GlobalService.log("what is baseDate", baseDate.toString());
      GlobalService.log("what is baseDate time", baseDate.getTime());
      useAction.due = baseDate.getTime().toString();

      this.removeActionFromList(action);
      let res = await this.updateAction(useAction);
      await this.finishActionUpdate(res, useAction, []);

      let updatedAction: Action = res;
      ActionService.Action = await this.getActionPure(updatedAction.id_action);
      GlobalService.IsLoading = false;
    }
    console.log("action finished", ActionService.actionGroups);
  }

  public async assignUserToAction(action){
    GlobalService.log("assign user to action: ", action);
    if(action){
      GlobalService.IsLoading = true;
      
      this.removeActionFromList(action);
      let useAction = await this.getActionPure(action.id_action);
      useAction.assigned_user = action.assigned_user;
      let res = await this.updateAction(useAction);
      await this.finishActionUpdate(res, useAction, []);

      let updatedAction: Action = res;
      ActionService.Action = await this.getActionPure(updatedAction.id_action);
      GlobalService.IsLoading = false;
    }
  }
}

interface ActionGroup {
  name: string;
  actions: Action[];
}