import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import Contact from 'src/app/models/contact';
import { Router } from '@angular/router';
import { ContactService } from 'src/app/services/contact.service';
import { NavController } from '@ionic/angular';
import { ContactAvatarCached } from 'src/app/models/contact-avatar-cache';
import { AvatarLoaderService } from 'src/app/services/avatar-loader.service';
import { GlobalService } from 'src/app/services/global.service';
import { AuthService } from 'src/app/services/auth.service';
import { AdministrationService } from 'src/app/services/administration.service';

@Component({
  selector: 'app-contact-item',
  templateUrl: './contact-item.component.html',
  styleUrls: ['./contact-item.component.scss'],
})
export class ContactItemComponent implements OnInit {

  @Input() public contact: Contact;
  @Input() public clickable: boolean = true;
  @Input() public shouldScroll: boolean = false;
  @Input() public arrow: boolean = false;
  @Input() public lines: boolean = true;
  @Input() public favouriteButton: boolean = false;
  @Input() public importing: boolean = false;
  @Output() onFavourite: EventEmitter<any> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  public contactDisplayName = "";
  public hideMe: boolean = false;

  public avatar: ContactAvatarCached = {id_contact: undefined, avatar: undefined, last_modified: undefined};

  constructor(
    private contactService: ContactService,
    private router: Router,
    private navController: NavController,
    private avatarLoader: AvatarLoaderService,
    private administrationService: AdministrationService
  ) { }

  ngOnInit() {
    this.getAvatar();
    this.contactDisplayName = ContactService.setDisplayName(this.contact, 1);

    if(this.contact.last_name == this.contact.first_name) this.contact.last_name
  }

  public async initializeHideMe(){
    if(!AdministrationService.UserData)
      await this.administrationService.getFullUserData();

    if(!GlobalService.DebugMode && AuthService.User && this.contact && this.contact.id_user
    && AuthService.User.id_user != this.contact.id_user
    && !AdministrationService.UserData.administration){
      this.hideMe = true;
    }
  }

  public async getAvatar () {
    if (!this.contact || !this.contact.has_avatar) return;

    if(this.importing == true){
      this.avatar.avatar = this.contact.avatar;
    } else {
      if(this.contact.avatar == undefined){
        if(this.contact.id_contact != undefined){
          this.contact.loadingAvatar = true;
          this.avatarLoader.addToQueue(this.contact);
          this.avatar = await this.avatarLoader.getAvatar(this.contact);
          this.contact.loadingAvatar = false;
        }
      } else {
        this.avatar.avatar = this.contact.avatar;
      }
    }
  }

  public openContactDetails() {
    if (!this.clickable || !this.contact.id_contact) return;

    this.onClick.emit();

    ContactService.SetCurrentContact(this.contact, this.shouldScroll);
    this.navController.navigateRoot("contact-details");
  }

  public setFavourite() {
    let isFavourite = this.contact.favourite;
    let favouriteStatus = +(!isFavourite); // Negate boolean and convert to number

    this.contact.favourite = favouriteStatus;

    this.contactService.setFavourite(this.contact, favouriteStatus).then(res => {
      this.onFavourite.emit();
    });
  }

  public getSkeletonEnabled(){
    return GlobalService.DisplaySkeleton;
  }
}
