import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { ContactGroupItemComponent } from './contact-group-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [ 
    CommonModule, 
    FormsModule, 
    IonicModule, 
    TranslateModule, 
    NgxSkeletonLoaderModule,
  ],
  declarations: [ContactGroupItemComponent],
  exports: [ContactGroupItemComponent, TranslateModule]
})
export class ContactGroupItemComponentModule {}
