<!-- Skeleton View -->
<ion-card *ngIf="!actionpath && !hideMe && getSkeletonEnabled()" [ngClass]="{'page-element-center': elementCenter}">
  <ion-card-content style="padding-inline-start: 10px;">
    <ion-item class="item goal-item" [lines]="lines ? 'full' : 'none'">
      <ion-grid class="ion-no-padding goal-grid">
        <ion-row style="margin-top: -10px; margin-left: -5px;">
          <ion-col class="goal-avatar" size="2">
            <ion-avatar class="item-avatar no-avatar" style="background-color: transparent">
              <ngx-skeleton-loader class="actionpath-circle-override" appearance="circle">
              </ngx-skeleton-loader>
            </ion-avatar>
          </ion-col>
          <ion-col style="position: absolute; padding-left: 90px;">
            <ion-row style="height: 15px; margin-top: 4px; margin-bottom: 8px;">
              <ion-text>
                <ngx-skeleton-loader [theme]="{'width': '30vw'}">
                </ngx-skeleton-loader>
              </ion-text>
            </ion-row>
            <ion-row style="height: 15px;">
              <ion-text>
                <ngx-skeleton-loader [theme]="{'width': '50vw'}">
                </ngx-skeleton-loader>
              </ion-text>
            </ion-row>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-card-content>
</ion-card>

<ion-card *ngIf="actionpath && !hideMe" [ngClass]="{'page-element-center': elementCenter}">
  <ion-card-content style="padding-inline-start: 10px;">
    <!-- Real Data -->
    <ion-item class="item goal-item" [lines]="lines ? 'full' : 'none'" (click)="openActionPathDetails()">
      <ion-grid class="ion-no-padding goal-grid">
        <ion-row>
          <ion-col class="goal-avatar" size="2">
            <ion-avatar *ngIf="!actionpath.contacts || actionpath.contacts.length == 0"  class="item-avatar no-avatar">
              <ion-icon class="ai-flaticon-document fi"></ion-icon>
            </ion-avatar>
            <div *ngIf="actionpath.contacts && actionpath.contacts.length > 0">
              <ion-avatar *ngIf="!actionpath.contacts[0].avatar || actionpath.contacts[0].avatar.includes('contacts.svg')" class="item-avatar no-avatar">
                <ion-icon [src]="'assets/icon/groups.svg'"></ion-icon>
              </ion-avatar>
              <ion-avatar *ngIf="actionpath.contacts[0].avatar && !actionpath.contacts[0].avatar.includes('contacts.svg')" class="item-avatar">
                <ion-img [src]="actionpath.contacts[0].avatar"></ion-img>
              </ion-avatar>
            </div>
          </ion-col>
          <ion-col>
            <div *ngIf="isOverdue() || actionpath.priority" class="priority-indicator">
              <ion-text *ngIf="!isOverdue() && actionpath.priority" style="font-size: 36px;">!</ion-text>
              <ion-text *ngIf="isOverdue() && !actionpath.priority" style="font-size: 36px;">!</ion-text>
              <ion-text *ngIf="isOverdue() && actionpath.priority" style="font-size: 36px; font-weight: bold">!</ion-text>
            </div>
            <ion-row class="goal-title-row">
              <ion-text>{{ actionpath.title }}</ion-text>
            </ion-row>
            <ion-row>
              <ion-text>{{ actionpath.result }}</ion-text>
            </ion-row>
            <ion-text *ngIf="!actionpath.finaldate && (actionpath.contacts && actionpath.contacts.length > 0)" color="medium">{{getDisplayName(actionpath.contacts[0]).trim()}}</ion-text>
            <ion-text *ngIf="!actionpath.finaldate && (!actionpath.contacts || actionpath.contacts.length == 0)" color="medium">{{'PERSONAL' | translate}}</ion-text>
            <ion-text *ngIf="actionpath.finaldate" color="medium">{{prettyDate()}}</ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-item>
  </ion-card-content>
</ion-card>