import { Injectable } from "@angular/core";
import Note from "../models/note";
import { CrudService } from "./crud.service";
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from "./auth.service";
import { GlobalService } from "./global.service";
import action from "../models/action";
import Contact from "src/app/models/contact";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})

export class NoteService extends CrudService {

  private static note: Note;
  private static isEditing: boolean;
  private static personalNotes: Note[];
  private static contactNotes: Note[];
  private static favouriteNotes: Note[];
  private static curPage: number = 1;
  private static goToAction: action;
  private static currentContact: Contact;

  public static set CurrentContact(value) {
    this.currentContact = value;
  }

  public static get CurrentContact() {
    return this.currentContact;
  }
  public static SetCurPage(page) {
    this.curPage = page;
  }

  public static get CurPage() {
    return this.curPage;
  }

  public static get Note() {
    return this.note;
  }

  public static SetCurrentNote(note: Note) {
    this.note = note;
  }

  public static SetIsEditing(isEditing: boolean) {
    this.isEditing = isEditing;
  }


  public static get FavouriteNotes () {
    return this.favouriteNotes;
  }

  public static SetFavouriteNotes (notes: Note[]) {
    this.favouriteNotes = notes;
  }

  public static PushFavouriteNotes (notes: Note[]) {
    notes.forEach(note => {
      this.favouriteNotes.push(note);
    });
  }

  public static get PersonalNotes () {
    return this.personalNotes;
  }

  public static get ContactNotes () {
    return this.contactNotes;
  }

  public static SetPersonalNotes (notes: Note[]) {
    this.personalNotes = notes;
  }

  public static SetContactNotes (notes: Note[]) {
    this.contactNotes = notes;
  }

  public static get IsEditing() {
    return this.isEditing;
  }

  public static set GoToAction(action_id) {
    this.goToAction = action_id;
  }

  public static get GoToAction() {
    return this.goToAction;
  }

  constructor(
    public httpClient: HttpClient,
    public storageService: StorageService,
    public navController: NavController,
    public translateService: TranslateService,
    public alertController: AlertController,
    public authService: AuthService,
    public globalService: GlobalService
  ) {
    super(httpClient, navController, translateService, alertController, globalService, storageService, authService);
}

  public getAllNotes(): Promise<Array<Note>> {
    console.log("Getting notes...");

    return this.read("/notes/list").then((res) => {
      console.log(res);
      let noteList = res;
      noteList = noteList.sort((b, a) => (new Date(a.created) > new Date(b.created)) ? 1 : -1);
      return noteList as Note[];
    });
  }

  public getPersonalNotes(): Promise<Array<Note>> {
    console.log("Getting notes...");

    return this.read("/notes/personal/list").then((res) => {
      console.log(res);
      let noteList = res;
      noteList = noteList.sort((b, a) => (new Date(a.created) > new Date(b.created)) ? 1 : -1);
      noteList = noteList.filter(note => !note.id_action);
      noteList = noteList.filter(note => !note.id_contact);
      noteList = noteList.filter(note => !note.id_touchpoint);
      NoteService.SetPersonalNotes(noteList as Note[]);
      return noteList as Note[];
    });
  }

  public getContactNotes(): Promise<Array<Note>> {
    console.log("Getting notes...");

    return this.read("/notes/contact/list").then((res) => {
      console.log(res);
      let noteList = res.sort((b, a) => (new Date(a.created) > new Date(b.created)) ? 1 : -1);
      noteList = noteList.filter(note => note.id_contact);
      NoteService.SetContactNotes(noteList as Note[]);
      return noteList as Note[];
    });
  }

  removeNoteFromList(note: Note): void {
    if(NoteService.contactNotes != undefined) { NoteService.SetContactNotes(NoteService.contactNotes.filter(({ id_notes }) => id_notes !== note.id_notes)); }
    if(NoteService.personalNotes != undefined) { NoteService.SetPersonalNotes(NoteService.personalNotes.filter(({ id_notes }) => id_notes !== note.id_notes)); }
  }
  
  public static PushPersonalNote (note: Note) {
    if(this.personalNotes != undefined){
      this.personalNotes.push(note);
      this.personalNotes = this.personalNotes.sort((b, a) => (new Date(a.created) > new Date(b.created)) ? 1 : -1);
    }
  }
  
  public static PushContactNote (note: Note) {
    if(this.contactNotes != undefined){
      this.contactNotes.push(note);
      this.contactNotes = this.contactNotes.sort((b, a) => (new Date(a.created) > new Date(b.created)) ? 1 : -1);
    }
  }

  public async createNote (note: Note) : Promise<Response> {
    console.log("this is the note");
    if(note.id_user == undefined){
      note.id_user = (await this.authService.getUserObj()).id_user;
    }
    console.log(note);
    
    return this.post("/user/notes/new",note);
  }

  public updateNote (note: Note) : Promise<Response> {
    NoteService.isEditing = false;
    
    NoteService.note = note;
    NoteService.note.id_notes = note.id_notes;

    console.log(note);

    return this.put("/user/notes/update",note);
  }

  public static unsetNote(){
    NoteService.note = undefined;
  }

  public deleteNote (note: Note) {
    console.log(note);
    this.removeNoteFromList(note);
    NoteService.unsetNote();
    return this.delete("/notes/delete/" + note.id_notes);
  }

  public setFavourite(
    id: string,
    type: string,
    typeId: number,
    favourite: number,
    note: Note
  ): Promise<Response> {
    let noteObj = {...note};
    noteObj.favourite = favourite;

    if(NoteService.favouriteNotes){
      if(noteObj.favourite == 1){
        NoteService.favouriteNotes.push(noteObj);
      } else {
        NoteService.favouriteNotes = NoteService.favouriteNotes.filter(({ id_notes }) => id_notes !== noteObj.id_notes);
      }
      NoteService.favouriteNotes = NoteService.favouriteNotes.sort((b, a) => (new Date(a.created) > new Date(b.created)) ? 1 : -1);
    }

    switch (type) {
      case "action":
        return this.patch(`/action/${typeId}/notes/update`, noteObj);
      case "contact":
        return this.patch(`/contact/${typeId}/notes/update`, noteObj);
      case "touchpoint":
        return this.patch(`/touchpoint/${typeId}/notes/update`, noteObj);
      case "personal":
        return this.patch(`/user/notes/updatefav`, noteObj);
    }
  }

  public async deleteAllNotes(){
    let allNotes = await this.getAllNotes();

    let getIDs = [];
    
    allNotes.forEach(note => {
      if(note.id_notes){
        getIDs.push({"id_notes": note.id_notes});
      }
    });

    if(allNotes && allNotes.length > 0){
      let deleteAll = this.deleteNotesCollection(getIDs);

      return deleteAll;
    }
  }
  
  public async deleteNotesCollection(noteList: Note[]){
    console.log("delete notes");
    console.log(noteList);
    let sleepTimer = 0;
    let deleteList = [];

    while(noteList.length > 0){
      if(noteList.length < 10 && noteList.length != 0){
        noteList.forEach(contact => {
          this.removeNoteFromList(contact);
        });
        await this.post(`/notes/delete/collection`,noteList);
        noteList = [];
      }

      deleteList.push(noteList[0]);
      this.removeNoteFromList(noteList[0]);
      noteList.shift(); 

      if(sleepTimer == 10){
        await this.post(`/contact/delete/collection`,deleteList);
        deleteList = [];
        sleepTimer = 0;
      }

      sleepTimer++;
    }
    
    return;
  }

  public static resetLocalLists (){
    NoteService.personalNotes = [];
    NoteService.contactNotes = [];
    NoteService.favouriteNotes = [];
  }
}