import { Injectable } from "@angular/core";
import { CrudService } from "./crud.service";
import Notification from "../models/notification";
import { NavController, Platform, AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LocalNotificationPendingList, LocalNotificationRequest, LocalNotifications} from '@capacitor/local-notifications'
import { GlobalService } from "./global.service";
import { Storage } from "@ionic/storage-angular";
import { AuthService } from "./auth.service";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends CrudService {

  constructor(
    public httpClient: HttpClient,
    public storageService: StorageService,
    public navController: NavController,
    public translateService: TranslateService,
    private platform: Platform,
    public alertController: AlertController,
    public globalService: GlobalService,
    public authService: AuthService
  ) {
    super(httpClient, navController, translateService, alertController, globalService, storageService, authService);
  }

  public getNotification (id) : Promise<Notification> {
    return this.read(`/notification/${id}`).then(res => {
      return res as Notification;
    });
  }

  public async scheduleNotification(title, body, action){
    if (this.platform.is('cordova')) {
      console.log("Cordova check passed");
      let checkPermission = await LocalNotifications.checkPermissions();
      if(checkPermission.display != "granted"){
        GlobalService.log("Does app have permission for notifications?", checkPermission);
        await LocalNotifications.requestPermissions();
      }
        
      let id_notification: number = body.id_notification;
      if(!body.msg || body.msg == "undefined") body.msg = "";

      let notificationObject = { notifications: [{
        id: id_notification,
        schedule: {at: new Date(body.time)},
        title: title,
        body: "",
        largeBody: "",
        extra: {
          action: {"id_action": action.id_action, "fromNotification": true},
        },
        smallIcon: "icon"
      }]};

      if(body.msg.includes("BIGMESSAGE")){
        notificationObject.notifications[0].body = body.msg.split(", BIGMESSAGE")[0];
        notificationObject.notifications[0].largeBody = body.msg.split(", BIGMESSAGE")[0] + body.msg.split(", BIGMESSAGE")[1];  
      } else {
        notificationObject.notifications[0].body = body.msg;
        notificationObject.notifications[0].largeBody = body.msg;
      }

      GlobalService.log("schedule notification", notificationObject);

      LocalNotifications.schedule(notificationObject);
    } else {
      console.log("schedule notification", title, body, new Date(body.time));
      console.log("is not cordova");
    }
  }

  public async deleteNotification(notification){
    let res = await LocalNotifications.getPending()
    console.log("", res);
    res.notifications = await res.notifications.filter(i => i.id == notification.id_notification);
    if(res.notifications.length > 0) await LocalNotifications.cancel(res);
  }
}
