import { EventEmitter } from "@angular/core";
import { Injectable, Inject } from '@angular/core';
import { CrudService } from './crud.service';
import Contact from '../models/contact';
import { ContactGroup } from '../models/contact-group';
import Note from '../models/note';
import Touchpoint from '../models/touchpoint';
import Action from '../models/action';
import { TranslateService } from '@ngx-translate/core';
import { NavController, AlertController } from '@ionic/angular';
import actionpath from 'src/app/models/actionpath';
import { Storage } from "@ionic/storage-angular";
import { ContactAvatar } from '../models/contact-avatar';
import { ContactAvatarCached } from '../models/contact-avatar-cache';
import { DOCUMENT } from '@angular/common';
import Pagination from '../models/pagination';
import { AuthService } from './auth.service';
import { GlobalService } from "./global.service";
import { environment } from "src/environments/environment";
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import contact from "../models/contact";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ActionService } from "./action.service";
import { SubscriptionService} from "src/app/services/subscription.service";
import { ContactTransferService } from "./contact-transfer.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: 'root'
})
export class ContactService extends CrudService {

  public onSync: EventEmitter<any> = new EventEmitter();

  private static contact: Contact;
  private static searchedContacts: Contact[];
  private static group: ContactGroup;
  private static isEditing: boolean;
  private static isEditingGroup: boolean;
  private static hasLoaded: boolean = false;
  private static shouldScroll: boolean = false;
  private static pageNumber = 0;
  private static localList: Contact[] = [];
  private static localGroupList: ContactGroup[] = [];
  private static sortedLocalList: Contact[] = [];
  private syncDate: Date;
  private static hasInitialisedSync: boolean = false;
  private lastSortMethod;
  private lastSortDirection;
  private hasSortedSinceLastSync: boolean = false;
  private static startSync = false;
  private static dashboardContactList: any[] = []; //This holds the contacts on the dashboard page
  private static contactsPageContactList: any[] = []; //This holds the contacts on the contacts page
  private static contactsPageFavouriteContactList: any[] = []; //This holds the favourite contacts on the contacts page
  private static hasDeletedContact: boolean = false; //Set to true if a contact was deleted so it won't attempt to scroll to it
  private static contactDetailPageTab: number = 0;
  private static importList: Contact[] = [];
  private static loadingContacts = false;
  private static justImportedSingle = false;
  private static refreshContactActions: boolean = false;

  private emailTypeList: String[] =[
    "GENERAL",
    "PERSONAL",
    "WORK"
  ];

  private phoneTypeList: String[] = [
    "MOBILE",
    "PERSONAL",
    "WORK"
  ];

  constructor(
    public httpClient: HttpClient,
    public navController: NavController,
    public translateService: TranslateService,
    public alertController: AlertController,
    public authService: AuthService,
    public fileOpener: FileOpener,
    public subscriptionService: SubscriptionService,
    public globalService: GlobalService,
    public contactTransferService: ContactTransferService,
    public storageService: StorageService,
    @Inject(DOCUMENT) private document: HTMLDocument
  ) {
    super(httpClient, navController, translateService, alertController, globalService, storageService, authService);
  }

  public static get RefreshContactActions () {
    return this.refreshContactActions;
  }

  public static set RefreshContactActions (value) {
    this.refreshContactActions = value;
  }

  public static get JustImportedSingle () {
    return this.justImportedSingle;
  }

  public static set JustImportedSingle (value) {
    this.justImportedSingle = value;
  }

  public static get LoadingContacts () {
    return this.loadingContacts;
  }

  public static set LoadingContacts (value) {
    this.loadingContacts = value;
  }

  public static get StartSync () {
    return this.startSync;
  }

  public static get PageNumber () {
    return this.pageNumber;
  }

  public get LocalList () {
    return ContactService.localList;
  }

  public static get Contact () {
    return this.contact;
  }

  public static set Contact (newContact) {
    this.contact = newContact;
  }
  
  public static get SortedLocalList () {
    return this.sortedLocalList;
  }
  
  public static get SearchedContacts () {
    return this.searchedContacts;
  }

  public static get HasLoadedContacts () {
    return this.hasLoaded;
  }

  public static get ShouldScroll () {
    return this.shouldScroll;
  }

  public static get ContactsPageContactList () {
    return this.contactsPageContactList;
  }

  public static get ContactsPageFavouriteContactList () {
    return this.contactsPageFavouriteContactList;
  }

  public static get HasDeletedContact () {
    return this.hasDeletedContact;
  }

  public static get DashboardContactList () {
    return this.dashboardContactList;
  }

  public static get ContactDetailPageTab () {
    return this.contactDetailPageTab;
  }

  public static get LocalGroupList () {
    return this.localGroupList;
  }

  public static get ImportList () {
    return this.importList;
  }

  public static set ImportList (groupList) {
    this.importList = groupList;
  }

  public static set LocalGroupList (groupList) {
    this.localGroupList = groupList;
  }

  public static set ContactDetailPageTab (detailPageTab) {
    this.contactDetailPageTab = detailPageTab;
  }

  public static set DashboardContactList (paginatedList) {
    this.dashboardContactList = paginatedList;
  }

  public static set HasDeletedContact (result) {
    this.hasDeletedContact = result;
  }

  public static set ContactsPageContactList (paginatedList) {
    this.contactsPageContactList = paginatedList;
  }

  public static set ContactsPageFavouriteContactList (paginatedList) {
    this.contactsPageFavouriteContactList = paginatedList;
  }
  
  public static set PageNumber (newNumber) {
    this.pageNumber = newNumber;
  }

  public static set HasLoadedContacts (hasLoaded: boolean) {
    this.hasLoaded = hasLoaded;
  }
  
  public static set ShouldScroll (val: boolean) {
    console.log(`%c Setting should scroll ${val}`, 'color: red' );

    this.shouldScroll = val;
  }

  public static SetCurrentContact (contact: Contact, shouldScroll: boolean = false) {
    this.contact = contact;
    this.ShouldScroll = shouldScroll;
  }

  public static SetShouldScroll (shouldScroll: boolean) {
    this.ShouldScroll = shouldScroll;
  }

  public static SetSearchedContacts (contacts: Contact[]) {
    this.searchedContacts = [...contacts].sort(
      (a, b) => a.first_name?.localeCompare(b.first_name)
    );
  }

  public async PushContact (contact: Contact) {
    contact.display_name = ContactService.setDisplayName(contact);
    console.log("pushed contact", contact);

    if(ContactService.localList && ContactService.startSync){
      ContactService.localList.push(contact);
    }

    if(ContactService.contactsPageContactList && ContactService.startSync){
      ContactService.contactsPageContactList.push(contact);
    }

    if(ContactService.dashboardContactList){
      ContactService.dashboardContactList.unshift(contact);
    }
    
    this.hasSortedSinceLastSync = false;
    await this.sortLocalList();

    this.storageService.setStorage(`localContacts_${(await this.authService.getUserObj()).id_user}`, ContactService.localList, false);
    this.storageService.setStorage(`contactSyncDate_${(await this.authService.getUserObj()).id_user}`, this.syncDate);
  }

  public async RemoveContactFromList (contact: Contact) {
    ContactService.contact = undefined;
    if(ContactService.localList){
      ContactService.localList = ContactService.localList.filter(({ id_contact }) => id_contact !== contact.id_contact);
    }
    if(ContactService.dashboardContactList){
      ContactService.dashboardContactList = ContactService.dashboardContactList.filter(({ id_contact }) => id_contact !== contact.id_contact);
    }
    if(ContactService.contactsPageContactList){
      ContactService.contactsPageContactList = ContactService.contactsPageContactList.filter(({ id_contact }) => id_contact !== contact.id_contact);
    }
    if(ContactService.contactsPageFavouriteContactList){
      ContactService.contactsPageFavouriteContactList = ContactService.contactsPageFavouriteContactList.filter(({ id_contact }) => id_contact !== contact.id_contact);
    }
    if(ContactService.sortedLocalList){
      ContactService.sortedLocalList = ContactService.sortedLocalList.filter(({ id_contact }) => id_contact !== contact.id_contact);
    }

    this.storageService.setStorage(`localContacts_${(await this.authService.getUserObj()).id_user}`, ContactService.localList, false);
    this.storageService.setStorage(`contactSyncDate_${(await this.authService.getUserObj()).id_user}`, this.syncDate);
  }
  
  public PushContacts (contacts: Contact[]){
    if(contacts != undefined){
      contacts.forEach(contact => {
        this.PushContact(contact)
      });
    }
  }
  
  public PushContactGroup (contactGroup: ContactGroup) {
    if(ContactService.LocalGroupList){
      ContactService.LocalGroupList.push(contactGroup);
      
      ContactService.LocalGroupList = [...ContactService.LocalGroupList].sort(
        (a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0)
      );
    }
  }

  public RemoveContactGroupFromList (contactGroup: ContactGroup) {
    if(ContactService.LocalGroupList){
      ContactService.LocalGroupList = ContactService.LocalGroupList.filter(({ id_contactgroup }) => id_contactgroup !== contactGroup.id_contactgroup);
    }
  }

  public static get ContactGroup () {
    return this.group;
  }

  public static set ContactGroup (group: ContactGroup) {
    this.group = group;
  }

  public static SetIsEditing (isEditing: boolean) {
    this.isEditing = isEditing;
  }

  public static get IsEditing () {
    return this.isEditing;
  }

  public static SetIsEditingGroup (isEditing: boolean) {
    this.isEditingGroup = isEditing;
  }

  public static get IsEditingGroup () {
    return this.isEditingGroup;
  }

  public static get HasInitializedSync () {
    return this.hasInitialisedSync;
  }

  public async setupSync () {
    if(!ContactService.startSync){
      ContactService.startSync = true;
      GlobalService.log("setup sync has triggered");
  
      if((await this.authService.getUserObj()).id_user){
        let contacts = await this.storageService.getStorage(`localContacts_${(await this.authService.getUserObj()).id_user}`);
    
        if (contacts && contacts.length > 0){
          ContactService.localList = contacts as Contact[];
        } 
    
        let syncDate = await this.storageService.getStorage(`contactSyncDate_${(await this.authService.getUserObj()).id_user}`);
    
        if (!syncDate && (ContactService.localList && ContactService.localList.length > 0)){
          GlobalService.log("No sync date available...");
          this.syncDate = new Date();
    
          this.storageService.setStorage(`contactSyncDate_${(await this.authService.getUserObj()).id_user}`, this.syncDate.toISOString());
        } else {
          this.syncDate = new Date(syncDate);
        }
    
        ContactService.hasInitialisedSync = true;
  
        GlobalService.log("Syncing with date", this.syncDate.toISOString());
        return true;
      }
    }
    return true;
  }

  private sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public static stopSync () {
    ContactService.startSync = false;
    ContactService.hasInitialisedSync = false;
  }

  public async syncContacts () : Promise<Array<Contact>> {
    let repeaterController = 0;    
    while (!ContactService.hasInitialisedSync) {
      if(!ContactService.startSync){
        this.setupSync();
      }
    
      if(GlobalService.DebugMode) console.log("Waiting for sync to be initialised, try again if reaching 50");

      await this.sleep(100);

      repeaterController++;

      if(repeaterController == 50){
        ContactService.startSync = false;
        repeaterController = 0;
      }
    }

    console.log("Started syncing contacts...", this.syncDate);

    let res = await this.read("/contact/minilist/sync?date=" + this.syncDate.toISOString())
    
    if(res && res.length > 0){
      let contacts = res as Contact[];

      if (contacts && contacts.length) {
        console.log(`Got ${contacts.length} new changes`, contacts);

        await this.appendLocalList(contacts);
      }

      GlobalService.log(`Done syncing, acquired`, res);

      this.onSync.emit();

      this.hasSortedSinceLastSync = false;

      this.sortLocalList(this.lastSortMethod ?? "firstname", this.lastSortDirection ?? 1);

      return ContactService.localList;
    } else {
      GlobalService.log("no external changes");
      return undefined;
    }
  }

  private async appendLocalList (contacts: Contact[]) {
    if (!contacts || contacts.length == 0) return;

    for (let contact of contacts) {
      let index = ContactService.localList.findIndex(c => c.id_contact === contact.id_contact);

      if (index > -1) {
        ContactService.localList[index] = contact;
      } else {
        ContactService.localList.push(contact);
      }
    }

    await this.storageService.setStorage(`localContacts_${(await this.authService.getUserObj()).id_user}`, ContactService.localList, false);

    this.syncDate = new Date();

    await this.storageService.setStorage(`contactSyncDate_${(await this.authService.getUserObj()).id_user}`, this.syncDate);

    console.log(`Applied new changes. Sync date ${this.syncDate}`);
  } 

  public async sortLocalList (sortBy: string = "firstname", direction: number = 1) {
    if (this.lastSortMethod != sortBy || this.lastSortDirection != direction || !this.hasSortedSinceLastSync) {
      console.log("sort by local list triggered");
      ContactService.sortedLocalList = [...ContactService.localList]; 
      ContactService.sortedLocalList.forEach(contact => {
        if(!contact.first_name && sortBy == "firstname"){
          if(contact.last_name){
            contact["alteredData"] = {"type": "last_name", "value": contact.last_name, "original_type": "first_name"};
            contact.first_name = contact.last_name;
          } else if(contact.company) {
            contact["alteredData"] = {"type": "company", "value": contact.company, "original_type": "first_name"};
            contact.first_name = contact.company;
          } else if(contact.function) {
            contact["alteredData"] = {"type": "function", "value": contact.function, "original_type": "first_name"};
            contact.first_name = contact.function;
          }
        } else 
        if(!contact.last_name && sortBy == "lastname"){
          if(contact.first_name){
            contact["alteredData"] = {"type": "first_name", "value": contact.first_name, "original_type": "last_name"};
             contact.last_name = contact.first_name;
          } else if(contact.company) {
            contact["alteredData"] = {"type": "company", "value": contact.company, "original_type": "last_name"};
            contact.last_name = contact.company;
          } else if(contact.function) {
            contact["alteredData"] = {"type": "function", "value": contact.function, "original_type": "last_name"};
            contact.last_name = contact.function;
          }
        }
      });

      switch (sortBy) {
        case "firstname":
          ContactService.sortedLocalList.sort((a,b) => direction * a.first_name?.localeCompare(b.first_name));
          break;
        case "lastname":
          ContactService.sortedLocalList.sort((a,b) => direction * a.last_name?.localeCompare(b.last_name));
          break;
        case "company":
          ContactService.sortedLocalList.sort((a,b) => direction * a.company?.localeCompare(b.company));
          break;
        case "function":
          ContactService.sortedLocalList.sort((a,b) => direction * a.function?.localeCompare(b.function));
            break;
        case "modification":
          ContactService.sortedLocalList.sort((a,b) => direction * (new Date(a.modification).getTime() - new Date(b.modification).getTime()));
            break;
        case "lastinteraction":
          ContactService.sortedLocalList.sort((a,b) => direction * (new Date(a.last_interaction).getTime() - new Date(b.last_interaction).getTime()));
          break;
      }
      
      this.hasSortedSinceLastSync = true;
      this.lastSortMethod = sortBy;
      this.lastSortDirection = direction;
    }
  }

  public async getLocalListPaginated (page: number, size: number, sortBy: string = "firstname", direction: number = 1) : Promise<Pagination<Contact>> {
    GlobalService.log("Get local list paginated triggered, page: " + page + ", size: " + size + ", sortBy: " + sortBy + ", direction: " + direction);
    await this.sortLocalList(sortBy, direction);

    let pagination: Pagination<Contact> = {
      total_pages: Math.ceil(ContactService.sortedLocalList.length / size),
      number: page,
      first: page == 0,
      last: page * size + size >= ContactService.sortedLocalList.length,
      content: ContactService.sortedLocalList.slice(page * size, Math.min(page * size + size, ContactService.sortedLocalList.length))
    }

    console.log(pagination);

    return pagination;
  }

  public async getAllContacts (full: boolean = false) : Promise<Array<Contact>> {
    console.log("Getting contacts...");

    let url = full ? "/contact/list" : "/contact/minilist";

    let res = await this.read(url)
    let contacts = res as Contact[];

    let sortedContacts = [...contacts].sort(
      (a, b) => a.first_name?.localeCompare(b.first_name)
    );

    ContactService.SetSearchedContacts(sortedContacts);
    ContactService.HasLoadedContacts = true;

    console.log(sortedContacts.length + " contacts")

    return sortedContacts;
  }

  public async getAlphaContacts (letter: string) : Promise<Array<Contact>> {
    console.log("Getting contacts...");

    let res = await this.read("/contact/minilist")

    let contacts= res as Contact[];

    let sortedContacts = [...contacts].sort(
      (a, b) => a.first_name?.localeCompare(b.first_name)
    );

    console.log(sortedContacts);

    for(var i=sortedContacts.length-1 ; i>=0;i--){
      if(sortedContacts && sortedContacts[i] && sortedContacts[i].first_name){
        if(sortedContacts[i].first_name.charAt(0) != letter.toLowerCase() && sortedContacts[i].first_name.charAt(0) != letter.toUpperCase()){
          sortedContacts.splice(i, 1);
        }
      }
    }

    return sortedContacts;
  }

  public async getAlphaGroups(letter: string){
    console.log("Getting groups...");

    let res = await this.read("/contact/group/list")

    let contactGroups = res as ContactGroup[];

    let sortedGroups = [...contactGroups].sort(
      (a, b) => a.name?.localeCompare(b.name)
    );

    console.log(sortedGroups);

    for(var i=sortedGroups.length-1 ; i>=0;i--){
      if(sortedGroups && sortedGroups[i] && sortedGroups[i].name){
        if(sortedGroups[i].name.charAt(0) != letter.toLowerCase() && sortedGroups[i].name.charAt(0) != letter.toUpperCase()){
          sortedGroups.splice(i, 1);
        }
      }
    }

    console.log("final groups", sortedGroups);

    return sortedGroups;
  }

  public async getMiniListPaginated (page: Number, size: Number, sortBy: string = "firstname", direction: string = "ascending") : Promise<Pagination<Contact>> {
    ContactService.LoadingContacts = true;
    let params = "?"
    params += "page" + "=" + page;
    params += "&size" + "=" + size;
    params += "&sortBy" + "=" + sortBy;
    params += "&direction" + "=" + direction;

    let res = await this.read("/contact/minilist/paginated" + params);

    let paginatedContacts = res;

    if(paginatedContacts.content){
      paginatedContacts.content.forEach(contact => {
        if(contact.first_name != "" && contact.first_name != "?"){
          contact.display_name = contact.first_name;
        } else if(contact.last_name != ""){
          contact.display_name = contact.last_name;
        } else if(contact.company != ""){
          contact.display_name = contact.company;
        } else if(contact.function != ""){
          contact.display_name = contact.function;
        } else {
          contact.display_name = "?";
        }
      });
    }

    ContactService.LoadingContacts = false;
    return paginatedContacts as Pagination<Contact>;
  }

  public getListPaginated (page: Number, size: Number, sortBy: string = "firstname", direction: string = "ascending") : Promise<Pagination<Contact>> {
    ContactService.LoadingContacts = true;

    let params = "?"
    params += "page" + "=" + page;
    params += "&size" + "=" + size;
    params += "&sortBy" + "=" + sortBy;
    params += "&direction" + "=" + direction;

    return this.read("/contact/list/paginated" + params)
    .then(res =>{
      ContactService.LoadingContacts = false;
      return res as Pagination<Contact>;
    }).catch(res =>{
      ContactService.LoadingContacts = false;
      return res;
    });
  }

  public getAllContactsSorted () : Promise<Array<Contact>> {
    console.log("Getting contacts...");

    return this.read("/contact/list")
    .then(res =>{
      let contacts= res as Contact[];

      let sortedContacts = [...contacts].sort((contact1, contact2) => {
        if (contact1.last_interaction == null && contact2.last_interaction == null) {
          return -Number.MAX_VALUE;
        }

        return (
          new Date(contact2.last_interaction).getTime() - 
          new Date(contact1.last_interaction).getTime()
        );
      });

      ContactService.SetSearchedContacts(sortedContacts);
      ContactService.HasLoadedContacts = true;

      return sortedContacts;
    });
  }

  public getContact (id) : Promise<Contact> {
    return this.read(`/contact/byid/${id}`).then(res => {
      return res as Contact;
    });
  }

  public getMiniContact (id) : Promise<Contact> {
    return this.read(`/contact/getMiniContactById/${id}`).then(res => {
      return res as Contact;
    });
  }

  public setFavourite (contact: Contact, favourite: number) : Promise<Response> {
    if(ContactService.contactsPageFavouriteContactList){
      if(favourite == 1){
        ContactService.contactsPageFavouriteContactList.push(contact);
      } else {
        ContactService.contactsPageFavouriteContactList = ContactService.contactsPageFavouriteContactList.filter(({ id_contact }) => id_contact !== contact.id_contact);
      }
    }
    
    return this.put(`/contact/makeFavourite/${contact.id_contact}`, {favourite: favourite});
  }

  public addNewContact (contact: Contact){
    if(GlobalService.DebugMode) console.log("add new contact triggered");
    if(!contact.avatar){
      contact.avatar = "";
    }
    if(contact.avatar.length != 0 && !contact.avatar.includes("data:image/png;base64") && !contact.avatar.endsWith(".png")) {
      contact.avatar = "data:image/png;base64, " + contact.avatar;
    }
    if(contact.favourite == undefined){
      contact.favourite = 0;
    }
    if(contact.first_name[0] == undefined){
      contact.first_name = ""
    }
    if(contact.last_interaction == null){
      contact.last_interaction = new Date();
    }
    return this.put("/contact/new",contact);
  }

  public deleteContact (id: number) {
    return this.delete("/contact/delete/"+id);
  }

  public async postContactAvatar (id: number, avatar: string) {
    if(!avatar || avatar == ""){
      return;
    }
    if(avatar.length != 0 && !avatar.includes("data:image/png;base64") && !avatar.endsWith(".png") && 
    !avatar.includes("data:image/jpg;base64") && !avatar.endsWith(".jpg")) {
      avatar = "data:image/png;base64, " + avatar;
    }

    return this.post(`/contact/${id}/avatar`, { avatar });
  }

  public updateContact (id: number, contact: Contact) {
    return this.put("/contact/updateContact/"+id, contact);
  }

  public async getAllGroups () : Promise<Array<ContactGroup>> {
    console.log("Getting contact groups...");

    let res = await this.read("/contact/group/list")
    
    let groups= res as ContactGroup[];

    let sortedGroups = [...groups].sort(
      (a, b) => a.name.charCodeAt(0) - b.name.charCodeAt(0)
    );
    
    return sortedGroups;
  }

  public async getGroupDetails (id: string) : Promise<ContactGroup> {
    return await this.read(`/contact/group/byid/${id}`);
  }

  public deleteGroup (id: string) : Promise<Response> {
    console.log(`Deleting contact group ${id}...`);

    return this.delete(`/contact/group/delComplete/${id}`);
  }

  public async addNewGroup (group) : Promise<Response> {
    console.log("Adding new group...");

    let result = await this.put("/contact/group/new", group).catch(result => {
      return undefined;
    });
    return result;
  }

  public async updateGroup (group) : Promise<Response> {
    console.log("Updating group...");

    let result = this.put("/contact/group/new", group).catch(result => {
      return undefined;
    });
    return result;
  }

  public setGroupContacts (id, contacts: Contact[], name: String) : Promise<Response>  {
    console.log("Setting group contacts...");

    return this.post("/contact/group/add", {id_contactgroup: id, contacts: contacts, name: name});
  }

  public getContactNotes (id) : Promise<Note[]> {
    return this.read(`/contact/${id}/note/list`).then(res => res as Note[]);
  }

  public async getContactActions (id) : Promise<Array<Action>> {
    let res = await this.read(`/action/list/contact/${id}`);
    console.log("actionlist before changes", res);
    let Actionlist = res as Action[];

    Actionlist.forEach(action => {
      action.duedate = new Date(action.due).getTime();
      action.due = new Date(action.due).toLocaleString();
      if(!action.contacts){ action.contacts = []; }
    });

    return Actionlist;
  }

  public getContactTouchpoints (id) : Promise<Touchpoint[]> {
    return this.read(`/touchpoint/list/contact/${id}`).then(res => res as Touchpoint[]);
  }

  public getContactActionpaths (id) : Promise<actionpath[]> {
    return this.read(`/actionpath/list/contact/${id}`).then(res => res as actionpath[]);
  }

  public async getContactAvatar (contact: Contact) : Promise<ContactAvatarCached> {
    let cachedAvatar = await this.getContactAvatarCached(contact);

    if (cachedAvatar && (new Date(cachedAvatar.last_modified) >= new Date(contact.modification ?? "0000"))) {
      return cachedAvatar;
    }

    let avatar = await this.read(`/contact/${contact.id_contact}/avatar`).then(res => res as ContactAvatar);

    let newCachedAvatar = this.setContactAvatarCached(contact, avatar);

    return newCachedAvatar;
  }

  public async getContactAvatarCached (contact: Contact) : Promise<ContactAvatarCached> {
    let cachedAvatar = await this.storageService.getStorage(`avatar_${contact.id_contact}`, false);

    return cachedAvatar;
  }

  public async setContactAvatarCached (contact: Contact, avatar: ContactAvatar) : Promise<ContactAvatarCached> {
    return await this.storageService.setStorage(`avatar_${contact.id_contact}`, {
      id_contact: contact.id_contact,
      avatar: avatar.avatar,
      last_modified: new Date(contact.modification)
    }, false);
  }

  public async importContacts (contacts: Contact[]) : Promise<Contact[]> {
    if(contacts.length == 1) GlobalService.log("imported single contact", contacts);
    let contactChunk = await this.post(`/contact/import`,contacts).then(res => res as Contact[]).catch(res =>{
      GlobalService.log("error with API call while importing contact", res);
      return [];
    });

    return contactChunk;
  }

  public async importClipboardContact(clipboard){
    let jsonString: string = await clipboard;
    if(JSON.parse(jsonString)){
      let contact: Contact = JSON.parse(jsonString);
      if(contact.first_name || contact.last_name || contact.company || contact.function){
        GlobalService.IsLoading = true;
        delete contact.id_contact;
        delete contact.contact_administration;

        if(contact.url_list) contact.url_list.forEach(url => {
          delete url.id_url;
        });
        if(contact.telephone_list) contact.telephone_list.forEach(telephone => {
          delete telephone.id_telephone;
        });
        if(contact.address_list) contact.address_list.forEach(address => {
          delete address.id_address;
        });
        if(contact.email_list) contact.email_list.forEach(email => {
          delete email.id_email;
        });
        console.log("final contact", contact);
        await this.createNewContact(contact, false);
        GlobalService.IsLoading = false;
      }
    }
  }

  public getEmailTypeList () {
    return this.emailTypeList;
  }

  public getPhoneTypeList () {
    return this.phoneTypeList;
  }
  
  public arraySplitter(curArray, number){
    let initialArray = curArray;
    console.log(initialArray);
    console.log(number);
    let secondaryArray = [];
    for (var i = 0; i < number; i++) {
      secondaryArray.push(initialArray[i]);
    }

    while(number > 0){
      initialArray.pop(number);
      number = number - 1;
    }
    return({remainingData: initialArray, splitData: secondaryArray});
  }

  public static resetLocalLists(){
    ContactService.sortedLocalList = [];
    ContactService.ContactsPageContactList = [];
    ContactService.localList = [];
    ContactService.localGroupList = [];
    ContactService.searchedContacts = [];
    ContactService.DashboardContactList = [];
    ContactService.HasLoadedContacts = false;
    ContactService.hasInitialisedSync = false;
    ContactService.startSync = false;
  }

  public async countLocalList(){
    let attempts = 0;
    while(ContactService.localList.length == 0 && attempts < 3){
      await this.setupSync();
      attempts++;
    }

    return ContactService.localList.length;
  }

  public async emptyContactSync(){
    this.storageService.setStorage(`localContacts_${(await this.authService.getUserObj()).id_user}`, undefined, false);
    this.storageService.setStorage(`contactSyncDate_${(await this.authService.getUserObj()).id_user}`, undefined);
  }

  public static setDisplayName(contact, shorterNames: number = 0){
    let displayname = "";
    if(!contact) return "?";
    if(contact["shortDisplay"] && contact["shortDisplay"] != "" && contact["shortDisplay"] != "?") 
      return contact["shortDisplay"];
    
    if(contact.first_name != undefined && contact.first_name != "" && contact.first_name != "?" 
    && contact.first_name != contact.company && contact.first_name != contact.function && contact.first_name != contact.last_name){
      if(contact.last_name != undefined && contact.last_name != "" && contact.last_name != "?") {
        let finalName = this.cap(contact.first_name) + " " + contact.last_name;
        if(finalName.length > 22 && shorterNames == 1) finalName = this.cap(contact.first_name).charAt(0) + ". " + contact.last_name;
        if(finalName.length > 7 && shorterNames == 2) finalName = this.cap(contact.first_name).charAt(0) + ". " + contact.last_name;
        displayname = finalName;
      } else {
        displayname = this.cap(contact.first_name);
      }
    } else if(contact.last_name != undefined && contact.last_name != "" && contact.last_name != "?"){
      displayname = this.cap(contact.last_name);
    } else {
      if(contact.company) displayname = this.cap(contact.company);
      else if(contact.function) displayname = this.cap(contact.function);
      else if (contact.email_list && contact.email_list[0]  && contact.email_list[0].email) {
        displayname = this.cap(contact.email_list[0].email);
      }
      else displayname = "?";
    }
    contact["shortDisplay"] = displayname;
    return displayname;
  }

  public async deleteAllContacts(){
    let allContacts = await this.getAllContacts();
    let contactIDs: contact[] = [];

    if(allContacts && allContacts.length > 0){
      allContacts.forEach(contact => {
        contactIDs.push({id_contact: contact.id_contact});
      });

      let deleteAll = this.deleteContactCollection(contactIDs);

      return deleteAll;
    }
  }
  
  public async deleteContactCollection(contactList: Contact[]){
    console.log("obtained list", contactList);
    let sleepTimer = 0;
    let deleteList = [];

    while(contactList.length > 0){
      if(contactList.length > 10 && contactList.length != 0){
        deleteList.push(contactList[0]);
        this.RemoveContactFromList(contactList[0]);
        contactList.shift(); 
      } else {
        GlobalService.log("delete contacts", contactList);
        await this.post(`/contact/delete/collection`, contactList);
        contactList.forEach(contact => {
          this.RemoveContactFromList(contact);
        });
        contactList = [];
      }

      if(sleepTimer == 10){
        GlobalService.log("delete contacts", deleteList);
        await this.post(`/contact/delete/collection`, deleteList);
        deleteList = [];
        sleepTimer = 0;
      }

      sleepTimer++;
    }

    if(deleteList.length > 0){
      GlobalService.log("delete contacts", deleteList);
      await this.post(`/contact/delete/collection`,deleteList);
      deleteList.forEach(contact => {
        this.RemoveContactFromList(contact);
      });
      deleteList = [];
    }
    this.globalService.returnToast(this.translateService.instant("CONTACTSDELETED"));

    return;
  }

  public async importContactGroup(){
    //show loading screen
    if(ContactService.ImportList.length < 1000){
      let newContactList = [];
      ContactService.ImportList.forEach(element => {
        newContactList.push({"id_contact": element.id_contact});
      });

      if(newContactList && newContactList.length > 0){
        let curDate = new Date();
        let groupName = "Imported " + curDate.getFullYear() + "-" + ("0" + (curDate.getMonth() + 1)).slice(-2) + "-" + ("0" + curDate.getDate()).slice(-2)
        + " " + ("0" + curDate.getHours()).slice(-2)+ ":" + ("0" + curDate.getMinutes()).slice(-2)
        await this.addNewGroup({
          name: groupName,
          favourite: 0
        })
        .then(async (res) => {
          let newGroup = res as unknown as ContactGroup;
          let finalContactGroup = await this.setGroupContacts(newGroup.id_contactgroup,newContactList, groupName);
          let finalContacts = await finalContactGroup as unknown as ContactGroup;
          this.PushContactGroup(finalContacts);
          ContactService.ContactGroup = finalContacts;
          this.navController.navigateRoot("contact-group-details");
          ContactService.ImportList = [];
        }).catch(error => {
          return;
        });
      }
    }
    //remove loading screen
  }

  public async exportToRelationAPI(contact){
    let res = await this.post(environment.relation + "/contact", contact, undefined, false);
    
    return res;
  }

  public async importFromRelationAPI(){
    let res = await this.read(environment.relation + "/contact/list", undefined, false);
    
    return res;
  }

  public async createContactFollowUp(idContact){
    let buttons = [];
    buttons.push({
      cssClass: 'delete-yes first-yes-no',
      text: this.translateService.instant("YES"),
          handler: () => {
            //To-Do, check if action limit wasn't reached
            ContactService.contactDetailPageTab = 1;
            ActionService.ActionStatus = 0;
            this.navController.navigateRoot("add-action", {queryParams: {id_contact: idContact}});
            return;
      }
    });
    buttons.push({
      cssClass: 'delete-no second-yes-no',
      text: this.translateService.instant("NO"),
      handler: () => {
        return;
      }
    });

    this.globalService.ionicAlert(
      buttons,
      this.translateService.instant("ADDCONTACTSUCCESS") + ". " + this.translateService.instant("WOULDYOULIKETOCREATEFOLLOWUP")
    );
  }

  public async exportToQR(incomingContact){
    let contact: any = incomingContact;
    let initialAvatar = contact.avatar;
    contact.avatar = undefined;

    let QRData: string = "";

    QRData = JSON.stringify(contact);
    console.log("QR Data: ", QRData);
    contact.avatar = initialAvatar;

    GlobalService.ShowQR = true;
    GlobalService.QrCode = QRData;
  }
  /*
  
  public async importFromQR() {
    const options: BarcodeScannerOptions = {
      preferFrontCamera: false,
      showFlipCameraButton: false,
      showTorchButton: false,
      torchOn: false,
      prompt: this.translateService.instant("PLACEQRCODEINBOX"),
      resultDisplayDuration: 500,
      orientation: 'portrait',
    };
    
    BarcodeScanner.scan(options).then(async barcodeData => {
      console.log(barcodeData);
      let contactList = JSON.parse(barcodeData.text.toString())
      console.log(contactList);
      contactList.id_contact = undefined;
      await this.addNewContact(contactList).then(async res => {
        if (res.ok) {
          //Push this instead of "contact" because "contact" has no id_contact and won't let the user edit it
          let newContact  = res as unknown;

          console.log("New contact added", res.json());

          this.PushContact(newContact);

          const alert = await this.alertController.create({
            cssClass: 'custom-alert',
            header: this.translateService.instant("CONTACT"),
            message: this.translateService.instant("ADDCONTACTSUCCESS"),
            buttons: [{
              text: this.translateService.instant("CONTINUE"),
              cssClass: "cancelButton"
            }]
           });
           this.globalService.alertTimer(alert);
           alert.present(); 
        }
      })
    });
  }
  */

  public async createNewContact(contact, isEditingExisting, original?, goBack?){
    if(contact.function && contact.function.length == 3) contact.function = contact.function.toUpperCase();
    if (isEditingExisting && original) {
      let res = this.updateContact(original.id_contact,contact);

      if (res) {
        this.postContactAvatar(contact.id_contact, contact.avatar);
        let updatedContact = await res;
        this.RemoveContactFromList(updatedContact);
        this.PushContact(updatedContact);
        ContactService.Contact = await res;
        if(goBack){
          ContactService.SetIsEditing(false);
          this.globalService.goBack();
        }
      }
      GlobalService.log(contact);
    } else {
      let res = await this.addNewContact(contact)
      
      //Push this instead of "contact" because "contact" has no id_contact and won't let the user edit it
      let newContact  = res as unknown as Contact;
      if(GlobalService.DebugMode) GlobalService.log("New contact added, now adding contact avatar", newContact);
      await this.postContactAvatar(newContact.id_contact, contact.avatar);
      if(GlobalService.DebugMode) GlobalService.log("Contact avatar added");
      this.PushContact(newContact);
      // this.globalService.returnToast("ADDCONTACTSUCCESS");
      if(goBack){
        ContactService.SetIsEditing(false);
        this.globalService.goBack();
      }
      await GlobalService.sleep(500);
      ContactService.SetCurrentContact(newContact);
      this.navController.navigateRoot("contact-details");
      await this.createContactFollowUp(newContact.id_contact);
    }
  }

  public static cap(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
}
