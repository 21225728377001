import { Injectable } from "@angular/core";
import { CrudService } from "./crud.service";
import Actionpath from "src/app/models/actionpath";
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from "./global.service";
import { Storage } from "@ionic/storage-angular";
import { AuthService } from "./auth.service";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import Action from "../models/action";
import { ActionService } from "./action.service";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class ActionpathService extends CrudService {
  private static actionpath: Actionpath;
  private static isEditing: boolean = false;
  private static isFollowUp: boolean = false;
  private static maxactionpaths = 10;
  private static reachedmaximum = false;
  private static searchedgoals: Actionpath[];
  private static openActions: Action[];
  private static closedActions: Action[];
  private static priorityExists: boolean = false;
  private static fromAction: boolean = false;
  private static refreshDashboardGoals: boolean = false;
  private static refreshActionPathActions: boolean = false;

  public static get RefreshActionPathActions () {
    return this.refreshActionPathActions;
  }

  public static set RefreshActionPathActions (value) {
    this.refreshActionPathActions = value;
  }

  public static get RefreshDashboardGoals () {
    return this.refreshDashboardGoals;
  }

  public static set RefreshDashboardGoals (value) {
    this.refreshDashboardGoals = value;
  }

  public static set IsFollowUp (value) {
    this.isFollowUp = value;
  }

  public static get IsFollowUp () {
    return this.isFollowUp;
  }

  public static set FromAction (value) {
    this.fromAction = value;
  }

  public static get FromAction () {
    return this.fromAction;
  }

  public static set PriorityExists (value) {
    this.priorityExists = value;
  }

  public static get PriorityExists () {
    return this.priorityExists;
  }

  public static get OpenActions () {
    return this.openActions;
  }

  public static set OpenActions (value) {
    this.openActions = value;
  }

  public static get ClosedActions () {
    return this.closedActions;
  }

  public static set ClosedActions (value) {
    this.closedActions = value;
  }

  public static get Searchedgoals () {
    return this.searchedgoals;
  }

  public static set Searchedgoals (actionpath: Actionpath[]) {
    this.searchedgoals = actionpath;
  }

  public static get maxActionpaths () {
    return this.maxactionpaths;
  }

  public static get reachedMaximum () {
    return this.reachedmaximum;
  }

  public static setReachedMaximum(isMax: boolean) {
    this.reachedmaximum = isMax;
  }

  public static get Actionpath () {
    return this.actionpath;
  }

  public static SetCurrentactionpath (actionpath: Actionpath) {
    this.actionpath = actionpath;
  }

  public static SetIsEditing (isEditing: boolean) {
    this.isEditing = isEditing;
  }

  public static get IsEditing () {
    return this.isEditing;
  }

  constructor(
    public httpClient: HttpClient,
    public navController: NavController,
    public translateService: TranslateService,
    public alertController: AlertController,
    public globalService: GlobalService,
    public storageService: StorageService,
    public authService: AuthService
  ) {
    super(httpClient, navController, translateService, alertController, globalService, storageService, authService);
  }

  public async getAllactionpaths(): Promise<Array<Actionpath>> {
    console.log("Getting actionpaths...");
    let result = await this.read("/actionpath/list")

    let noFinalDateList = [];
    let finalDateList = [];

    for(let object of result){
      if(object.finaldate) finalDateList.push(object);
      else noFinalDateList.push(object);
    }
    finalDateList.sort((a, b) => (new Date(a.finaldate) > new Date(b.finaldate)) ? 1 : -1);
    let finalList = [];

    for(let object of finalDateList){
      finalList.push(object);
    }
    for(let object of noFinalDateList){
      finalList.push(object);
    }

    return finalList;
  }

  public async createactionpath (actionpath: Actionpath) : Promise<Actionpath> {
    if(actionpath.priority){
      await this.RemoveOtherPriorities();
    }

    let res = await this.put("/actionpath/new",actionpath);
    console.log(res);
    return res;
  }

  public async updateactionpath (actionpath: Actionpath) : Promise<Actionpath> {   
    if(actionpath.priority){
      await this.RemoveOtherPriorities();
    }

    let res = await this.patch(`/actionpath/${actionpath.id_action_path}/update`,actionpath);
    console.log(res);
    return res;
  }

  public async deleteactionpath (id: number) {
    ActionService.SelectedGoals = [];
    let actionPath = await this.getactionpath(id);

    actionPath.contacts = [];
    actionPath.actions = [];

    await this.updateactionpath(actionPath);

    return this.delete("/actionpath/truedelete/"+id);
  }

  public getactionpath (id: number): Promise<Actionpath> {
    return this.read("/actionpath/byid/" + id);
  }
  
  public async checkMaximum(){
    let pathCounter = 0;
    let Actionpaths = await this.getAllactionpaths()
    Actionpaths.forEach(actionPath => {
      if(actionPath.status == "OPEN"){ pathCounter++; };
    });

    if(pathCounter >= ActionpathService.maxActionpaths){
      ActionpathService.setReachedMaximum(true);
      return true;
    } else {
      ActionpathService.setReachedMaximum(false);
      return false;
    }
  }

  public async deleteAllActionPaths(){
    ActionService.SelectedGoals = [];
    let allActionPaths = await this.getAllactionpaths();

    if(allActionPaths && allActionPaths.length > 0){
      let deleteAll = this.deleteActionPathCollection(allActionPaths);

      return deleteAll;
    }
  }
  
  public async deleteActionPathCollection(actionPathList: Actionpath[]){
    ActionService.SelectedGoals = [];
    console.log("delete goals");
    console.log(actionPathList);
    let sleepTimer = 0;
    let deleteList = [];

    while(actionPathList.length > 0){
      if(actionPathList.length < 10 && actionPathList.length != 0){
        await this.post(`/actionpath/delete/collection`,actionPathList);
        actionPathList = [];
      }

      deleteList.push(actionPathList[0]);
      actionPathList.shift(); 

      if(sleepTimer == 10){
        await this.post(`/actionpath/delete/collection`,deleteList);
        deleteList = [];
        sleepTimer = 0;
      }

      sleepTimer++;
    }
    
    return;
  }

  public static getTouchpointActionData(selectedActions){
    let touchpointActions = [];
    
    if(selectedActions){
      selectedActions.forEach(selectedAction => {
        if(selectedAction.status > 1){
          touchpointActions.push(selectedAction);
        }
      });
  
      touchpointActions.sort((a, b) => (new Date(a.due) > new Date(b.due)) ? 1 : -1)
    }
    
    return touchpointActions;
  }

  public static getTouchpointSuccesfulActionData(selectedActions){
    let touchpointActions = [];
    
    if(selectedActions){
      selectedActions.forEach(selectedAction => {
        if(selectedAction.status > 1 && selectedAction.status != 3){
          touchpointActions.push(selectedAction);
        }
      });
  
      touchpointActions.sort((a, b) => (new Date(a.due) > new Date(b.due)) ? 1 : -1)
    }
    
    return touchpointActions;
  }

  public async reloadPage(){
    if(ActionpathService.actionpath)
      ActionpathService.actionpath = await this.getactionpath(ActionpathService.actionpath.id_action_path);
  }

  public async createFollowUpActionPath(actionpath){    
    let buttons = [];
    buttons.push({
      cssClass: 'delete-yes first-yes-no',
      text: this.translateService.instant("YES"), handler: async () => {
        let newAP = actionpath;
        newAP.id_action_path = undefined;
        newAP.status = "OPEN";
        newAP.actions = [];
        newAP.contacts = [];
        ActionpathService.IsFollowUp = true;
        ActionpathService.SetCurrentactionpath(newAP);
        this.navController.navigateRoot("add-actionpath");
      }
    });
    buttons.push({
      cssClass: 'delete-no second-yes-no',
      text: this.translateService.instant("NO"),
      handler: () => {}
    });
    
    this.globalService.ionicAlert(
      buttons,
      "WOULDYOULIKETOCREATEFOLLOWUPGOAL"
    );
  }

  public async RemoveOtherPriorities(){
    let allAP = await this.getAllactionpaths();

    for(let AP of allAP){
      if(AP.priority){
        AP.priority = false;
        await this.updateactionpath(AP);
      }
    }

    console.log("ALL AP: ", allAP);
  }

  //Follow up time selection for goals, not needed unless requested
  //Would only work IF final date was inserted.

  // public async quickFollowTimeGoal(actionpath){
	// 	let alert = await this.alertController.create
	// 	({
  //     cssClass: 'custom-alert',
	// 		header: this.translateService.instant("WHENFOLLOWUP"),
	// 		buttons:
	// 		[
	// 			{
	// 				text: this.translateService.instant("TOMORROW"), handler: async () =>
	// 				{
  //           let newAP = actionpath;
  //           newAP.id_action_path = undefined;
  //           newAP.status = "OPEN";
  //           newAP.actions = [];
  //           newAP.contacts = [];
  //           ActionpathService.IsFollowUp = true;
  //           ActionpathService.SetCurrentactionpath(newAP);
  //           this.navController.navigateRoot("add-actionpath", {queryParams: {followupDate: 1}});
	// 				}
	// 			},
	// 			{
	// 				text: this.translateService.instant("NEXTWEEK"), handler: async () =>
	// 				{
  //           let newAP = actionpath;
  //           newAP.id_action_path = undefined;
  //           newAP.status = "OPEN";
  //           newAP.actions = [];
  //           newAP.contacts = [];
  //           ActionpathService.IsFollowUp = true;
  //           ActionpathService.SetCurrentactionpath(newAP);
  //           this.navController.navigateRoot("add-actionpath", {queryParams: {followupDate: 7}});
	// 				}
	// 			},
	// 			{
	// 				text: this.translateService.instant("NEXTYEAR"), handler: async () =>
	// 				{
  //           let newAP = actionpath;
  //           newAP.id_action_path = undefined;
  //           newAP.status = "OPEN";
  //           newAP.actions = [];
  //           newAP.contacts = [];
  //           ActionpathService.IsFollowUp = true;
  //           ActionpathService.SetCurrentactionpath(newAP);
  //           this.navController.navigateRoot("add-actionpath", {queryParams: {followupDate: 365}});
	// 				}
	// 			},
	// 			{
	// 				text: this.translateService.instant("MANUALSELECT"), handler: () => 
	// 				{
  //           let newAP = actionpath;
  //           newAP.id_action_path = undefined;
  //           newAP.status = "OPEN";
  //           newAP.actions = [];
  //           newAP.contacts = [];
  //           ActionpathService.IsFollowUp = true;
  //           ActionpathService.SetCurrentactionpath(newAP);
  //           this.navController.navigateRoot("add-actionpath");
	// 				}
	// 			}
	// 		]
  //   });
	// 	alert.present();
  // }
}
