<ion-header>
  <ion-toolbar>
    <ion-buttons mode="md" slot="start">
      <ion-button mode="md" (click)="goBack()">
        <ion-icon class="new-style-icon" [src]="'assets/icon/chevron-left.svg'"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-button class="finish-select-button" (click)="submit()" *ngIf="selectedgoals && selectedgoals.length > 0">
        <ion-icon class="new-style-icon v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text class="v-done">{{"DONE" | translate}}</ion-text>
      </ion-button>
      <ion-button class="finish-select-button" *ngIf="!selectedgoals || selectedgoals.length == 0">
        <ion-icon class="new-style-icon faded v-icon" [src]="'assets/icon/save.svg'"></ion-icon>
        <ion-text class="v-done faded">{{"DONE" | translate}}</ion-text>
      </ion-button>
    </ion-buttons>

    <app-header [title]="'SELECTGOALS' | translate"></app-header>
  </ion-toolbar>

  <ion-searchbar search-icon="search-outline" mode="md" clear-icon="undefined" [placeholder]="'SEARCH' | translate" 
  (ionChange)="searchgoals($event)"></ion-searchbar></ion-header>

<ion-content>
  <div [ngClass]="{'ios-search-margin': isIos()}"  class="search-margin"></div>

  <div *ngIf="this.isLoadinggoals && !visiblegoals">
    <ion-list>
      <app-actionpath-mini-item></app-actionpath-mini-item>
      <app-actionpath-mini-item></app-actionpath-mini-item>
      <app-actionpath-mini-item></app-actionpath-mini-item>
      <app-actionpath-mini-item></app-actionpath-mini-item>
      <app-actionpath-mini-item></app-actionpath-mini-item>
    </ion-list>
  </div>

  <div *ngIf="!this.isLoadinggoals && goals">
    <!-- <div class="center-text select-page-buttonholder">
      <ion-button color="medium" (click)="selectAll()">
        {{ "SELECTALL" | translate }}
      </ion-button>
    </div> -->

    <ion-list>
      <div *ngFor="let goal of goals">
        <ion-item [ngClass]="{'selected-item': actionIsSelected(goal)}" *ngIf="goal.status == 'OPEN'" ion-button (click)="selectGoal(goal)" [button]="true">
          <ion-grid class="ion-no-padding">
            <ion-row>
              <ion-col>
                <app-actionpath-mini-item class="transparant-background select-goal-holder" [actionpath]="goal" [clickable]="false" [lines]="false"></app-actionpath-mini-item>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-item>
      </div>
    </ion-list>
  </div>
</ion-content>