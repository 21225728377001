import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TermsandAgreementsComponent } from './terms-and-agreements.component';
import { HeaderComponentModule } from 'src/app/components/header/header.module';

@NgModule({
  imports: [ 
    CommonModule, 
    FormsModule, 
    IonicModule, 
    TranslateModule, 
    HeaderComponentModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [TermsandAgreementsComponent],
  exports: [TermsandAgreementsComponent, TranslateModule]
})
export class TermsandAgreementsComponentModule {}
